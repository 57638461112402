import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {format} from "date-fns";
import {getDaysInMonth} from "../utils/dateHelper";
import {RootState} from "services/rootReducer";
import styles from "./css/YearViewComp.module.css";
import { Button, Modal } from "react-bootstrap";
import {calRangeType} from "services/View_Slice"

import CONSTANTS from "../../constants"
import styled from "styled-components";
import { buildQueryViewParamsCal } from "../utils/queryHelper";
import OverlayComp from "../OverlayComp";
import { createToastProperties, toastListProp, toastType } from "components/toast/Toast";
import { addToQueue } from "services/Toasts_Slice";

const StyledDiv = styled.div`
    background-color: ${props => props.color};
`;

const YearViewComp = (props: any) => {
    //month components to render
    const months = [];

    //redux store variables
    const {mobile, calRangeValue, missionColorMap, heatmap} = useSelector((state: RootState) => state.View);
    const {request} = useSelector((state: RootState) => state.Query);

    const [loading, setLoading] = useState(false);
    
    //array of links to render in modal
    const link_array: any = [];
    const [links, setLinks] = useState(null);

    //modal states
    const [show, setShow] = useState(false);
    const [modalDocs, setModalDocs] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const usedContainer = mobile? styles.container_mobile: styles.container;

    const dispatch = useDispatch()

    useEffect(() => {

    }, [])

    //ID functional component 
    const IDs = (id: string, elem: any) => {
        const item = elem;
        const mission: string = item[CONSTANTS.UI.TOP_CAT];
        const title: string =  item['title'];
        const doctype: string = item['documenttype_ss']
        var aColor: string = '#000000';
        if(missionColorMap !== undefined && missionColorMap[mission] !== undefined && missionColorMap[mission] !== undefined){
            aColor = missionColorMap[mission];
        }

        //render ID
        return(
            <a key={elem['id']} className={styles.link} href={elem['id']} target="_blank" rel="noopener noreferrer">
                <StyledDiv className={styles.styleDiv} color={aColor}/>
                <div className={styles.elem_title}>
                    {title}
                </div>
                <div className={styles.elem_prop}>
                    {doctype}
                </div>
            </a>
        )
    }

    function fetchResults(yUrl: string){
        return fetch(yUrl)
        .then(response => response.json())
        .then(function(response) {
            console.log(JSON.stringify(response.query));

            return response 
        })
        .catch(function(error) {
            console.log(error);
        });    
    }
    
    //Days functional component (renders day button with heatmap color)
    const Days = (day: Date | undefined, month: number) => {
        var d;
        var disabled = false;
        const dString = format(day!, 'yyyy-MM-dd')
        var numOfDocs = 0;
        var missionNumbs = []
        var missions = []
        for(let elem in heatmap){
            if(elem.split('T')[0] === dString){
                //console.log(elem['file_modified_dt'].split('T')[0] + '\n' + dString)
                for(let inner in heatmap[elem]){
                    missions.push(inner)
                    missionNumbs.push(heatmap[elem][inner])
                }
            }
        }
        if(day !== undefined){
            d = day.getDate();
        }
        if(day?.getMonth() !== month){
            disabled = true;            //disable days that are not in the month
        }

        for(let elem of missionNumbs)
            numOfDocs += parseInt(elem)

        //heatmap
        var useButton;
        if(numOfDocs > 0 && numOfDocs < 5){
            useButton = styles.button_full3;
        }else if(numOfDocs >= 5 && numOfDocs <= 10){
            useButton = styles.button_full2;
        }else if(numOfDocs > 10){
            useButton = styles.button_full1;
        }

        //render day
        return(
            <div key={day?.getDate()}>
                {numOfDocs > 0 && !disabled ? 
                <Button className={useButton} disabled={disabled}
                onClick={() => {
                    setLoading(true)
                    
                    const viewparams = buildQueryViewParamsCal(100, calRangeType.day, dString);

                    var data = fetchResults(CONSTANTS.ENDPOINT.REST_API + CONSTANTS.REST_DO_QUERY + "?" + request + "&" + viewparams)
                
                    var res: any = data.then(response => {
                        res = response
                        
                        var docs;
                        if(res['response'] !== undefined){
                            docs = res['response']['docs'];
                            for(let elem of docs){
                                console.log(elem)
                                link_array.push(IDs(elem['title'], elem))
                            }
                            setLinks(link_array);
                            setModalDocs(numOfDocs);
                            handleShow()
                            setLoading(false)
                        }else{
                            console.log('too late')
                        }
                    }).catch(function(error){
                        const toastProperties: toastListProp = createToastProperties(toastType.error, 'error occurred while retrieving data', error.message);
                        dispatch(addToQueue(toastProperties));
                        setLoading(false)
                    })
                }}>
                    {d}
                </Button>
                : 
                <Button className={styles.button} disabled={disabled}
                onClick={() => {
                    // alert(day);
                }}>
                    {d}
                </Button>
                }
            </div>  
        );
    }

    //MonthContainer functional component (renders month)
    const month_names = ["January", "February", "March","April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const MonthContainer = (month: number) => { 
        const dates: Date[] = getDaysInMonth(month, parseInt(calRangeValue));
        const d: any = [];
        const l: any = [];
        const mar: any = [];
        const mer: any = [];
        const g: any = [];
        const v: any = [];
        const s: any = [];

        //push days for each week day
        dates.forEach(element => {
            switch(element.getDay()){
                case 0: d.push(Days(element, month)); break;
                case 1: l.push(Days(element, month)); break;
                case 2: mar.push(Days(element, month)); break;
                case 3: mer.push(Days(element, month)); break;
                case 4: g.push(Days(element, month)); break;
                case 5: v.push(Days(element, month)); break;
                case 6: s.push(Days(element, month)); break;
            }
        });

        return(
            <div key={month} className={styles.month_container}>
                <div className={styles.month_title}>{month_names[month]}</div>
                <div className={styles.days_container}>
                    <div className={styles.week_container}>
                        M
                        {l}
                    </div>
                    <div className={styles.week_container}>
                        T
                        {mar}
                    </div>
                    <div className={styles.week_container}>
                        W
                        {mer}
                    </div>
                    <div className={styles.week_container}>
                        T
                        {g}
                    </div>
                    <div className={styles.week_container}>
                        F
                        {v}
                    </div>
                    <div className={styles.week_container}>
                        S
                        {s}
                    </div>
                    <div className={styles.week_container}>
                        S
                        {d}
                    </div>
                </div>
            </div>
        );
    }

    //push month components in months variable
    for(var i=0; i < 12; i++){
        months.push(MonthContainer(i))
    }

    //YearView render
    return(
        <div>
            {loading ? 
            <OverlayComp/>
            :
            <div className={usedContainer}>
                <Modal size="lg" centered={true} animation={false} className={styles.modal} show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Documents Found: {modalDocs}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {links}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="outline" onClick={handleClose}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal> 
                {months}       
            </div>
            }
        </div>
    );
}

export default YearViewComp;