/*
* 
*/

import logger from '../loggers/logger';

// facets
export const PARAM_MISSION: string='mis';
export const PARAM_INSTRUMENT: string='ins';
export const PARAM_CALENDARTYPE: string='cat';
export const PARAM_LEVEL: string='lev';
export const PARAM_PRODUCTTYPE: string='prt';
export const PARAM_DOCUMENTTYPE: string='dot';

// sort by
export const PARAM_SORTBY: string='sby';
export const PARAM_SORTBYDIRECTION: string='sbd';

// show by (pagination)
export const PARAM_SHOW: string='sho';
// view by (date range)
export const PARAM_VIEWBY: string='vby';
export const PARAM_DATESTART: string='dts';
// search field
export const PARAM_SEARCHBAR: string='sbt';
// view list/cal switch
export const PARAM_VIEWTYPE: string='slc';


//const KNOWN_PARAMS_NAMES: Array<string>=[
//    'mis','ins','cat', 'lev','prt', 'dot',
//    ]

//interface Dictionary<T> {
//    [Key: string]: T;
//}








//
// returns array compatible with store selected facet
//
export function parseQueryStringForFacets(query: URLSearchParams): Array<string>{


    var urlFacetsConstraints: Array<string> = [];
    if (query.get(PARAM_MISSION) != null){
        logger.info("@@@@@@ parseQueryStringForFacets; PARAM_MISSION=" + query.get(PARAM_MISSION));
        urlFacetsConstraints.push(query.get(PARAM_MISSION) + "@mission_ss")
    }

    if (query.get(PARAM_INSTRUMENT) != null){
        logger.info("@@@@@@ parseQueryStringForFacets; PARAM_INSTRUMENT=" + query.get(PARAM_INSTRUMENT));
        urlFacetsConstraints.push(query.get(PARAM_INSTRUMENT) + "@instrument_ss")
    }

    if (query.get(PARAM_CALENDARTYPE) != null){
        logger.info("@@@@@@ parseQueryStringForFacets; PARAM_CALENDARTYPE=" + query.get(PARAM_CALENDARTYPE));
        urlFacetsConstraints.push(query.get(PARAM_CALENDARTYPE) + "@calendartype_ss")
    }

    if (query.get(PARAM_LEVEL) != null){
        logger.info("@@@@@@ parseQueryStringForFacets; PARAM_LEVEL=" + query.get(PARAM_LEVEL));
        urlFacetsConstraints.push(query.get(PARAM_LEVEL) + "@level_ss")
    }

    if (query.get(PARAM_PRODUCTTYPE) != null){
        logger.info("@@@@@@ parseQueryStringForFacets; PARAM_PRODUCTTYPE=" + query.get(PARAM_PRODUCTTYPE));
        urlFacetsConstraints.push(query.get(PARAM_PRODUCTTYPE) + "@producttype_ss")
    }

    if (query.get(PARAM_DOCUMENTTYPE) != null){
        logger.info("@@@@@@ parseQueryStringForFacets; PARAM_DOCUMENTTYPE=" + query.get(PARAM_DOCUMENTTYPE));
        urlFacetsConstraints.push(query.get(PARAM_DOCUMENTTYPE) + "@documenttype_ss")
    }

   return urlFacetsConstraints;
}