/*
 Hold the summary information
 Will be rendered in the Summary component

*/

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "./store";
import CONSTANTS from "../constants";
import { buildQueryString } from 'utils/solr';
import  {FacetsMap, FacetsSelected } from "./Facets_Slice"
import { setAllStates } from "./Loading_Slice";
import logger from "../logger"
import { toastListProp, toastType, createToastProperties } from '../components/toast/Toast';
import { addToQueue } from "../services/Toasts_Slice";
const urldecode = require('urldecode');

interface Dictionary<T> {
    [Key: string]: T;
}

/*
like:
{"numFound":5,
    "facetFound": {
        "mission_ss":["cryosat-2|5"],
        "instrument_ss":["siral|4","siral-doris|1"],
        "productType_ss":["gom|2","gob-b|1","gop-c|1","iop|1"]
    }
}
*/
export type summaryFacetFoundEntryType = Array<string>;

export type summaryStateType = {
    numFound: number;
    facetFound: Dictionary<summaryFacetFoundEntryType>;
}

let initialState: summaryStateType = {
    "numFound": 0,
    "facetFound": {
        "mission_ss":[],
        "instrument_ss":[],
        "productType_ss":[]
    }
};


const summarySlice = createSlice({
    name: 'Summary',
    initialState: initialState,
    reducers: {
        setSummary(state, action: PayloadAction<summaryStateType>) {
            logger.debug("summarySlice setSummary:"+JSON.stringify(action.payload));
            state.facetFound=action.payload.facetFound;
            state.numFound=action.payload.numFound;
        },
        setSummaryTotal(state, action: PayloadAction<number>) {
            logger.debug("summarySlice setSummaryTotal:"+JSON.stringify(action.payload));
            state.numFound=action.payload
        },
        setSummaryFacetFound(state, action: PayloadAction<Dictionary<Array<string>>>) {
            logger.debug("summarySlice setSummaryFacetFound:"+JSON.stringify(action.payload));
            state.facetFound=action.payload
        },
    }
});

//const useAddToast = () => {
//    const dispatch = useDispatch();
//    const toastProperties: toastListProp = createToastProperties(toastType.error, "Facet not loaded", "Try reloading the page...");
//    useDispatch(addToQueue(toastProperties));
//}

//
// get the summary info
//
export const getSummaryShort = (facetMap: FacetsMap, facetSelected: FacetsSelected, searchContraints:string, operator: string): AppThunk => async dispatch => {
    

    logger.info("getSummaryShort facetMap:" + JSON.stringify(facetMap));
    logger.info("getSummaryShort facetSelected:" + JSON.stringify(facetSelected));
    logger.info("getSummaryShort searchContraints:" + searchContraints);

    // if we miss some essential data (if some api call has failed), don't break the UI but display notification
    if(facetMap===undefined){
        const toastProperties: toastListProp = createToastProperties(toastType.error, "Facets not loaded (at Summary slice)", "Try reloading the page...");
        dispatch(addToQueue(toastProperties));
    }else{

        dispatch(setAllStates({loading:true, ok:false, error: ""}));

        // q; start; stop; facetSelected; facetMap
        const query = buildQueryString(
            "*:*",
            searchContraints,
            undefined,
            undefined,
            facetSelected,
            facetMap,
            operator
            );
        logger.debug("getSummaryShort encoded query:" + query);
        logger.debug("getSummaryShort decoded query:" + urldecode(query));

        fetch(CONSTANTS.ENDPOINT.REST_API + CONSTANTS.REST_GET_SUMMARY_SHORT + "?" + query)
            .then(response => {
                logger.info("response 0: " + JSON.stringify(response));
                if(response.ok){
                    logger.info("getSummaryShort response 0: ok");
                    return response.json();
                }else{
                    logger.info("getSummaryShort response 0: not ok");
                    throw new Error('Network response was not ok:' + response.status);
                }
            })
            .then(data => {
                if(CONSTANTS.BACKEND_REPLY_IS_ERROR in data){
                    const toastProperties: toastListProp = createToastProperties(toastType.error, CONSTANTS.REST_GET_SUMMARY_SHORT_ALIAS, data[CONSTANTS.BACKEND_REPLY_IS_ERROR]);
                    dispatch(addToQueue(toastProperties));
                }else{
                    logger.info("## getSummaryShort data.payload: " + JSON.stringify(data));
                    dispatch(setSummary(data));
                }
                dispatch(setAllStates({loading:false, ok:true, error: ""}));
            })
            .catch((error) => {
                logger.error("## getSummaryShort: error="+error);
                dispatch(setAllStates({loading:false, ok:false, error: error.message}));
                const toastProperties: toastListProp = createToastProperties(toastType.error, CONSTANTS.REST_GET_SUMMARY_SHORT_ALIAS, error.message);
                dispatch(addToQueue(toastProperties));
            })

    }
};

export const {setSummary, setSummaryTotal, setSummaryFacetFound} = summarySlice.actions;

export default summarySlice.reducer