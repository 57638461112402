import React, {useEffect, useState} from "react"
import { format, parseISO } from "date-fns"
import {useSelector} from "react-redux"
import {RootState} from "../../services/rootReducer"
import {getDaysInWeek} from "../utils/dateHelper";

import styles from "./css/WeekViewComp.module.css"
import styled from "styled-components"
import CONSTANTS from "../../constants"
import { Button, Modal } from "react-bootstrap"

const StyledDiv = styled.div`
    background-color: ${props => props.color};
`;

//WeekView functional component
const WeekViewComp = (props: any) => {

    const {calRangeValue, mobile, missionColorMap} = useSelector((state: RootState) => state.View)
    const {result} = useSelector((state: RootState) => state.Query)

    //modal states
    const [show, setShow] = useState(false);
    const [elemDetails, setElemDetails] = useState(null);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var today: Date = parseISO(calRangeValue);

    // const [days , setDays] = useState<Date[]>();
    const days = getDaysInWeek(today)
    const dayComp: Array<JSX.Element> = [];

    useEffect(() => {
        // setDays(getDaysInWeek(today));
    }, []);

    var maxElements: number = mobile ? 6: 8;

    var docs: any = []; 
    if(result!==undefined && result["numFound"] !== undefined){
        docs = result["docs"];
    }

    //ID functional component 
    const IDs = (id: string, elem: any) => {
        const item = elem;
        const mission: string = item[CONSTANTS.UI.TOP_CAT];
        const title: string =  item['title'];
        const doctype: string = item['documenttype_ss']
        var aColor: string = '#000000';
        if(missionColorMap !== undefined && missionColorMap[mission] !== undefined && missionColorMap[mission] !== undefined){
            aColor = missionColorMap[mission];
        }

        //render ID
        return(
            <a key={elem['id']} className={styles.link} href={elem['id']} target="_blank" rel="noopener noreferrer">
                <StyledDiv className={styles.styleDiv2} color={aColor}/>
                <div className={styles.elem_title}>
                    {title}
                </div>
                <div className={styles.elem_prop}>
                    {doctype}
                </div>
            </a>
        )
    }

    //Elem functional component (renders element)
    const Elem = (id: string, elem: any) => {
        const item = elem;
        const mission: string = item[CONSTANTS.UI.TOP_CAT];
        var aColor: string = '#000000';
        if(missionColorMap !== undefined && missionColorMap[mission] !== undefined && missionColorMap[mission] !== undefined){
            aColor = missionColorMap[mission];
        }
        const usedStyleDiv = mobile ? styles.styleDiv_mobile: styles.styleDiv;
        var usedTitle = styles.elem_title;
        if(mobile){
            usedTitle = styles.elem_title_mobile;
        }
        const usedElem = mobile? styles.elem_mobile: styles.elem;
        return(
            <div key={elem['path_basename_s']} className={usedElem} 
            onClick={() => {
                // insertElem(ElemDetail(elem, aColor))
                // handleShow()
                window.open(item['id'])
            }}>
                <StyledDiv className={usedStyleDiv} color={aColor}/>
                <div className={usedTitle}>
                    {id}
                </div>
            </div>
        );
    }

    const Day = (day: Date) => {

        var dString = format(day, 'yyyy-MM-dd');
        var numOfDocs = 0
        var more: any = []
        var numMore = 0
        var elements: any = []

        for(let elem of docs){
            if(elem['file_modified_dt'].split('T')[0] === dString){
                if(numOfDocs < maxElements){
                    elements.push(Elem(elem['title'], elem));
                }else{
                    numMore++
                }
                more.push(IDs(elem['title'], elem));
                numOfDocs++;
            }
        }

        return(
            <div key={day.getDate()} className={styles.day_container}>
                <div className={styles.pivot}>
                    <div className={styles.inner_pivot}>
                        {day.getDate()}
                    </div>
                    {elements}
                    {numMore > 0 ? 
                    <div className={styles.button} onClick={() => {
                        // elements.push(more)
                        setElemDetails(more)
                        handleShow();
                    }}>
                        +{numMore} more
                    </div>
                    :
                    null}
                </div>
            </div>
        );
    }
    
    days.forEach(element => {
        dayComp.push(Day(element))
    })

    return(
        <div className={styles.box}>
            <div className={styles.container}>
                {dayComp}
                <Modal size="lg" centered={true} animation={false} className={styles.modal} show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Documents</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {elemDetails}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="outline" onClick={handleClose}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
}

export default WeekViewComp;