import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type DownloadState = {
    urls: string[],
    num: number,
}

let initialState: DownloadState = {
    urls: [],
    num: 0,
};

const downloadSlice = createSlice ({
    name: 'Download',
    initialState: initialState,
    reducers: {
        addUrl(state, action: PayloadAction<string>) {
            if(!state.urls.includes(action.payload)){
                state.urls.push(action.payload);
                state.num += 1;
            }
        },
        removeUrl(state, action: PayloadAction<string>) {
            const index = state.urls.indexOf(action.payload);
            if(index > -1){
                state.urls.splice(index, 1);
                state.num -= 1;
            }
        },
        clearUrls(state) {
            state.urls.splice(0);
            state.num = 0;
        },
    }
});

export const {addUrl, removeUrl, clearUrls} = downloadSlice.actions;
export default downloadSlice.reducer;