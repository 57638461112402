import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Image } from 'react-bootstrap';
import { RootState } from "../services/rootReducer";
import { doQuery } from "../services/Query_Slice";
import { buildQueryViewParams } from "./utils/queryHelper";

//import { getGradientMap } from "../services/View_Slice"

import DoughnutChart from "./DoughnutChart";
import Gradient from "./Gradient";
import { toastListProp, toastType, createToastProperties } from '../components/toast/Toast';
import { addToQueue} from "../services/Toasts_Slice";
import CONSTANTS from "../constants";
//import { startOfMonth, format } from 'date-fns';
import styles from "./css/Summary.module.css";
import logger from 'logger';

import { getChartData, getDoughnutData, getGradientData } from "./utils/summaryHelper";
import colormap from "colormap";
import styled from "styled-components";
import { getGradientMap } from 'services/View_Slice';
import OverlayComp from './OverlayComp';
import { exceptions } from 'winston';


interface Dictionary<T> {
    [Key: string]: T;
}

// new:aspectRatio: 1.5,
const theChartOptions={
    width: 100,
    height: 100,
    aspectRatio: 1.5,
    legend: {
      display: false,
      labels: {
          fontColor: '#495057'
      }
    },
    animation: {
        animateRotate: false,
        animateScale: false,
    },
    tooltips: {
        backgroundColor: CONSTANTS.UI.TOOLTIP_COLOR,
    }
};

const theChartOptions_mobile={
    width: 100,
    height: 100,
    aspectRatio: 2.7,
    legend: {
      display: false,
      labels: {
          fontColor: '#495057'
      }
    },
    animation: {
        animateRotate: false,
        animateScale: false,
    },
    tooltips: {
        backgroundColor: CONSTANTS.UI.TOOLTIP_COLOR,
    }
};


/*const theChartData = {
    labels: ['Toto: A', 'B', 'C'],
    datasets: [
        {
            data: [300, 50, 100],
            backgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56"
            ],
            hoverBackgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56"
            ]
        }]
    }*/






type chartEntryType = {
    angle: number,
    label: string,
    color: string,
}

const CHART_FIELD=CONSTANTS.UI.CHART_DATA_0;
const CHART_FIELD1=CONSTANTS.UI.CHART_DATA_1;

const StyledDiv = styled.div`
    width : 14px!important;
    min-width: 14px!important;
    height: 14px!important;
    min-height: 14px!important;
    padding-top: 0px;
    padding-bottom: 5px;
    margin-top: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
    margin-right: 10px;
    background-color: ${props => props.color};
    border-radius: 100%;
`;

type screenValues = {
    mobile: boolean,
    width: number
}


function Summary(props: React.PropsWithChildren<screenValues>) {

    const dispatch = useDispatch();

    // use effect to load initial view 
    const {request} = useSelector((state: RootState) => state.Query);
    const {facetSelected} = useSelector((state: RootState) => state.Facets);
    const {freeTextSearch, operator} = useSelector((state: RootState) => state.Search);
    const {loading} = useSelector((state:RootState) => state.Loading);


    // will trigger a query:
    // build view params: start = 0; rows = ITEM_PER_PAGE; dateRange = month; date = now
    // add to request
    //const dateMonth = format(startOfMonth(new Date()), 'yyyy-MM');
    const {viewType, cursorStart, listSortedFeld, listSortedOrder, calRangeUsed, calRangeValue, missionColorMap, gradientValues, gradientColorMap, pageRangeUsed, mobile } = useSelector((state: RootState) => state.View);
    //const viewparams = buildQueryViewParams(viewType, cursorStart, CONSTANTS.UI.ITEM_PER_PAGE, listSortedFeld, listSortedOrder, calRangeType.month, dateMonth);
    var viewparams: string | undefined = undefined;
    try{
        viewparams = buildQueryViewParams(viewType, cursorStart, pageRangeUsed, listSortedFeld, listSortedOrder, calRangeUsed, calRangeValue);
        logger.debug("@@@@ viewparams summary2 :" + viewparams);
    } catch(err) {
        const toastProperties: toastListProp = createToastProperties(toastType.error, "buildQueryViewParams", err.message);
        dispatch(addToQueue(toastProperties));
    }

    //
    const usedContainer = mobile ? styles.container_mobile: styles.container;
    const usedColStyle = mobile ? styles.col_mobile: styles.col;
    const usedTotalDivStyle = mobile ? styles.totalDiv_mobile: styles.totalDiv;
    const usedLegendDiv = mobile ? styles.legendDiv_mobile: styles.legendDiv;
    const usedDivLegendGrid = mobile ? styles.divLegendGrid_mobile: styles.divLegendGrid;
    const usedDivDoughnut = mobile ? styles.divDoughnut_mobile: styles.divDoughnut;

    useEffect(() => {
        if(!request.includes(CONSTANTS.STATE_NOT_SET)){
            logger.info("@@@@ summary2 query; request:" + request);
            logger.info("**** summary call request:" + request);
            dispatch(doQuery(request+"&"+viewparams))
            dispatch(getGradientMap(freeTextSearch ,facetSelected, operator));
        }

        //dispatch(getGradientMap(facetSelected));

    }, [dispatch, request, viewparams, freeTextSearch, facetSelected]); //, facetSelected]);

    // like:
    // {"numFound":5,
    // "facetFound":{"mission_ss":["cryosat-2|5"],"instrument_ss":["siral|4","siral-doris|1"],"productType_ss":["gom|2","gob-b|1","gop-c|1","iop|1"]}}
    const {numFound, facetFound} = useSelector((state: RootState) => state.Summary);

    // names ( of mission)
    var names: Array<string> = [];
    // values
    var values: Array<number> = [];


    //
    // build chart 0 data, and legend
    //
    //char data sall be like: const myData:any = [{angle: 1, label="aa", color="xx"}, {angle: 5, label="bb", color="xx"}, {angle: 2, label="cc", color="xx"}]
    //var myChartData: Array<chartEntryType> = [];

    // chart stuff
    //var notEmptyChart=false;
    // colors used, to also put the mission color into the other control: legend
    var theMissionColorMap: Dictionary<any> = {};
    // to put total on item (legend?)
    //var totalPerItem: Dictionary<Array<string>>={}
    var itemTotal: Dictionary<number>={}

    var numMission;
    //var total=0;
    if(facetFound!==undefined && facetFound[CHART_FIELD]!==undefined){

        numMission = facetFound[CHART_FIELD].length;
        for(var arInd in facetFound[CHART_FIELD]){
            const name: string = facetFound[CHART_FIELD][arInd].split('|')[0];
            names.push(name);
            const num: number = Number(facetFound[CHART_FIELD][arInd].split('|')[1]);
            values.push(num);
            //total+=num;

            logger.debug("@@@@@@@@@@@@@@ totalPerItem doing '"+arInd+"' name="+name+"; num="+num);

            /* put in TotalPerItem map. key is total.
            if (num in totalPerItem){
                totalPerItem[num].push(name);
            }else{
                var aList: Array<string> = [name];
                //aList.push(name);
                totalPerItem[num] = aList;
            }*/

            itemTotal[name] = num;
        }

        //logger.info("@@@@@@@@@@@@@@ names=" + JSON.stringify(names));
        //logger.info("@@@@@@@@@@@@@@ values=" + JSON.stringify(values));
        //logger.info("@@@@@@@@@@@@@@ totalPerItem=" + JSON.stringify(totalPerItem));
        logger.debug("@@@@@@@@@@@@@@ itemTotal=" + JSON.stringify(itemTotal));

        // jet map requires nshades to be at least size 6
        // hsv map requires nshades to be at least size 6
        var numColor = 11;
        if (numMission>11){
            numColor = numMission;
        }
        const colors = colormap({
            colormap: 'hsv',
            nshades: numColor,
            format: 'hex',
            alpha: 1
        })

        var n=0;
        for(var arInd2 in names){
            if(values[n]>0){
                //myChartData.push({angle: values[n], label: names[arInd2], color: colors[n]});
                theMissionColorMap[names[arInd2]] = colors[n];
                //notEmptyChart=true;

                //missionSpanMap[names[arInd2]] = styledSpan;
            }
            n+=1;
        }


    }else{
        logger.debug("Summary comp: no '"+ CHART_FIELD + "' field in summary facetFound map");
    }  


    //
    // build chart 1 data, and list
    //
    var missionInfo: Array<any> = [];
    var doughnutData: Array<any> = [];
    var gradientData: Array<any> = [];
    if(facetFound!==undefined && facetFound[CHART_FIELD1]!==undefined){
        // returns [notEmpty, myChartData, missionColorMap, itemTotal];
        missionInfo = getChartData(facetFound, CHART_FIELD1);

        // returns [notEmpty, result, itemTotal]
        doughnutData = getDoughnutData(numFound, facetFound, CHART_FIELD1, missionColorMap);

        // returns [notEmpty, result ]
        // result is array of single gradient bar
        gradientData = getGradientData(numFound, gradientValues, gradientColorMap);
    }

    logger.debug("@@@@@@@@@@@@@@ getGradientData returns:" + JSON.stringify(gradientData));



    /*var gradientData1 = {
        labels: ["2014", "2013", "2012", "2011"],
        
        datasets: [{
            label: 'Dataset 1',
            data: [727, 589, 537, 543, 574],
            backgroundColor: "rgba(63,103,126,1)",
            hoverBackgroundColor: "rgba(50,90,100,1)"
        },{
            label: 'Dataset 2',
            data: [238, 553, 746, 884, 903],
            backgroundColor: "rgba(163,103,126,1)",
            hoverBackgroundColor: "rgba(140,85,100,1)"
        },{
            label: 'Dataset 3',
            data: [1238, 553, 746, 884, 903],
            backgroundColor: "rgba(63,203,226,1)",
            hoverBackgroundColor: "rgba(46,185,235,1)"
        }]
    };*/


    /*const missionToRender = Object.keys(missionInfo[2]).map(item =>{
        return(
            <div key={item} className={usedLegendDiv}>
                <StyledDiv color={missionColorMap[item]}></StyledDiv>
                <span className={styles.legendItem}>{item}</span>
            </div>
        )
    })*/

    var missions: Array<string> = Array(10).fill('');
    var i: number = 0;
    for ( var aVar in missionInfo[2] ) {
        var row = Math.floor(i % 5);
        var col = Math.floor(i / 5) ;
        //console.log(" loop mission[" + aVar + "] type: "+ typeof(aVar) + "; color: " + missionInfo[2][aVar] + "; at row:" + row + "; col:" + col + "; store at ind:" + (row*2 + col));
        //console.log(" loop mission[" + aVar + "] splice index: " + (row*2 + col)); 
        //missions.splice(row*2 + col, 0, aVar);
        missions[row*2 + col]=aVar;
        i+=1;
    }
    //console.log(" loop mission result:" + missions);
 
    var nn: number=0;
    const missionToRender = missions.map(item =>{
        logger.debug(" loop mission missionToRender; item:" + item + "; color:" + missionColorMap[item]);
        nn+=1;
        return(
            <div key={nn} className={usedLegendDiv}>
                <StyledDiv color={missionColorMap[item]}></StyledDiv>
                <div key={item}  className={styles.legendItem}>{item} {CONSTANTS.LEGEND_DATES[item]}</div>
            </div>
        )
    })
    //console.log(" loop mission missionToRender length:" + missionToRender.length);
    
    var lg = props.width < 1500 ? [5, 7, 5, 7]: [2, 4, 1, 5] 

    return (
        <div>
            {loading || doughnutData[1]==undefined ? 
            <OverlayComp/>
            :
            <Container className={usedContainer}>
                <Row className={styles.row}>

                    {/* the files total */}
                    <Col xs={12} md={5} lg={lg[0]} className={usedColStyle}>
                        <div>
                            <label className={styles.labelSmall}>Reports Number</label>
                            <div className={usedTotalDivStyle}>
                            <Image src="/qrast/images/doc-icon.png" alt="Total of documents" className={styles.glIcons}/>
                            <label className={styles.labelTotal}>{numFound} files</label>
                            </div>
                        </div>
                    </Col>




                    {/* the mission list */}
                    {missionInfo!==undefined && missionInfo[0] ? 
                    <Col xs={12} md={7} lg={lg[1]} className={usedColStyle}>
                        <div>
                            <label className={styles.labelSmall}>{CONSTANTS.UI.TOP_CAT_LABEL}</label>
                            <div className={usedDivLegendGrid}>
                                {missionToRender}
                            </div>
                        </div>
                    </Col>
                    :
                    <Col xs={12} md={7} lg={3} className={usedColStyle}>
                        <label className={styles.labelSmall}>{CONSTANTS.UI.TOP_CAT_LABEL}</label>
                        <div>N/A</div>
                    </Col>}   




                    {/* the mission %  DoughnutChart */}
                    {missionInfo!==undefined && doughnutData[1]['labels'].length > 0 ? 
                        <Col xs={12} md={5} lg={lg[2]} className={usedColStyle}>
                            <div>
                            <label className={styles.labelSmall}>% for Mission</label>
                            <div className={usedDivDoughnut}>
                                <DoughnutChart chartData={doughnutData[1]} chartOptions={mobile ? theChartOptions_mobile : theChartOptions}/>
                            </div>
                            </div>
                        </Col>
                    :
                    <Col xs={12} md={5} lg={3} className={usedColStyle}>
                        <label className={styles.labelSmall}>% for Mission</label>
                        <div>N/A</div>
                    </Col>}



                    {/* the mission docType gradients */}
                    {missionInfo!==undefined && doughnutData[1]['labels'].length > 0 ? 
                    <Col xs={12} md={7} lg={lg[3]} className={usedColStyle}>
                        <div>
                        <label className={styles.labelSmall}>Type of report</label>
                        <Gradient missionInfo={missionInfo} doughnutData={doughnutData}/>
                        </div>
                    </Col>
                    :
                    <Col xs={12} md={7} lg={4} className={usedColStyle}>
                        <label className={styles.labelSmall}>Type of report</label>
                        <div>N/A</div>
                    </Col>}




                    {/*</div>*/}
                </Row>
            </Container>
            }
        </div>
    );
}

export default Summary;
