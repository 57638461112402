import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "./store";
import { setAllStates } from "./Loading_Slice";
import CONSTANTS from "../constants";
//import { paginationStateType } from "../components/Pagination";
import { setTotal } from 'services/View_Slice';
import logger from "../logger"
import { toastListProp, toastType, createToastProperties } from '../components/toast/Toast';
import { addToQueue } from "../services/Toasts_Slice";
var urldecode = require('urldecode');

interface Dictionary<T> {
    [Key: string]: T;
}

type queryState = {
    request: string;
    requestDone: string;
    result: Dictionary<any>,
    //resultInView: paginationStateType,
    facetCounts: Dictionary<any>,
    
}

let initialState: queryState = {
    request: CONSTANTS.STATE_NOT_SET,
    requestDone: CONSTANTS.STATE_NOT_SET,
    result: {},
    facetCounts: {},
};


const queryingSlice = createSlice({
    name: 'Query',
    initialState: initialState,
    reducers: {
        setResult(state, action: PayloadAction<Dictionary<any>>) {
            state.result = action.payload;
        },
        setQuery(state, action: PayloadAction<string>) {
            state.request = action.payload;
        },
        setQueryDone(state, action: PayloadAction<string>) {
            state.requestDone = action.payload;
        },
        setFacetCounts(state, action: PayloadAction<Dictionary<any>>) {
            state.facetCounts = action.payload;
        },
    }
});


export const doQuery = (request: string): AppThunk => async dispatch => {    
    dispatch(setAllStates({ loading: true, ok: true, error: "" }));

    //const {request} = useSelector((state: RootState) => state.Query);
    logger.info("@@@@ doQuery request encoded:" + request);
    logger.info("@@@@ doQuery request decoded:" + urldecode(request));

    logger.info("@@@@ doQuery URL:" + CONSTANTS.ENDPOINT.REST_API + CONSTANTS.REST_DO_QUERY + "?" + request);

    fetch(CONSTANTS.ENDPOINT.REST_API + CONSTANTS.REST_DO_QUERY + "?" + request)
        .then(response => {
            //logger.debug("response 0: " + Response);
            if(response.ok){
                logger.debug("doQuery response 0: ok");
                return response.json();
            }else{
                logger.error("doQuery response 0: not ok");
                throw new Error('Network response was not ok:' + response.status);
            }
        })
        .then(data => {
            logger.info("## doQuery data.payload: " + JSON.stringify(data));
            dispatch(setAllStates({loading:false, ok:true, error: ""}));
            dispatch(setResult(data.response));

            if(CONSTANTS.BACKEND_REPLY_IS_ERROR in data){
                const toastProperties: toastListProp = createToastProperties(toastType.error, "Query error", data[CONSTANTS.BACKEND_REPLY_IS_ERROR]);
                dispatch(addToQueue(toastProperties));
            }else{
                var numFound = 0;
                //var numPages = 0;
                //var page = 0;
                //var numDocs = 0;
                if (data.response.docs !== undefined){
                    numFound=Number(data.response["numFound"]);
                    //numDocs = data.response.docs.length;
                    if (data.response.docs.length>=CONSTANTS.UI.ITEM_PER_PAGE){
                        //numPages=Math.ceil(data.response.docs.length/CONSTANTS.UI.ITEM_PER_PAGE);
                        //page = 1;
                    }else{
                        //firstViewLength=data.response.docs.length;
                        if (data.response.docs.length>=0){
                            //page = 1;
                        }
                    }
                }

                dispatch(setFacetCounts(data.facet_counts));
                dispatch(setQueryDone(request));
                dispatch(setTotal(numFound));
            }
        })
        .catch((error) => {
            logger.error("## doQuery: error="+error);
            console.error(error.stack);
            dispatch(setAllStates({loading:false, ok:false, error: error.message}));
            const toastProperties: toastListProp = createToastProperties(toastType.error, "Query error", error.message);
            dispatch(addToQueue(toastProperties));
        })
};

export const {setResult, setQuery, setQueryDone, setFacetCounts} = queryingSlice.actions;

export default queryingSlice.reducer