import React  from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../services/rootReducer";
import { calRangeType } from "../services/View_Slice";
import { Container } from 'rsuite';
import logger from '../loggers/logger';
import { format, parseISO } from 'date-fns';
import CalendarComp from "./calendar/CalendarComp";
import styles from "./css/CalendarView.module.css";

interface Dictionary<T> {
    [Key: string]: T;
  }

type screenValues = {
  mobile: boolean,
  width: number
}

function CalendarView(props: React.PropsWithChildren<screenValues>) {

const { calRangeUsed, calRangeValue } = useSelector((state: RootState) => state.View);
const { result } = useSelector((state: RootState) => state.Query);

let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today
logger.debug("CalendarView comp; app todayStr:"+todayStr);

// make event list from result found
var docs: Array<Dictionary<string>> = []; 
if(result!==undefined && result["numFound"] !== undefined){
  docs = result["docs"];
}
const theEvents: Array<any> = [];
var n=0;
for(var aDoc in docs){
  //logger.info("@@@@@@@@@ app anInitialDat: " + n);
  const extProps: Dictionary<string> = {
    anId: String(n),
    aColor: '#fd89400',
    filename : String(n)+": "+docs[aDoc]['filename_ss'],
  }

  const anitem: Dictionary<string |  Dictionary<string>> = {
    id : String(n),
    title: docs[aDoc]['mission_ss'],
    start: docs[aDoc]['file_modified_dt'].replace(/T.*$/, ''),
    extendedProps: extProps,
  }
  theEvents.push(anitem);
  n++;
}

// date calculation
var adate: Date | undefined = undefined;

if(calRangeUsed !== undefined){
  switch(calRangeUsed){
      case calRangeType.month:
          adate = parseISO(String(calRangeValue+"-01"));
          logger.debug("CalendarView comp; app anInitialDat parsed date:" + adate);
          break;
      case calRangeType.year:
          adate = parseISO(String(calRangeValue+"-01-01"));
          break;
      case calRangeType.week:
          adate = parseISO(String(calRangeValue));
          //aWeek = getISOWeek(adate);
          break;
      default:
        logger.debug("CalendarView comp; app anInitialDat onSelect: unhandled calRangeUsed:" + calRangeUsed);
  }
}else{
    logger.error("CalendarView comp; app anInitialDat NOT parsed because undefined");
}

var anInitialDate: string = "";
if(adate!==undefined){
    anInitialDate = format(adate, 'yyyy-MM-dd');
}else{
    anInitialDate = format(new Date(), 'yyyy-MM-dd');
}
const anotherDate: Date = parseISO(anInitialDate);
logger.debug("CalendarView comp; app anInitialDat:"+anInitialDate);

    return (
        <Container className={styles.container}>
            <CalendarComp startDate={anotherDate} endDate={anotherDate} events={theEvents} mobile={props.mobile} width={props.width}/>
        </Container>
    )

}

export default CalendarView;
