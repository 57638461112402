import React, { useEffect, useState } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: any, otherRef: any | undefined, otherRef2: any | undefined) {

    const [result, setResult] = useState(false)
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if(otherRef !== undefined && otherRef2 !== undefined){
                if (ref.current && !ref.current.contains(event.target) && otherRef.current && !otherRef.current.contains(event.target) && otherRef2.current && !otherRef2.current.contains(event.target)) {
                    setResult(true);
                }
            }else if(otherRef !== undefined && otherRef2 === undefined){
                if (ref.current && !ref.current.contains(event.target) && otherRef.current && !otherRef.current.contains(event.target)) {
                    setResult(true);
                }
            }else if(otherRef === undefined && otherRef2 !== undefined){
                if (ref.current && !ref.current.contains(event.target) && otherRef2.current && !otherRef2.current.contains(event.target)) {
                    setResult(true);
                }
            }else if(otherRef === undefined && otherRef2 === undefined){
                if (ref.current && !ref.current.contains(event.target)) {
                    setResult(true);
                }
            }
            setResult(false);
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return {result};
}

export default useOutsideAlerter;