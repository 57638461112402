import React from 'react';
import { useDispatch, useSelector } from "react-redux";
//import { format, startOfMonth } from 'date-fns';
import { RootState } from "../services/rootReducer";
import { Container, Button, ButtonToolbar } from 'react-bootstrap';
import ListIcon from "@material-ui/icons/List";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ReactTooltip from "react-tooltip";
import CONSTANTS from "../constants";
import { setType, viewType as vt, calRangeType, cursorsType, setCalRangeUsed, setCursors } from "../services/View_Slice"
import styles from "./css/ViewSwitch.module.css";

function ViewSwitch() {

    const dispatch = useDispatch();

    const {viewType, mobile} = useSelector((state: RootState) => state.View);

    //
    //const usedLabelStyle = mobile ? styles.label_mobile: styles.label;
    const usedGlIconStyle = mobile ? styles.glIcon_mobile: styles.glIcon;

    const listDisabled = viewType===0 ? true : false;
    const listOutline = listDisabled ? "light" : "outline-secondary";
    const calDisabled = viewType===0 ? false : true;
    const calOutline = calDisabled ? "light" : "outline-secondary";

    //
    //const dateMonth = format(startOfMonth(new Date()), 'yyyy-MM');

    const {pageRangeUsed} = useSelector((state: RootState) => state.View);

    // the tooltip remain when cal/list is changed: use a timeout to hide it after delay
    var ideTooltipFunction = (inst?: any) => {
        //console.log("@@@@ ViewSwitch WILL HIDE TOOLTIP:" + inst);
        ReactTooltip.hide();
    };

    const listTooltip = <ReactTooltip className={styles.glToolTip} id={"tipList"} place="top" effect="solid" textColor='#000000' backgroundColor={CONSTANTS.UI.TOOLTIP_COLOR} afterShow={() => { setTimeout(() => ideTooltipFunction(), 2000) } }>
        Switch to list view
    </ReactTooltip>

    const calTooltip  = <ReactTooltip className={styles.glToolTip} id={"tipCal"} place="top" effect="solid" textColor='#000000' backgroundColor={CONSTANTS.UI.TOOLTIP_COLOR} afterShow={() => { setTimeout(() => ideTooltipFunction(), 2000) } }>
        Switch to calendar view
    </ReactTooltip>

    return(
        <Container className={styles.container}>
            <ButtonToolbar>
            <Button size="sm"
                    value="List"
                    onClick={(e) => {
                        //const aa = listTooltip as ReactTooltip;
                        // change view type
                        // reset cursor start, rows, etc
                        const cursorInfo: cursorsType = {
                            cursorStart: 0,
                            cursorRows: pageRangeUsed,
                        }
                        // update store
                        dispatch(setCursors(cursorInfo));
                        // dispatch(setCalRangeUsed(calRangeType.month));
                        //dispatch(setCalRangeValue(dateMonth));
                        dispatch(setType(vt.List));
                    }}
                    disabled={listDisabled}
                    data-for={"tipList"}
                    data-tip
                    >
                <ListIcon className={usedGlIconStyle}/>
            </Button>
            {/*<ReactTooltip id={"tipList"} place="top" effect="solid" textColor='#000000'backgroundColor='#ffb388'>
                Switch to list view
                </ReactTooltip>*/}
            {listTooltip}
            <Button size="sm"
                    value="Calendar"
                    
                    onClick={(e) => {
                        // change view type
                        // reset cursor start, rows, etc
                        const cursorInfo: cursorsType = {
                            cursorStart: 0,
                            cursorRows: pageRangeUsed,
                        }
                        // update store
                        dispatch(setCursors(cursorInfo));
                        // dispatch(setCalRangeUsed(calRangeType.month));
                        //dispatch(setCalRangeValue(dateMonth));
                        dispatch(setType(vt.Calendar));
                    }}
                    disabled={calDisabled}
                    data-for={"tipCal"}
                    data-tip
                    >
                <DateRangeIcon className={usedGlIconStyle}/>
            </Button>
            {/*<ReactTooltip id={"tipCal"} place="top" effect="solid" textColor='#000000'backgroundColor='#ffb388'>
                Switch to calendar view
                </ReactTooltip>*/}
            {calTooltip}
            </ButtonToolbar>
        </Container>
    )
}

export default ViewSwitch;
