import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../services/rootReducer";
import { Dropdown, Button } from 'react-bootstrap';
//import ReactTooltip from "react-tooltip";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ReactTooltip from "react-tooltip";
import { setSortField, setSortOrder, sortFieldType, reverseSortFieldType, sortType } from "../services/View_Slice";
import styles from "./css/SortControl.module.css";
import CONSTANTS from "../constants";
import logger from '../loggers/logger';
import { doQuery } from "../services/Query_Slice";
import { buildQueryViewParams } from "./utils/queryHelper";



const SortControl: any = ((props: any) =>{

    const dispatch = useDispatch();
    const {request} = useSelector((state: RootState) => state.Query);
    const {viewType, listSortedFeld, listSortedOrder, calRangeUsed, calRangeValue, pageRangeUsed, mobile} = useSelector((state: RootState) => state.View);

    //
    const usedLabelStyle = mobile ? styles.label_mobile: styles.label;
    const usedGlIconStyle = mobile ? styles.glIcon_mobile: styles.glIcon;
    const usedGlButton = mobile ? styles.glButton_mobile : styles.glButton; //manca mobile
    const usedGlDropdown = mobile ? styles.glDropdown_mobile: styles.glDropdown;

    const dropDownItemsToRender = Object.keys(CONSTANTS.UI.SORTING_FIELDS).map(item => {
        return(
            <Dropdown.Item key={item} eventKey={item} className={usedLabelStyle}>{item}</Dropdown.Item>
        )
    });

    const anIcon = listSortedOrder===sortType.descending ? <ArrowDownwardIcon className={usedGlIconStyle}/> : <ArrowUpwardIcon className={usedGlIconStyle}/>;
    const alternateSortOrder = listSortedOrder===sortType.descending ? sortType.ascending : sortType.descending;

    return(
        <div className={styles.container}>
            <div className={usedLabelStyle}>Sort by</div> 
            <Dropdown
                className={usedGlDropdown}
                onSelect={(e) => {
                    logger.info("@@@@@@@@@ SortControl onSelect:" + e);
                    switch(e){
                        case reverseSortFieldType.file_modified_dt:
                            logger.info("@@@@@@@@@ SortControl onSelect change sortby to:" + reverseSortFieldType.file_modified_dt);
                            logger.info("@@@@@@@@@ SortControl onSelect change sortby to solr param:" + sortFieldType[reverseSortFieldType.file_modified_dt]);
                            dispatch(setSortField(sortFieldType.date));

                            // will trigger a query:
                            // build view params
                            // add to request 
                            const viewparams = buildQueryViewParams(viewType, 0, pageRangeUsed, sortFieldType[reverseSortFieldType.file_modified_dt], listSortedOrder, calRangeUsed, calRangeValue);
                            logger.info("@@@@@@@@@ SortControl onSelect viewparams change sorting:" + viewparams);

                            // query
                            dispatch(doQuery(request + "&"+ viewparams))

                            break;
                        case reverseSortFieldType.path_basename_s:
                            logger.info("@@@@@@@@@ SortControl onSelect change sortby to:" + reverseSortFieldType.path_basename_s);
                            logger.info("@@@@@@@@@ SortControl onSelect change sortby to solr param:" + sortFieldType[reverseSortFieldType.path_basename_s]);
                            dispatch(setSortField(sortFieldType.filename));

                            // will trigger a query:
                            // build view params
                            // add to request 
                            const viewparams2 = buildQueryViewParams(viewType, 0, pageRangeUsed, sortFieldType[reverseSortFieldType.path_basename_s], listSortedOrder, calRangeUsed, calRangeValue);
                            logger.info("@@@@@@@@@ SortControl onSelect viewparams change sorting: " + viewparams2);

                            // query
                            dispatch(doQuery(request + "&"+ viewparams2))

                            break;
                        default:
                            logger.info("@@@@@@@@@ SortControl onSelect: NOTHING DONE");
                            break
                    }
                }}
                >
                <Dropdown.Toggle variant="outline" id="dropdown-basic"
                    size="sm"
                    data-for="tipSortSwitch"
                    data-tip>
                    {reverseSortFieldType[listSortedFeld]}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {dropDownItemsToRender}
                </Dropdown.Menu>

                <Button variant="outline-secondary" size="sm"
                            value={listSortedOrder}
                            className={usedGlButton}
                            data-for="tipSortAD"
                            data-tip
                            onClick={(e) => {
                                logger.info("@@@@@@@@@ SortControl change sorting direction to: " + alternateSortOrder);
                                // update store
                                dispatch(setSortOrder(alternateSortOrder));

                                // will trigger a query:
                                // build view params
                                // add to request 
                                const viewparams = buildQueryViewParams(viewType, 0, pageRangeUsed, listSortedFeld, alternateSortOrder, calRangeUsed, calRangeValue);
                                logger.info("@@@@ viewparams change sorting direction: " + viewparams);

                                // query
                                dispatch(doQuery(request + "&"+ viewparams))

                            }}
                            >
                            {anIcon}
                    </Button>
            </Dropdown>
            <ReactTooltip className={styles.glToolTip} id="tipSortAD" place="top" effect="solid" textColor='#000000'backgroundColor={CONSTANTS.UI.TOOLTIP_COLOR}>
                        Ascending/Descending
                    </ReactTooltip>
            <ReactTooltip className={styles.glToolTip} id="tipSortSwitch" place="top" effect="solid" textColor='#000000'backgroundColor={CONSTANTS.UI.TOOLTIP_COLOR}>
                Sort by date/name
            </ReactTooltip>
        </div>
    )

});

export default SortControl;
