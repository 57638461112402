import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {toastListProp, positionType} from '../components/toast/Toast';

//
export type toastState = {
    toastQueue: Array<toastListProp>,
    position: positionType,
    autoDismiss: boolean,
    dismissTime: number,
  }
  
//
const initialState: toastState = {
    toastQueue : [],
    position : positionType.top_right,
    autoDismiss: false,
    dismissTime: 2500,
}


//
const toastsSlice = createSlice({
    name: 'Toasts',
    initialState: initialState,
    reducers: {
        addToQueue(state, action: PayloadAction<toastListProp>) {
            //console.log("#### toastsSlice.addToQueue:"+JSON.stringify(action.payload));
            state.toastQueue.push(action.payload);
        },
        removeFromQueue(state, action: PayloadAction<number>) {
            //console.log("#### toastsSlice.removeFromQueue; id:"+action.payload);
            const itemIndex = state.toastQueue.findIndex(e => e.id === action.payload);
            //console.log("#### toastsSlice.removeFromQueue; at index:"+itemIndex);
            state.toastQueue.splice(itemIndex, 1);
        },
        setQueue(state, action: PayloadAction<Array<toastListProp>>) {
            state.toastQueue = action.payload;
        },
        setPosition(state, action: PayloadAction<positionType>) {
            state.position = action.payload;
        },
        setAutoDismiss(state, action: PayloadAction<boolean>) {
            state.autoDismiss = action.payload;
        },
        setDismissTime(state, action: PayloadAction<number>) {
            state.dismissTime = action.payload;
        },
    }
});

export const {addToQueue, removeFromQueue, setQueue, setPosition, setAutoDismiss, setDismissTime} = toastsSlice.actions;

export default toastsSlice.reducer