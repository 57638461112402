import React, { useEffect } from 'react';
import { Container, Card  } from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {doQuery, setQuery} from "../services/Query_Slice";
import { getCurrentFacets } from 'services/Facets_Slice';
import { getMissionColorMap } from 'services/View_Slice';
import {RootState} from "../services/rootReducer";
import {buildQueryString} from 'utils/solr';
import styles from "./css/Query.module.css";
import logger from "../logger"
import { getSummaryShort } from 'services/Summary_Slice';
//import { toastListProp, toastType, createToastProperties } from '../components/toast/Toast';
//import { addToQueue} from "../services/Toasts_Slice";
import CONSTANTS from "../constants";
//
var urldecode = require('urldecode');

function Query() {

    const {facetMap, facetSelected, currentFacetMap} = useSelector((state: RootState) => state.Facets);


    const {result, request, requestDone, facetCounts} = useSelector((state: RootState) => state.Query);
    const {freeTextSearch, operator} = useSelector((state: RootState) => state.Search);
    const {numFound, facetFound} = useSelector((state: RootState) => state.Summary);
    const {showDebugInfo, total, viewType, cursorStart, cursorRows, listSortedFeld, listSortedOrder, calRangeUsed, calRangeValue, missionColorMap} = useSelector((state: RootState) => state.View);


    // use effect to:
    // - set query string in store
    // - load summary
    const dispatch = useDispatch();
    useEffect(() => {
            // if we miss some essential data (if some api call has failed), don't break the UI but display notification
            if('loading' in facetMap){
                //const toastProperties: toastListProp = createToastProperties(toastType.error, "Facets not loaded (at Query)", "Try reloading the page...");
                //dispatch(addToQueue(toastProperties));
            }else{
                const query = buildQueryString('*:*', freeTextSearch, undefined, undefined, facetSelected, facetMap, operator);
                logger.debug("Query.tsx useEffect.setQuery: start:"+undefined+"; stop:"+ undefined + "; facetSelected:"+facetSelected);
                dispatch(setQuery(query));
                dispatch(getSummaryShort(facetMap, facetSelected, freeTextSearch, operator))
            }
    }, [dispatch, freeTextSearch, facetSelected, facetMap, freeTextSearch, operator]);




    return (
        <Container className={styles.container}>
            {showDebugInfo === true ? 
            
            <div>
            <h1>Debug info:</h1>
            <div>Version: {CONSTANTS.VERSION}</div>

            <br/><br/>
            <div>
            <h1>Actions:</h1>
            <button
                onClick={(e) => {
                    dispatch(doQuery(request))
                }}>
                [ Run query ]
            </button>

            <button
                onClick={(e) => {
                    dispatch(getCurrentFacets(facetMap, facetSelected, operator))
                }}>
                [ Run getCurrentFacets ]
            </button>
            
            <button
                onClick={(e) => {
                    dispatch(getSummaryShort(facetMap, facetSelected, freeTextSearch, operator))
                }}>
                [ Run getSummaryShort ]
            </button>

            <button
                onClick={(e) => {
                    dispatch(getMissionColorMap())
                }}>
                [ Run getMissionColorMap ]
            </button>
            </div>


            <br/><br/>

            <h2>Search field:</h2>
            <div>
            freeTextSearch:{freeTextSearch}
            </div><br/>


            <br/><br/>

            <h2>View:</h2>

            <div>
                UI: missions color map:{JSON.stringify(missionColorMap)}
            </div><br/>

            <div>
                Total:{total}
            </div><br/>
            <div>
                Type:{viewType}
            </div><br/>
            <div>
                Cursor start:{cursorStart}<br/>
                Cursor rows:{cursorRows}<br/>
                Sorted field:{listSortedFeld}<br/>
                Sorted order:{listSortedOrder}<br/>
                Calendar range type:{calRangeUsed}<br/>
                calendar range:{calRangeValue}<br/>
            </div><br/>


            <h2>Summary:</h2>
            <div>
                Total found:{JSON.stringify(numFound)}
            </div><br/>
            <div>
                Facets found:{JSON.stringify(facetFound)}
            </div><br/>


            <h2>Facets:</h2>
            <div>
                All facets:{JSON.stringify(facetMap)}
            </div><br/>
            <div>
                Current facets:{JSON.stringify(currentFacetMap)}
            </div><br/>

            <div>
                Facets selected:{JSON.stringify(facetSelected)}
            </div><br/>

            <div>
                Facet counts: {JSON.stringify(facetCounts)}
            </div><br/><br/>

            <h2>Query:</h2>
            <div>
                Query request:
                <Card className={styles.glMaxWidth}>
                    {urldecode(JSON.stringify(request))}
                </Card>
            </div><br/>

            <div>
                Query request done:
                <Card className={styles.glMaxWidth}>
                    {urldecode(JSON.stringify(requestDone))}
                </Card>
            </div><br/>

            <div>
                Query result:
                <Card className={styles.glMaxWidth}>
                    {JSON.stringify(result)}
                </Card>
            </div>
            <br/><br/>
            
            </div>:<span></span>
            }

        </Container>
    );
}

export default Query;
