import { format, getISOWeek } from 'date-fns';



//get first monday
export function getMonday(aDate: Date) {
    var d: Date = new Date(aDate);
    const day: number = d.getDay();
    const diff: number = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday (0sun, 1mon, 2tue... and so on)
    return new Date(d.setDate(diff));
}

//get next monday
export function getNextMonday(aDate: Date) {
    const d: Date = new Date(aDate.setDate(aDate.getDate() + (1 + 7 - aDate.getDay()) % 7));
    return d;
}

//
// used in DateNav when switching into week view
// default format is: 'yyyy-MM-dd'
//
export function dateToWeekDate(aStrDate: string, aFormat: string): string{
    var tmp = new Date(aStrDate);
    if(getISOWeek(tmp) !== 1) //if its different from the 1st week of the year get next monday
        tmp = getNextMonday(tmp);
    else{
        tmp = getMonday(tmp);
    }//else get first monday
    return format(tmp, aFormat);
}

export function getDaysInMonth(month: number, year: number): Date[] {
    var date: Date = new Date(year, month, 1);
    if(date.getDay() !== 1){
        date = getMonday(date);
    }
    var days = [];
    while(date.getDay() !== 0){
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    
    while(date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    while(date.getDay() !== 1){
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return days;
}

export function getDaysInWeek(today: Date): Date[]{
    var date: Date = today;
    if(date.getDay() !== 1){
        date = getMonday(date);
    }
    var days = [];
    while(date.getDay() !== 0){
        days.push(new Date(date))
        date.setDate(date.getDate() + 1);
    }
    days.push(new Date(date))
    return days;
}