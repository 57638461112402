import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap';

//
import logger from "logger"
import Summary from "./components/Summary";
import Facets from "./components/Facets";
import QrastMain from "./components/QrastMain";
import ToastsContainer from "./components/ToastsContainer";
import { toastListProp, toastType, createToastProperties } from './components/toast/Toast';
import { addToQueue } from "./services/Toasts_Slice";
import { parseQueryStringForFacets, 
    PARAM_SORTBY,
    PARAM_SORTBYDIRECTION,
    PARAM_SHOW,
    PARAM_VIEWBY,
    PARAM_DATESTART,
    PARAM_SEARCHBAR,
    PARAM_VIEWTYPE

 } from "./utils/urlParser";

//
import { sortType, sortFieldType, calRangeType, viewType, // exported types
    setType,
    setMobile, setUiDimensions,
    setSortField, setSortOrder,
    setPageRangeUsed,
    setCalRangeUsed, setCalRangeValue,
    setUrlFacetConstraints, setUrlConstraintsUsed,
 } from "./services/View_Slice";
import { FacetsMap, FacetsMapPayload, getCurrentFacets, setCurrentFacetsMap, setFacetSelected } from 'services/Facets_Slice';
import { setFreeTextSearch } from 'services/Search_Slice';
//
import { parseISO, format, startOfMonth } from 'date-fns';
import { dateToWeekDate } from "./components/utils/dateHelper";

//
import CONSTANTS from "./constants"
//

import {RootState} from "services/rootReducer";

//
import styles from "./QrastLayout.module.css";

import useOutsideAlerter from "./components/outsideClick"

export const MOBILE_THREASHOLD = 1500;

type propsTypes = {
    useMobileView: boolean,
    willShowSidebar: boolean,
    searchParams: URLSearchParams
}

const initialSizeDone: boolean = false;
const initialFacetConstraints: Array<string> = [];

const QrastLayout = (props: propsTypes) => {

    //
    const [sizeDone, setSizeDone] = useState(initialSizeDone);
    const [facetConstraints, setFacetConstraints] = useState(initialFacetConstraints);

    // get from props
    const {searchParams, useMobileView, willShowSidebar, } = props;

    const {mobile, calRangeUsed, urlFacetsConstraints, urlConstraintsUsed} = useSelector((state: RootState) => state.View);
    const {facetMap} = useSelector((state: RootState) => state.Facets);

    // set props in state
    const [mobileView, setMobileView] = useState(useMobileView);
    const [showSidebar, setShowSidebar] = useState(willShowSidebar);

    // listen to size change, set into state
    const [dimensions, setDimensions] = React.useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })

    const dispatch = useDispatch();

    //var urlQueryFacetsConstraint: Array<string> = [];

    React.useEffect(() => {

        //
        // NOTES:
        // - need to use the URL params just once, if not the interface will re-query at every refresh(resize)
        // 
        // => set URL parameters into store: facets constraints
        //
        logger.info("#### QrastLayout comp; store urlFacetsConstraints:" + urlFacetsConstraints);
        //var testUrlParams = !urlConstraintsUsed;
        var urlQueryFacetsConstraint: string[] = [];
        if(!urlConstraintsUsed){
            urlQueryFacetsConstraint = parseQueryStringForFacets(searchParams);
            logger.info("#### QrastLayout comp; Constraint; urlQueryFacetsConstraint parsed:" + urlQueryFacetsConstraint);
            if(urlQueryFacetsConstraint.length>0){
                dispatch(setFacetSelected(urlQueryFacetsConstraint));
                dispatch(setUrlFacetConstraints(urlQueryFacetsConstraint));
                logger.debug("#### QrastLayout comp; Constraint urlQueryFacetsConstraint not empty: set facets selected in store");
            }else{
                logger.debug("#### QrastLayout comp; Constraint urlQueryFacetsConstraint empty");
            }
        }else{
            logger.info("#### QrastLayout comp; Constraint urlQueryFacetsConstraint not empty");
        }

        // sort by constraints
        // date/filename
        if(!urlConstraintsUsed){
            if (searchParams.get(PARAM_SORTBY) !== null){
                const by = searchParams.get(PARAM_SORTBY);
                logger.info("#### QrastLayout comp; Constraint PARAM_SORTBY=" + by);
                if(by !== null && (by==='date' || by==='filename')){
                    dispatch(setSortField(sortFieldType[by]))
                }else{
                    logger.error("#### QrastLayout comp; Constraint PARAM_SORTBY; invalid value:" + by);
                    const toastProperties: toastListProp = createToastProperties(toastType.error, "Param error: invalid "+PARAM_SORTBY, by);
                    dispatch(addToQueue(toastProperties));
                }
                logger.debug("#### QrastLayout comp; Constraint PARAM_SORTBY=" + by);
            }
        }
        // sort asc/desc
        if(!urlConstraintsUsed){
            if (searchParams.get(PARAM_SORTBYDIRECTION) !== null){
                const direction = searchParams.get(PARAM_SORTBYDIRECTION);
                logger.info("#### QrastLayout comp; Constraint PARAM_SORTBYDIRECTION=" + direction);
                if(direction==='asc'){
                    dispatch(setSortOrder(sortType.ascending))
                }else if(direction==='desc'){
                    dispatch(setSortOrder(sortType.descending))
                }else{
                    logger.error("#### QrastLayout comp; Constraint PARAM_SORTBYDIRECTION; invalid value:" + direction);
                    const toastProperties: toastListProp = createToastProperties(toastType.error, "Param error: invalid "+PARAM_SORTBYDIRECTION, direction);
                    dispatch(addToQueue(toastProperties));
                }
            }
        }

        if(!urlConstraintsUsed){
            // show (pagination range)
            var par = searchParams.get(PARAM_SHOW);
            if (par !== null){
                logger.info("#### QrastLayout comp; Constraint PARAM_SHOW=" + par);
                var pageNumRange: number = 10;
                if (par!==null && ( par==='10' || par==='25' || par==='50' || par==='100')){
                    pageNumRange = Number.parseInt(par);
                    dispatch(setPageRangeUsed(pageNumRange));
                }else{
                    logger.error("#### QrastLayout comp; Constraint PARAM_SHOW; invalid value:" + par);
                    const toastProperties: toastListProp = createToastProperties(toastType.error, "Param error: invalid "+PARAM_SHOW, par);
                    dispatch(addToQueue(toastProperties));
                }
            }
        }

        // view (date range + start)
        // year/month/week
        var dateRange:string|null = 'month';
        if(!urlConstraintsUsed){
            dateRange = searchParams.get(PARAM_VIEWBY);
            if (dateRange !== null){
                logger.debug("#### QrastLayout comp; (setCalRangeValue pb) Constraint PARAM_VIEWBY=" + dateRange);
                if (dateRange!==null && ( dateRange==='year' || dateRange==='month' || dateRange==='week')){
                    dispatch(setCalRangeUsed(calRangeType[dateRange]));
                }else{
                    logger.error("#### QrastLayout comp; (setCalRangeValue pb) Constraint PARAM_VIEWBY; invalid value:" + dateRange);
                    const toastProperties: toastListProp = createToastProperties(toastType.error, "Param error: invalid "+PARAM_VIEWBY, dateRange);
                    dispatch(addToQueue(toastProperties));
                }
            }else{ //default to month
                    //logger.error("#### QrastLayout comp; (setCalRangeValue pb) NO Constraint PARAM_VIEWBY; default to: month");
                    //dispatch(setCalRangeUsed(calRangeType['month']));
            }
            // date start yyyy-MM-dd
            par = searchParams.get(PARAM_DATESTART);
            if (par !== null){
                logger.debug("#### QrastLayout comp; (setCalRangeValue pb) Constraint PARAM_DATESTART=" + par);
                try{
                    var tmp_date = parseISO(par)
                    logger.debug("#### QrastLayout comp; (setCalRangeValue pb) Constraint PARAM_DATESTART ok, parsed=" + tmp_date);
                    var dateOK = '';
                    if(dateRange!==null){ // also have a dateRange in the URL params
                        if(dateRange==='year'){
                            dateOK = format(tmp_date, 'yyyy');
                        }else if(dateRange==='month'){
                            dateOK = format(tmp_date, 'yyyy-MM');
                        }else if(dateRange==='week'){
                            dateOK = dateToWeekDate(par, 'yyyy-MM-dd');
                        }else{
                            logger.error("#### QrastLayout comp; Constraint PARAM_DATESTART; invalid case for param dateRange:" + dateRange);
                        }
                    }else{
                        /* dont have a dateRange in the URL params, use current
                        if(calRangeUsed===calRangeType.year){
                            dateOK = format(tmp_date, 'yyyy');
                        }else if(calRangeUsed===calRangeType.month){
                            dateOK = format(tmp_date, 'yyyy-MM');
                        }else if(calRangeUsed===calRangeType.week){
                            dateOK = format(tmp_date, 'yyyy-MM-dd');
                        }else{
                            logger.error("#### QrastLayout comp; (setCalRangeValue pb) Constraint PARAM_DATESTART; invalid case for calRangeUsed:" + calRangeUsed);
                        }*/
                        dateOK = format(tmp_date, 'yyyy-MM');
                    }
                    if(dateOK!==''){
                        logger.debug("#### QrastLayout comp; (setCalRangeValue pb) Constraint PARAM_DATESTART; setCalRangeValue dateOK:"+dateOK);
                        dispatch(setCalRangeValue(dateOK));
                    }else{
                        logger.error("#### QrastLayout comp; (setCalRangeValue pb) Constraint PARAM_DATESTART; setCalRangeValue dateOK not good:'"+dateOK+"'");
                    }
                }catch{
                    logger.error("#### QrastLayout comp; (setCalRangeValue pb) Constraint PARAM_DATESTART; invalid value:" + par);
                    const toastProperties: toastListProp = createToastProperties(toastType.error, "Param error: invalid "+PARAM_DATESTART, par);
                    dispatch(addToQueue(toastProperties));
                }
            }else{ // no PARAM_DATESTART given, if PARAM_VIEWBY given, adjust calRangeValue if needed 
                // or set calRangeValue dirrectly (we don't care anymore of the store initial value)
                if(dateRange !== null){
                    if(dateRange=='year' && calRangeUsed!==calRangeType.year){
                        logger.debug("#### QrastLayout comp; PARAM_DATESTART VS calRangeValue: change calRangeValue to year date");
                        const newDate = format(new Date(), 'yyyy');
                        dispatch(setCalRangeValue(newDate));
                    }else if(dateRange=='month' && calRangeUsed!==calRangeType.month){
                        logger.debug("#### QrastLayout comp; PARAM_DATESTART VS calRangeValue: change calRangeValue to month date");
                        const newMonthDate = format(startOfMonth(new Date()), 'yyyy-MM');
                        dispatch(setCalRangeValue(newMonthDate));
                    }else if(dateRange=='week' && calRangeUsed!==calRangeType.week){
                        logger.debug("#### QrastLayout comp; PARAM_DATESTART VS calRangeValue: change calRangeValue to week date");
                        const newDate = format(startOfMonth(new Date()), 'yyyy-MM-dd');
                        const newWeekDate = dateToWeekDate(newDate, 'yyyy-MM-dd');
                        dispatch(setCalRangeValue(newWeekDate));
                    }
                }
            }
            
        }

        // free search field
        if(!urlConstraintsUsed){
            const searchStr = searchParams.get(PARAM_SEARCHBAR);
            if (searchStr !== null){
                logger.info("#### QrastLayout comp; Constraint PARAM_SEARCHBAR=" + searchStr);
                dispatch(setFreeTextSearch(searchStr))
            }
        }
        

        // view/cal switch
        if(!urlConstraintsUsed){
            const viewTypeFlag = searchParams.get(PARAM_VIEWTYPE);
            if (viewTypeFlag !== null){
                logger.info("#### QrastLayout comp; Constraint PARAM_VIEWTYPE=" + viewTypeFlag);
                if(viewTypeFlag==="cal"){
                    dispatch(setType(viewType.Calendar))
                }else if(viewTypeFlag==="list"){
                    dispatch(setType(viewType.List))
                }else{
                    logger.error("#### QrastLayout comp; (set view type pb) Constraint PARAM_VIEWTYPE; invalid value:" + viewTypeFlag);
                    const toastProperties: toastListProp = createToastProperties(toastType.error, "Param error: invalid "+PARAM_VIEWTYPE, viewTypeFlag);
                    dispatch(addToQueue(toastProperties));
                }
            }
        }

        if(!urlConstraintsUsed){
            logger.info("$$$$ QrastLayout comp; set setUrlConstraintsUsed to true");
            dispatch(setUrlConstraintsUsed(true));
        }


        const updateViewState = (w: number, h: number) => {
            logger.debug("#### QrastLayout comp; updateViewState clientWidth=" + document.documentElement.clientWidth);
            logger.debug("#### QrastLayout comp; updateViewState passed dimensions: width=" + w + "; height=" + h);
            logger.debug("#### QrastLayout comp; updateViewState will store in store: width=" + dimensions.width + "; height=" + dimensions.height);
            dispatch(setUiDimensions(dimensions));
    
            if (document.documentElement.clientWidth <= CONSTANTS.MOBILE_THREASHOLD) {
                setMobileView(true);
                setShowSidebar(false);
                dispatch(setMobile(true))
                logger.debug("#### QrastLayout comp; updateViewState goto mobile"); 
            } else {
                setMobileView(false);
                setShowSidebar(true);
                dispatch(setMobile(false))
                logger.debug("#### QrastLayout comp; updateViewState goto normal");
            }
        }


        logger.debug("#### QrastLayout comp; at useEffect; width="+ dimensions.width + "; height="+  dimensions.height);
        logger.debug("#### QrastLayout comp; at useEffect; window.width="+ window.innerWidth + "; window.height="+  window.innerHeight);

        if(!sizeDone){ // set size related vars at comp monting
            logger.debug("#### QrastLayout comp; at useEffect; call initial updateViewState");
            updateViewState(dimensions.width, dimensions.height);
            setSizeDone(true);
        }else{
            //logger.debug("#### QrastLayout comp; at useEffect; initial updateViewState already called");
            logger.debug("#### QrastLayout comp; at useEffect; call initial updateViewState");
            // updateViewState(dimensions.width, dimensions.height);
            //setSizeDone(true);
        }

        function handleResize() {
            setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
            });
            updateViewState(dimensions.width, dimensions.height);
            logger.debug("#### QrastLayout comp; resized to width="+ dimensions.width + "; height="+  dimensions.height);
        }

        window.addEventListener('resize', handleResize);

        // this will clean up the event every time the component is re-rendered
        return function cleanup() {
            window.removeEventListener('resize', handleResize);
        };
    }, [dimensions, sizeDone, dispatch, searchParams])

    const toggleSideBar = () => {
        console.log("button " + showSidebar)
        setShowSidebar(!showSidebar);
    }

    const wrapRef = useRef(null)
    const otherRef = useRef(null)
    const clicked = useOutsideAlerter(wrapRef, otherRef, undefined).result
    console.log(clicked)
    if(clicked && showSidebar && mobile){
        console.log("func " + showSidebar)
        setShowSidebar(false)
    }

    logger.info("@@@ Facets facetConstraints in QrastLAyout="+ JSON.stringify(facetConstraints));

    //below:  {/*<div className = { `${styles.sidebar} ${mobileView === true && styles.floatingSidebar} `}>*/}
    return (

        <div className = { `${styles.glContainer} ${mobileView === true && styles.glMobileview} `}> 

        <div className={styles.glSummary}>
            <Summary width={dimensions.width} mobile={mobileView}/>
        </div>


        { showSidebar ?
            <div ref={wrapRef} className = { mobileView === true ? styles.glFloatingSidebar : styles.glSidebar} >
                <Facets/>  {/*constraint={facetConstraints}/>*/}
            </div> :
            <div className = {  styles.glHiddenSidebar } >
                <Facets/>  {/*constraint={facetConstraints}/>*/}
            </div>
        } 


        <div className={styles.glMain}>
            <QrastMain width={dimensions.width} mobile={mobileView}/>
            <div className={styles.divInfo}>
                {CONSTANTS.VERSION}    Info: width:{dimensions.width}; height:{dimensions.height}; mobileView:{mobileView?'Yes':'No'}; showSidebar:{showSidebar?'Yes':'No'}
            </div>
        </div>


        {mobileView ? 
            <Button ref={otherRef} variant="outline-light" className={styles.fixBottomRight}
                onClick={toggleSideBar} 
            >
            <img src="/qrast/images/filter-icon.png" alt="Show/Hide filters" className={styles.glIcons}/>
            </Button>
        : null}

        <ToastsContainer/>
    </div>
    ); 
    
}


export default QrastLayout;
