import React from "react"
import {useSelector} from "react-redux"
import {RootState} from "services/rootReducer"
import styles from "../components/css/OverlayComp.module.css"

const OverlayComp = () => {

    const {mobile} = useSelector((state: RootState) => state.View)

    const usedOverlay = mobile ? styles.overlay_mobile : styles.overlay
    const usedLoader = mobile ? styles.loader_mobile : styles.loader

    return(
        <div className={usedOverlay}>
            <div className={usedLoader}></div>
        </div>
    );
}

export default OverlayComp;