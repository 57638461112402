import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {RootState} from "../../services/rootReducer"
import styles from "./css/MonthViewComp.module.css";
import {getDaysInMonth} from "../utils/dateHelper";
import { format } from "date-fns";
import { Button, Modal } from "react-bootstrap";
import {calRangeType, setCalRangeUsed} from "../../services/View_Slice"
import styled from "styled-components";
import CONSTANTS from "../../constants"
import OverlayComp from "../OverlayComp";
import { buildQueryViewParamsCal } from "../utils/queryHelper";
import { createToastProperties, toastListProp, toastType } from "components/toast/Toast";
import { addToQueue } from "services/Toasts_Slice";

const StyledDiv = styled.div`
    background-color: ${props => props.color};
`;

const MonthViewComp = (props:any) => {

    //redux store variables
    const {calRangeValue, missionColorMap, mobile, heatmap} = useSelector((state: RootState) => state.View);
    const {facetSelected} = useSelector((state: RootState) => state.Facets);
    const {freeTextSearch} = useSelector((state: RootState) => state.Search);

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();

    //modal states
    const [show, setShow] = useState(false);
    const [elemDetails, setElemDetails] = useState(null);
    const [modalDocs, setModalDocs] = useState(0);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //maxelements for day
    var maxElements = mobile ? 3: 4;
    if(props.width < 1650 && !mobile)
        maxElements = 4;

    const month = parseInt(calRangeValue.split('-')[1]) - 1
    const year = parseInt(calRangeValue.split('-')[0])

    //week days
    const d: any = [];
    const l: any = [];
    const mar: any = [];
    const mer: any = [];
    const g: any = [];
    const v: any = [];
    const s: any = [];

    //on mount setDates
    const dates = getDaysInMonth(month, year)

    //ID functional component 
    const IDs = (id: string, elem: any) => {
        const item = elem;
        const mission: string = item[CONSTANTS.UI.TOP_CAT];
        const title: string =  item['title'];
        const doctype: string = item['documenttype_ss']
        var aColor: string = '#000000';
        if(missionColorMap !== undefined && missionColorMap[mission] !== undefined && missionColorMap[mission] !== undefined){
            aColor = missionColorMap[mission];
        }

        //render ID
        return(
            <a key={elem['id']} className={styles.link} href={elem['id']} target="_blank" rel="noopener noreferrer">
                <StyledDiv className={styles.styleDiv2} color={aColor}/>
                <div className={styles.elem_title}>
                    {title}
                </div>
                <div className={styles.elem_prop}>
                    {doctype}
                </div>
            </a>
        )
    }

    //Elem functional component (renders element)
    const Elem = (id: string, elem: any) => {
        const item = elem;
        const mission: string = item[CONSTANTS.UI.TOP_CAT];
        var aColor: string = '#000000';
        if(missionColorMap !== undefined && missionColorMap[mission] !== undefined && missionColorMap[mission] !== undefined){
            aColor = missionColorMap[mission];
        }
        const usedTitle = props.width < 1200 ? styles.elem_title_small: styles.elem_title
        const usedElem = mobile ? styles.elem_mobile: styles.elem
        return(
            <div key={elem['path_basename_s']} className={usedElem} 
            onClick={() => {
                window.open(item['id'])
            }}>
                <StyledDiv className={styles.styleDiv} color={aColor}/>
                <div className={usedTitle}>
                    {id}
                </div>
            </div>
        );
    }

    function fetchResults(yUrl: string){
        return fetch(yUrl)
        .then(response => response.json())
        .then(function(response) {
            console.log(JSON.stringify(response.query));

            return response 
        })
        .catch(function(error) {
            console.log(error);
        });    
    }

    const Group = (mission: string, number: number, dString: string) => {
        var aColor: string = '#000000';
        if(missionColorMap !== undefined && missionColorMap[mission] !== undefined && missionColorMap[mission] !== undefined){
            aColor = missionColorMap[mission];
        }
        const usedTitle = props.width < 1200 ? styles.elem_title_small: styles.elem_title
        const usedElem = mobile ? styles.elem_mobile: styles.elem;
        const usedStyleDiv = mobile ? styles.styleDiv_mobile: styles.styleDiv;
        return(
            <div key={mission+dString} className={usedElem} onClick={() => {
                setLoading(true)
                const viewparams = buildQueryViewParamsCal(100, calRangeType.day, dString);

                var facets: string = '';
                var key; var value;
                if(facetSelected !== undefined){
                    for(var elem of facetSelected){
                        key = elem.split('@')[1];
                        value = elem.split('@')[0];
                        if(key !== 'mission_ss')
                            facets += '&fq=' + key + ':' + value
                    }
                }
                var data;
                var strs = "";
                if(freeTextSearch != ""){
                    for(var elem of freeTextSearch.split(" ")){
                        strs += elem + "* "
                    }
                    data = fetchResults(CONSTANTS.ENDPOINT.REST_API + CONSTANTS.REST_DO_QUERY + "?" + viewparams.slice(0,-1) + "&fq=mission_ss:" + mission + facets + "&q=_text_:" + strs)
                }else{
                    data = fetchResults(CONSTANTS.ENDPOINT.REST_API + CONSTANTS.REST_DO_QUERY + "?" + viewparams.slice(0,-1) + "&fq=mission_ss:" + mission + facets + "&q=*:*" )
                }
            
                var elements: any = []
                var res: any = data.then(response => {
                    res = response
                    
                    var docs;
                    if(res['response'] !== undefined){
                        docs = res['response']['docs'];
                        for(let elem of docs){
                            elements.push(IDs(elem['title'], elem))
                        }
                    }
                    setElemDetails(elements)
                    setModalDocs(res['response']['numFound'])
                    handleShow();
                    setLoading(false)
                }).catch(function(error){
                    const toastProperties: toastListProp = createToastProperties(toastType.error, 'error occurred while retrieving data', error.message);
                    dispatch(addToQueue(toastProperties));
                    setLoading(false)
                })
            }}>
                <StyledDiv className={usedStyleDiv} color={aColor}/>
                <div className={usedTitle}>
                    {mission} +{number}
                </div>
            </div>
        );  
    }

    //Days functional component (render day)
    const Days = (day: Date, month: number) => {

        const elements: any = [];
        const more: any = [];
        var disabled = false;

        const dString = format(day!, 'yyyy-MM-dd');

        if(day.getMonth() === month){
            var num = 0
            for(let elem in heatmap){
                if(elem.split('T')[0] === dString){
                    for(let inner in heatmap[elem]){
                        if(num >= maxElements){
                            more.push(Group(inner, parseInt(heatmap[elem][inner]), dString))
                        }else{
                            elements.push(Group(inner, parseInt(heatmap[elem][inner]), dString))
                        }
                        num++;
                    }
                }
            }
        }else{
            disabled = true
        }

        //render day
        return(
            <div key={day.getDate()+day.getDay()}>
                {disabled ? 
                <div key={day.getDate()} className={styles.day_disabled}>
                    {day?.getDate()}
                    <div className={styles.elem_container}>
                        <span></span>
                    </div>
                </div>
                :
                <div key={day.getDate()} className={styles.day}>
                    {day?.getDate()}
                    <div className={styles.elem_container}>
                        {elements}
                        {more.length > 0 ? 
                        <div className={styles.button} onClick={() => {
                            elements.push(more)
                            setElemDetails(elements)
                            handleShow();
                        }}>
                            +{more.length} more
                        </div>
                        :
                        <div></div>}
                    </div>
                </div>
                }
            </div>
        );
    }

    //push day components in week day array
    dates.forEach(element => {
        switch(element.getDay()){
            case 0: d.push(Days(element, month)); break;
            case 1: l.push(Days(element, month)); break;
            case 2: mar.push(Days(element, month)); break;
            case 3: mer.push(Days(element, month)); break;
            case 4: g.push(Days(element, month)); break;
            case 5: v.push(Days(element, month)); break;
            case 6: s.push(Days(element, month)); break;
        }
    });
    
    const week_daysNoMobile = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const week_daysMobile = ['M', 'T', 'W', "T", 'F', 'S', 'S'];

    const week_used = mobile ? week_daysMobile: week_daysNoMobile;

    //render MonthView
    return(
        <div className={styles.box}>
            {loading ? 
            <OverlayComp/>
            :    
            <div className={styles.container}>
                <div className={styles.day_container}>
                    <div className={styles.pivot}>
                        {week_used[0]}
                    </div>
                    {l}
                </div>
                <div className={styles.day_container}>
                    <div className={styles.pivot}>
                        {week_used[1]}
                    </div>
                    {mar}
                </div>
                <div className={styles.day_container}>
                    <div className={styles.pivot}>
                        {week_used[2]}
                    </div>
                    {mer}
                </div>
                <div className={styles.day_container}>
                    <div className={styles.pivot}>
                        {week_used[3]}
                    </div>
                    {g}
                </div>
                <div className={styles.day_container}>
                    <div className={styles.pivot}>
                        {week_used[4]}
                    </div>
                    {v}
                </div>
                <div className={styles.day_container}>
                    <div className={styles.pivot}>
                        {week_used[5]}
                    </div>
                    {s}
                </div>
                <div className={styles.day_container}>
                    <div className={styles.pivot}>
                        {week_used[6]}
                    </div>
                    {d}
                </div>
                <Modal size="lg" centered={true} animation={false} className={styles.modal} show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Documents Found: {modalDocs}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {elemDetails}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="outline" onClick={handleClose}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal> 
            </div>
            }  
        </div>
    );
}

export default MonthViewComp;