
import { viewType, sortFieldType, sortType, calRangeType } from '../../services/View_Slice';
import { format, addMonths, addYears, parseISO, subSeconds } from 'date-fns';
import CONSTANTS from "../../constants";
import logger from '../../loggers/logger';
var urlencode = require('urlencode');

//const dispatch = useDispatch();

export function buildQueryViewParams(
        aViewType: viewType,
        cursorStart: number,
        cursorRows: number,
        listSortedFeld: sortFieldType,
        listSortedOrder: sortType,
        calRangeUsed: calRangeType | undefined,
        calRangeValue: string
        ): string{

    logger.debug("queryhelper buildQueryViewParams calRangeValue="+ calRangeValue + "; calRangeUsed=" + calRangeUsed );
    if(cursorRows===undefined){
        throw new Error("buildQueryViewParams: cursorRows is undefined");
    }

    // add cursor start + rows
    // add sortField + order
    // will be like: &start=41&rows=5&sortBy=file_modified_dt&sortOrder=0"
    var result: string;
    if(aViewType === viewType.Calendar){
        if(calRangeUsed === calRangeType.week){
            cursorRows = CONSTANTS.UI.CALENDAR_ITEM_PER_WEEK;
        }
        result = "rows=" + cursorRows + "&";
    }else{
        result = "start="+cursorStart+"&rows="+cursorRows+"&sortBy="+listSortedFeld+"&sortOrder="+listSortedOrder + "&";
    }

    // add date 
    // will be like: file_modified_dt:["2020-10-01T00:00:00Z" TO "2020-10-31T00:00:00Z"]
    //const rangeSelected = calRangeUsed !== undefined ? calRangeType[calRangeUsed] : calRangeType.month;
    var adateStart: Date | undefined = undefined;
    var adateStop: Date | undefined = undefined;
    //var aWeek: number = 0;
    var dateStringStart = "";
    var dateStringStop = "";

    if(calRangeUsed !== undefined){
        switch(calRangeUsed){
            case calRangeType.month: // input 
                adateStart = parseISO(String(calRangeValue+"-01"));
                adateStop = addMonths(adateStart, 1);
                adateStop = subSeconds(adateStop, 1)
                logger.debug("queryhelper buildQueryViewParams month: adateStart="+ adateStart + "; adateStop=" + adateStop );
                dateStringStart = String(calRangeValue+"-01T00:00:00Z");
                dateStringStop = format(adateStop, 'yyyy-MM-dd') + 'T23:59:59Z';
                break;
            case calRangeType.year:
                adateStart = parseISO(String(calRangeValue+"-01-01"));
                adateStop = addYears(adateStart, 1);
                adateStop = subSeconds(adateStop, 1)
                logger.debug("queryhelper buildQueryViewParams year: adateStart="+ adateStart + "; adateStop=" + adateStop );
                dateStringStart = String(calRangeValue+"-01-01T00:00:00Z");
                dateStringStop = format(adateStop, 'yyyy-MM-dd') + 'T23:59:59Z';
                break;
            case calRangeType.week:
                adateStart = parseISO(String(calRangeValue));
                adateStop = new Date(adateStart.setDate(adateStart.getDate() + 6));
                logger.debug("queryhelper buildQueryViewParams week: adateStart="+ adateStart + "; adateStop=" + adateStop );
                dateStringStart = String(calRangeValue+"T00:00:00Z");
                dateStringStop = format(adateStop, 'yyyy-MM-dd') + 'T23:59:59Z';
                break;
            case calRangeType.day:
                adateStart = parseISO(String(calRangeValue));
                adateStop = adateStart;
                logger.debug("queryhelper buildQueryViewParams week: adateStart="+ adateStart + "; adateStop=" + adateStop );
                dateStringStart = String(calRangeValue+"T00:00:00Z");
                dateStringStop = format(adateStop, 'yyyy-MM-dd') + 'T23:59:59Z';
                break; 
            default:
                logger.error("@@@@@@@@@ buildQueryViewParams: unhandled calRangeUsed:" + calRangeUsed);
        }
    }else{
        logger.error("@@@@@@@@@ buildQueryViewParams: calRangeUsed undefined");
    }

    logger.debug("@@@@@@@@@ buildQueryViewParams: dateStringStart='" + dateStringStart + "'; dateStringStop='" + dateStringStop + "'");
    //aWeek = adateStart !== undefined ? getISOWeek(adateStart) : 0;

    result += "fq=" + urlencode(CONSTANTS.CRITERIA.DATERANGE + ':["' + dateStringStart + '" TO "' + dateStringStop + '"]') + "&";
    logger.debug("@@@@@@@@@ buildQueryViewParams: result='" + result + "'");

    return result;
}

export function buildQueryViewParamsCal(
    rows: number,
    calRangeUsed: calRangeType | undefined,
    calRangeValue: string
    ): string {
        
    var result = 'rows=' + rows + '&';

        // add date 
    // will be like: file_modified_dt:["2020-10-01T00:00:00Z" TO "2020-10-31T00:00:00Z"]
    //const rangeSelected = calRangeUsed !== undefined ? calRangeType[calRangeUsed] : calRangeType.month;
    var adateStart: Date | undefined = undefined;
    var adateStop: Date | undefined = undefined;
    //var aWeek: number = 0;
    var dateStringStart = "";
    var dateStringStop = "";

    if(calRangeUsed !== undefined){
        switch(calRangeUsed){
            case calRangeType.month: // input 
                adateStart = parseISO(String(calRangeValue+"-01"));
                adateStop = addMonths(adateStart, 1);
                adateStop = subSeconds(adateStop, 1)
                logger.debug("queryhelper buildQueryViewParams month: adateStart="+ adateStart + "; adateStop=" + adateStop );
                dateStringStart = String(calRangeValue+"-01T00:00:00Z");
                dateStringStop = format(adateStop, 'yyyy-MM-dd') + 'T23:59:59Z';
                break;
            case calRangeType.year:
                adateStart = parseISO(String(calRangeValue+"-01-01"));
                adateStop = addYears(adateStart, 1);
                adateStop = subSeconds(adateStop, 1)
                logger.debug("queryhelper buildQueryViewParams year: adateStart="+ adateStart + "; adateStop=" + adateStop );
                dateStringStart = String(calRangeValue+"-01-01T00:00:00Z");
                dateStringStop = format(adateStop, 'yyyy-MM-dd') + 'T23:59:59Z';
                break;
            case calRangeType.week:
                adateStart = parseISO(String(calRangeValue));
                adateStop = new Date(adateStart.setDate(adateStart.getDate() + 6));
                logger.debug("queryhelper buildQueryViewParams week: adateStart="+ adateStart + "; adateStop=" + adateStop );
                dateStringStart = String(calRangeValue+"T00:00:00Z");
                dateStringStop = format(adateStop, 'yyyy-MM-dd') + 'T23:59:59Z';
                break;
            case calRangeType.day:
                adateStart = parseISO(String(calRangeValue));
                adateStop = adateStart;
                logger.debug("queryhelper buildQueryViewParams week: adateStart="+ adateStart + "; adateStop=" + adateStop );
                dateStringStart = String(calRangeValue+"T00:00:00Z");
                dateStringStop = format(adateStop, 'yyyy-MM-dd') + 'T23:59:59Z';
                break; 
            default:
                logger.error("@@@@@@@@@ buildQueryViewParams: unhandled calRangeUsed:" + calRangeUsed);
        }
    }else{
        logger.error("@@@@@@@@@ buildQueryViewParams: calRangeUsed undefined");
    }

    result += "fq=" + urlencode(CONSTANTS.CRITERIA.DATERANGE + ':["' + dateStringStart + '" TO "' + dateStringStop + '"]') + "&";

    return result;
}
