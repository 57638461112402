import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'services/rootReducer';
//import { Container, Popover, Button, OverlayTrigger } from 'react-bootstrap';
//import { isTemplateSpan } from 'typescript';
//import {Dictionary} from '../../utils';
import styles from "./SearchHints.module.css";

type propsTypes = {
  results: Array<string|number>,
  callback?: (crit: string) => void
}


const SearchHints = (props: propsTypes ) => {

  const { results } = props;
  const {loading} = useSelector((state: RootState) => state.Loading);

  console.log("SearchHints props.results:"+JSON.stringify(results));
  var n: number = 0;
  const options = results.map(item => {
    n+=1;
    return(
      n%2===1 && !loading ? 
      <div key={item} className={styles.item} onMouseDown={(e) => {
        if(props.callback !== undefined){
          console.log("SearchHints use callBack with:"+item);
          props.callback(""+item);
        }
        }}>
        {item}
      </div>
      :
      null
    )
  })

  console.log("SearchHints returns size:"+options.length);
  return <div>{options}</div>
}

export default SearchHints
