//import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import React, { Component } from 'react';
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import CONSTANTS from "../constants";
import logger from 'logger';
import styles from "./css/GradientBar.module.css";

interface Dictionary<T> {
    [Key: string]: T;
}


const StyledDiv = styled.div<{width: string}>`
    height: 14px!important;
    min-height: 14px!important;
    max-height: 14px!important;
    padding-top: 0px;
    padding-bottom: 0px;

    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 1px;
    margin-right: 0px;

    background-color: ${props => props.color};
    width: ${props => props.width};
`;


type propsType = {
    mission: string;
    barData: any;
    colorMap : any;
}



export default class TestBar extends Component<propsType> {
    barData: any;

    /*constructor(props: propsType) {
        super(props);
    }*/


    render() {

        logger.debug("@@@@ TestBar render: mission " + this.props.mission 
        + " barData=" + JSON.stringify(this.props.barData) 
        + " colorMap=" + JSON.stringify(this.props.colorMap));

        logger.info("**** barData=" + JSON.stringify(this.props.barData) 
        + " colorMap=" + JSON.stringify(this.props.colorMap));

        var theWidth: Array<string> = [];

        var theTotalMap: Dictionary<number> = {};
        var theWidth2: Dictionary<string> = {};
        var total: number = 0;
        // for( var docType in this.props.barData[this.props.mission]){
        for( var docType in this.props.barData){   
        // total+=this.props.barData[this.props.mission][docType]['count'];
            total+=this.props.barData[docType]['count'];
        }
        logger.debug("@@@@ TestBar: mission " + this.props.mission + " total=" + total);

        // for( var aDocType in this.props.barData[this.props.mission]){
        for( var aDocType in this.props.barData){
            // const aWidth: string = "" + (Number(this.props.barData[this.props.mission][aDocType]['count']) / total) * 100 + "%";
            const aWidth: string = "" + (Number(this.props.barData[aDocType]['count']) / total) * 100 + "%";
            theWidth.push(aWidth);
            //theWidth2[docType] = (aWidth/total) * 100;
            theWidth2[aDocType] = aWidth;
            // theTotalMap[aDocType] = this.props.barData[this.props.mission][aDocType]['count'];
            theTotalMap[aDocType] = this.props.barData[aDocType]['count'];
        }
        logger.debug("@@@@ TestBar: mission " + this.props.mission + " segment % width=" + theWidth);

        /*{<StyledDiv width={theWidth2[docType]} color={this.props.colorMap[this.props.mission] !== undefined ? this.props.colorMap[this.props.mission][docType] : '#000000' }/>[{docType } {theWidth2[docType]} %] }*/


        return (
            <div className={styles.container}>
                <div className={styles.barTotal}>{this.props.mission}: {total}</div>
                {Object.keys(this.props.barData).map(aDocType2 =>{
                    return(
                        <StyledDiv
                                key={aDocType2}
                                data-for={aDocType2}
                                data-tip
                                width={theWidth2[aDocType2]} color={ (this.props.colorMap[this.props.mission] !== undefined && this.props.colorMap[this.props.mission][aDocType2] !== undefined && this.props.colorMap[this.props.mission][aDocType2] !== '') ? this.props.colorMap[this.props.mission][aDocType2] : '#000000' }>
                            <ReactTooltip className={styles.glToolTip} id={aDocType2} place="bottom" effect="solid" textColor='#000000' backgroundColor={CONSTANTS.UI.TOOLTIP_COLOR}>
                                {aDocType2}<br/>
                                Tot Num: {theTotalMap[aDocType2]}
                            </ReactTooltip>
                        </StyledDiv>
                    )
                })}
            </div>
        )
    }

}
