import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
//import { Container, Card } from 'react-bootstrap';
import { List, FlexboxGrid } from "rsuite";
import styled from "styled-components";
import { RootState } from "../services/rootReducer";
//import { setResultPagination } from "../services/Query_Slice";

import styles from "./css/ResultListItem.module.css";
//import {paginationStateType} from "./Pagination";
//import PaginationAdvanced from "./Pagination";
import CONSTANTS from "../constants";
//import DocumentPopup from "./DocumentPopup";
//import useWindowDimensions from "./utils/useWindowDimensions";
import { format, parseISO } from 'date-fns';
//import useResizeObserver from './useResizeObserver';
import { addUrl, removeUrl } from 'services/Download_Slice';


const StyledDiv = styled.div`
    background-color: ${props => props.color};
`;

const ResultListItem: any = ((props: any) =>{

    const item = props.doc;

    const { gradientColorMap, missionColorMap } = useSelector((state: RootState) => state.View);
    const dispatch = useDispatch();
    //const { mobile, uiDimensions } = useSelector((state: RootState) => state.View);

    // checkbox checked state
    const [checked, setChecked] = useState<boolean>(props.checked)

    // if the property checked from the parent changes, change the value of the state checked
    useEffect(() => {
      console.log('prop[checked]: ' + props.checked + ' -- state[checked]: ' + checked)
      setChecked(props.checked)
    }, [props.checked])

    //var containerStyle;
    var styleDivStyle;
    var styleCenterStyle;
    var stylePropertyStyle;
    var titleStyle;
    var slimTextStyle;
    var propertyStyle;

    if(props.mobile){
      //containerStyle = styles.container_mobile
      styleDivStyle = styles.styleDiv_mobile
      styleCenterStyle = styles.styleCenter_mobile
      stylePropertyStyle = styles.styleCenterProperty_mobile
      titleStyle = styles.titleStyle_mobile
      slimTextStyle = styles.slimText_mobile
      propertyStyle = styles.propertyStyle_mobile
    }else if(!props.mobile && props.width >= 1600){
      //containerStyle = styles.container
      styleDivStyle = styles.styleDiv
      styleCenterStyle = styles.styleCenter
      stylePropertyStyle = styles.styleCenterProperty
      titleStyle = styles.titleStyle
      slimTextStyle = styles.slimText
      propertyStyle = styles.propertyStyle
    }else if(!props.mobile && props.width < 1600){
      //containerStyle = styles.container
      styleDivStyle = styles.styleDiv
      styleCenterStyle = styles.styleCenter
      stylePropertyStyle = styles.styleCenterProperty_desktop_small
      titleStyle = styles.titleStyle_desktop_small
      slimTextStyle = styles.slimText_desktop_small
      propertyStyle = styles.propertyStyle_desktop_small
    }

    // get doc color
    const mission: string = item[CONSTANTS.UI.TOP_CAT];
    var aColor: string = '#000000';
    if(missionColorMap !== undefined && missionColorMap[mission] !== undefined && missionColorMap[mission] !== undefined){
      aColor = missionColorMap[mission];
    }

    var item_date = parseISO(item[CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE5[0]].split('T')[0])

    // handle the changes on the checkbox
    const handleChange = (event: { target: { checked: any; }; }) => {
      if(event.target.checked) {
        setChecked(true)
        // add url to the store
        dispatch(addUrl(item[CONSTANTS.RESULT.FIELD_URL]));
      }else{
        setChecked(false)
        // remove url from the store
        dispatch(removeUrl(item[CONSTANTS.RESULT.FIELD_URL]));
      }
    };


    return(

      <List.Item className={styles.list_item} key={props.id} index={props.index}>

        <a target="_blank" className={styles.link} href={item[CONSTANTS.RESULT.FIELD_URL]} rel="noopener noreferrer">

        <FlexboxGrid align="middle">

          {/* <input type="checkbox" checked={checked} id={props.id + 666} onChange={handleChange}></input> */}

          <FlexboxGrid.Item colspan={2} className={styleCenterStyle}>
            <StyledDiv className={styleDivStyle} color={aColor}/>
          </FlexboxGrid.Item>


          {/**/}
          <FlexboxGrid.Item
              colspan={8}
              className={stylePropertyStyle}
              >

              <div className={titleStyle}>{CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE0[1]}{item[CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE0[0]]}</div>
              {/* <div className={slimTextStyle}>{CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE1[1]}{item[CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE1[0]]}</div> */}
              
          </FlexboxGrid.Item>


          
          <FlexboxGrid.Item
              colspan={4}
              className={stylePropertyStyle}
              >

              <div className={propertyStyle}>{CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE2[1]}{item[CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE2[0]]}</div>
              <div className={propertyStyle}>{CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE3[1]}{item[CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE3[0]]}</div>

          </FlexboxGrid.Item>


          {/**/}
          <FlexboxGrid.Item
              colspan={4}
              className={stylePropertyStyle}
              >

              <div className={propertyStyle}>{CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE4[1]}{item[CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE4[0]]}</div>
              <div className={propertyStyle}>{CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE5[1]}{format(item_date, CONSTANTS.UI.DATE_FORMAT_DESIRED)}</div>

          </FlexboxGrid.Item>

          {/*info*/}
          <FlexboxGrid.Item
              colspan={4}
              className={stylePropertyStyle}
              >
              
              {item[CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE6[0]] != "" ?
                <div className={propertyStyle}>{CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE6[1]}{item[CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE6[0]]}</div>
                :
                <div className={propertyStyle}>{CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE6[1]}N/A</div>
              }
              {item[CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE7[0]] != "" ?
                <div className={propertyStyle}>{CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE7[1]}{item[CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE7[0]]}</div>
                :
                <div className={propertyStyle}>{CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE7[1]}N/A</div>
              }
              {item[CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE8[0]] != "" ?
                <div className={propertyStyle}>{CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE8[1]}{item[CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE8[0]]}</div>
                :
                <div className={propertyStyle}>{CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE8[1]}N/A</div>
              }
          </FlexboxGrid.Item>

        </FlexboxGrid>

        </a>

      </List.Item>
    
    )

});


export default ResultListItem
