//import { info } from 'console';
import winston from 'winston';
import debugFormat from 'winston-format-debug';


// OK:
/*const logger = winston.createLogger({
    transports: [
        new winston.transports.Console({}),
    ]
});*/

// OK:

const logger = winston.createLogger({
    level: 'info',
    transports: [
        new winston.transports.Console({
            format: winston.format.combine(
                winston.format.colorize({ message: true }),
                debugFormat({
                    colorizeMessage: false, // Already colored by `winston.format.colorize`.
                })
            )
        }),
    ]
});


export default logger