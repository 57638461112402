import React, { useState, useRef, useEffect} from 'react';
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux';
import { Container, Popover, Overlay, Button, Form } from 'react-bootstrap';
import useResizeObserver from "use-resize-observer";

import { OutlinedInput, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CONSTANTS from "../../constants";

import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import axios from 'axios'
import styled from "styled-components";
import styles from "./Search.module.css";
import SearchHints from './SearchHints';
import { RootState } from 'services/rootReducer';
import { setOperator }  from "../../services/Search_Slice"

import useOutsideAlerter from "../outsideClick"


const debug = false;

type searchTypes = {
  error: boolean,
  query: string,
  hintQuery: string,
  results: Array<string | number>,
}


const initialState = {
  error: false,
  query: '',
  hintQuery: '',
  results: []
}


/*
const StyledDiv = styled.div`
  background-color: #ff0000;
  opacity: 1;
  width: ${(props: { width: any; }) => props.width};
`;
*/

const StyledDiv = styled.div`
  opacity: 1;
  width: ${(props: { width: any; }) => props.width};
`;


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));
const useOutlinedInputStyles = makeStyles(theme => ({
  root: {
    padding: '0px !important',
    margin: '0px !important',

    backgroundColor: '#f1f3f4',
    "& $notchedOutline": {
      borderColor: "#d1d3d4"
    },
    "&:hover $notchedOutline": {
      borderColor: "#b1b3b4"
    },
    "&$focused $notchedOutline": {
      borderColor: "#b1b3b4"
    }
  },
  focused: {},
  notchedOutline: {}
}));





// the passed Test properties
type myPropsType = {
  callback?: (crit: string) => void,
  popupColor?: string,
  placeHolder?: string,
  value?: string,
  width?: number,
}

function Search(aProps: React.PropsWithChildren<myPropsType>) {

  const dispatch = useDispatch();

  const { mobile } = useSelector((state: RootState) => state.View);
  const { loading } = useSelector((state: RootState) => state.Loading);
  const { operator } = useSelector((state: RootState) => state.Search);

  //const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();

  // use props
  const popupBackColor = aProps.popupColor;
  const usedPlaceHolder = aProps.placeHolder !== undefined ? aProps.placeHolder : "Search for...";

  var isActive = mobile ? false : true;
  const [active, setIsActive] = useState(isActive);
  const [show, setShow] = useState(false);
  //const [operator, setLocalOperator] = useState('Or');

  const target = useRef<HTMLDivElement>(null);

  const wrapRef = useRef(null);
  const otherRef = useRef(null);
  const targetRef = useRef(null);
  const clicked = useOutsideAlerter(wrapRef, otherRef, targetRef).result;

  // may be empty
  const initialPropValue: string = aProps.value !== undefined ? aProps.value : "";
  //var initialUsed = true; // 
  const [initialUsed, setInitialUsed] = useState(true);
  if (debug) {
    console.log('@@ Search: initialPropValue=' + initialPropValue)
  }

  const [error, setError] = useState(false);
  const [query, setQuery] = useState(initialPropValue);
  const [hintQuery, setHintQuery] = useState(initialPropValue);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (debug) {
      console.log("@@ Search: set initialPropValue '" + initialPropValue + "' in state")
    }
    setQuery(initialPropValue);
    setHintQuery(initialPropValue);
    if (initialPropValue.length > 0) {
      getInfo(initialPropValue);
    }
    // qui c'era il getGradientMap
  }, [initialPropValue]);

  useEffect(() => {
    if (mobile) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, [mobile]);

  if (mobile && clicked && active) {
    setIsActive(!active);
  }

  const { width } = useResizeObserver<HTMLDivElement>({
    ref: target,
  });
  if (debug) {
    console.log("@@ Search: useResizeObserver width:" + width);
  }

  var aWidth = target.current ? target.current.offsetWidth + 'px' : width + 'px';
  const [widthString, setWidthString] = useState(aWidth);
  useEffect(() => {
    setWidthString(target.current ? target.current.offsetWidth + 'px' : width + 'px');
  }, [active, mobile, aWidth])

  useEffect(() => {
    if (loading) {
      setShow(false)
    }
  }, [loading])
  // set search and overlay to identical width
  if (debug) {
    console.log("@@ Search: useResizeObserver widthString:" + widthString);
  }

  // style of the search container
  const widthContainerCss = {
    backgroundColor: '#ffffff',
    position: 'relative',
    padding: '0px !important',
    margin: '0px !important',
    width: '100%',
    maxWidth: 9999,
  } as React.CSSProperties;

  const widthCss = {
    width: '100%',
    padding: '0px !important',
    margin: '0px !important',
  } as React.CSSProperties;


  // retrieve hint info
  function getInfo(criteria: string) {
    //const geHintUrl:string = "http://localhost:3101/api/real/terms" + "?limit=10&term=" + criteria;
    const geHintUrl: string = CONSTANTS.ENDPOINT.REST_API + CONSTANTS.REST_DO_TERMS_QUERY + "?limit=10&term=" + criteria;
    console.log("@@ Search: getInfo url:" + geHintUrl);
    axios.get(geHintUrl)
      .then(({ data }) => {
        console.log("@@ Search: getInfo returned data:" + JSON.stringify(data));
        setResults(data.data)
      })
      .catch(() => {
        console.error("@@ Search: getInfo error");
        setError(true);
      })
  }

  // hint select callback
  function hintSelected(choice: string) {
    console.log("@@ Search: hint selected:" + choice);
    setQuery(choice);
    setHintQuery(choice);

    if (aProps.callback !== undefined) {
      console.log('@@ Search: hintSelected; use callback using:' + choice);
      aProps.callback(choice);
    } else {
      console.log('@@ Search: hintSelected; use callback using:' + choice);
    }
  }

  const hideResults = Object.keys(results).length === 0;
  const hideCancel = hintQuery.length === 0;
  if (debug) {
    console.log('@@ Search: widthContainerCss:' + JSON.stringify(widthContainerCss));
    //console.log('Search: dimension:'+ dimension)
  }

  var valueToDisplay = hintQuery === '' ? initialUsed && initialPropValue !== undefined ? initialPropValue : '' : hintQuery;
  console.log('@@ Search: valueToDisplay:' + valueToDisplay)

  const usedContainer = mobile ? styles.container_mobile : styles.container;

  return (
    <div>
      {loading ?
        null
        :
        active ?
          <Container ref={wrapRef} className={usedContainer}>
            {debug ?
              <div>
                <div>widthString: {widthString}</div>
                <div>initialPropValue: {initialPropValue}</div>
                <div>initialUsed: {initialUsed === true ? 'Yes' : 'No'}</div>
                <div>hintQuery: {JSON.stringify(hintQuery)}</div>
                <div>query: {JSON.stringify(query)}</div>
                <div>results: {JSON.stringify(results)}</div>
                <div>error: {error ? 'Yes' : 'No'}</div>
                <div>operator: {operator}</div>
              </div> : null
            }

            <OutlinedInput ref={target} style={widthCss}
              classes={outlinedInputClasses}
              placeholder={usedPlaceHolder}
              value={valueToDisplay}

              onFocus={() => {
                setShow(true)
              }
              }
              onBlur={() => {
                setShow(false)
              }
              }

              endAdornment={
                hideCancel ?
                  null :
                  <InputAdornment position="start">
                    <Button variant="light" onClick={(e) => {
                      console.log('Search: clear button onClick');
                      setError(false);
                      setQuery('');
                      setHintQuery('');
                      setResults([]);
                      if (aProps.callback !== undefined) {
                        console.log("Search: cancel button onClick; use callback using:''");
                        setQuery('');
                        aProps.callback('');
                      } else {
                        console.log('Search: cancel button onClick; but no callback defined. Selection=' + hintQuery);
                      }
                    }}
                    >
                      <CancelIcon />
                    </Button>
                  </InputAdornment>
              }

              startAdornment={
                <div>
                  <InputAdornment position="end">
                    {/* <div className={styles.operator}>
                      <Form.Check type="radio" label="Or" id="op-or" checked={operator==='Or'} className={styles.opLabel}
                        onClick={(e) => {
                          console.log(" @@@@@ set operator to Or");
                          //setLocalOperator('Or');
                          dispatch(setOperator('Or'));
                        }} />
                      <Form.Check type="radio" label="And" id="op-and" checked={operator==='And'} className={styles.opLabel}
                        onClick={(e) => {
                          console.log(" @@@@@ set operator to And");
                          //setLocalOperator('And')
                          dispatch(setOperator('And'));
                        }} />
                    </div> */}
                    <Button variant="light" className={styles.button} onClick={(e) => {
                      if (aProps.callback !== undefined) {
                        console.log('Search: query button onClick; use callback using:' + hintQuery);
                        setQuery(hintQuery);
                        aProps.callback(hintQuery);
                      } else {
                        console.log('Search: query button onClick; but no callback defined. Selection=' + hintQuery);
                      }
                    }}
                    >
                      <SearchIcon />
                    </Button>
                  </InputAdornment>
                </div>
              }

              onKeyDown={(event: any) => {
                console.log('#### onKeyDown');
                setInitialUsed(false);
                if (event.key === 'Enter') {
                  console.log('Search: enter typed; do query using:' + event.target.value);
                  setQuery(event.target.value);
                  if (aProps.callback !== undefined) {
                    console.log('Search: enter typed; use callback using:' + hintQuery);
                    aProps.callback(event.target.value);
                  } else {
                    console.log('Search: enter typed; use callback using:' + hintQuery);
                  }
                }
              }}

              onChange={(event: any) => {
                console.log("#### search change: " + event.target.value);
                //console.dir(event);

                setHintQuery(event.target.value);

                if (event.target.value.length > 1) {
                  // get and show hints
                  getInfo(event.target.value);
                } {
                  // remove and hide hints
                  setError(false);
                  setQuery('');
                  //setHintQuery('');
                  setResults([]);
                }

              }}

            />



            <Overlay target={target.current} show={show} placement="bottom-start">
              {({ placement, arrowProps, show: _show, popper, ...props }) => (
                <StyledDiv
                  width={widthString}
                  {...props}
                  style={{
                    backgroundColor: popupBackColor,
                    padding: '0px !important',
                    margin: '0px !important',
                    color: 'white',
                    borderRadius: 3,
                    opacity: 0.9,
                    ...props.style,
                  }}
                >
                  {hideResults === false ?
                    <Popover.Content className={styles.popover}>
                      <div ref={targetRef}>
                        <SearchHints results={results} callback={hintSelected} />
                      </div>
                    </Popover.Content>
                    : null
                  }
                </StyledDiv>
              )}
            </Overlay>
          </Container>
          :
          null
      }
      {mobile ?
        <Button ref={otherRef} className={styles.mobile_button} onClick={() => {
          setIsActive(!active);
        }}>
          <img src="/qrast/images/search-icon.png" alt="search on/off" className={styles.glIcon} />
        </Button>
        :
        null
      }
    </div>
  );
}

export default Search
