import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "./store";
import { setAllStates } from "./Loading_Slice";
import { buildColorQueryString } from "../utils/solr"
import { format, startOfMonth } from 'date-fns';
import logger from "../logger"
import CONSTANTS from "../constants";
import { toastListProp, toastType, createToastProperties } from '../components/toast/Toast';
import { addToQueue } from "../services/Toasts_Slice";
//
const urldecode = require('urldecode');
var urlencode = require('urlencode');



interface Dictionary<T> {
    [Key: string]: T;
}

export enum viewType{
    List = 0,
    Calendar = 1
}

export enum calRangeType{
    month = 0,
    week = 1,
    day = 2,
    year = 3,
}

export enum sortType{
    descending = 0,
    ascending = 1,
}

export enum sortFieldType{
    'date' = 'file_modified_dt',
    'filename' = 'path_basename_s',
}

export enum reverseSortFieldType{
    'file_modified_dt' = 'date',
    'path_basename_s' = 'filename',
}

export type cursorsType = {
    cursorStart: number;
    cursorRows: number;
}

export type calValueType = {
    calRange: string | undefined;
}

export type uiDimensionsType = {
    width: number;
    height: number;
}

type viewchState = {
    total: number;
    //
    viewType: viewType;
    // for callendar:
    calRangeUsed : calRangeType | undefined;
    calRangeValue: string;
    // for list:
    cursorStart: number;
    cursorRows: number;
    listSortedFeld: sortFieldType;
    listSortedOrder : sortType;
    // for debug
    showDebugInfo: boolean;
    mobile: boolean;
    // for UI
    missionColorMap: Dictionary<string>;
    gradientColorMap: Dictionary<Dictionary<string>>;
    gradientValues: any;
    heatmap: Dictionary<Dictionary<string>>;
    pageRangeUsed: number;
    // for mobile layout
    uiDimensions: uiDimensionsType;
    // from URL query params
    // facet partams
    urlFacetsConstraints: Array<string>;
    // boolean flag: url param used once only
    urlConstraintsUsed: boolean;
}

var dateCurrentMonth = format(startOfMonth(new Date()), 'yyyy-MM');

let initialState: viewchState = {
    total: 0,
    viewType: viewType.Calendar,
    calRangeUsed: calRangeType.month,
    calRangeValue: dateCurrentMonth,
    cursorStart: 0,
    cursorRows: CONSTANTS.UI.ITEM_PER_PAGE_CHOICES[0],
    listSortedFeld: sortFieldType.date,
    listSortedOrder: sortType.descending,
    showDebugInfo: false,
    mobile: false,
    missionColorMap: {},
    gradientColorMap: {},
    gradientValues: {},
    heatmap: {},
    pageRangeUsed: CONSTANTS.UI.ITEM_PER_PAGE_CHOICES[0],
    uiDimensions: {width: -1, height: -1},
    urlFacetsConstraints: [],
    urlConstraintsUsed: false
};


const viewSlice = createSlice({
    name: 'View',
    initialState: initialState,
    reducers: {
        setHeatMap(state, action: PayloadAction<Dictionary<Dictionary<string>>>) {
            state.heatmap=action.payload;
        },
        setGradientValues(state, action: PayloadAction<Dictionary<any>>) {
            state.gradientValues=action.payload;
        },
        setGradientColorMap(state, action: PayloadAction<Dictionary<Dictionary<string>>>) {
            state.gradientColorMap=action.payload;
        },
        setMissionColorMap(state, action: PayloadAction<Dictionary<string>>) {
            state.missionColorMap=action.payload;
        },
        setMobile(state, action: PayloadAction<boolean>) {
            state.mobile=action.payload;
        },
        setShowDebugInfo(state, action: PayloadAction<boolean>) {
            state.showDebugInfo=action.payload;
        },
        setTotal(state, action: PayloadAction<number>) {
            state.total=action.payload;
        },
        setType(state, action: PayloadAction<viewType>) {
            state.viewType=action.payload;
        },
        setCursors(state, action: PayloadAction<cursorsType>) {
            state.cursorStart=action.payload.cursorStart;
            state.cursorRows=action.payload.cursorRows;
        },
        setSortField(state, action: PayloadAction<sortFieldType>) {
            state.listSortedFeld=action.payload;
        },
        setSortOrder(state, action: PayloadAction<sortType>) {
            state.listSortedOrder=action.payload;
        },
        setCalRangeUsed(state, action: PayloadAction<calRangeType>) {
            state.calRangeUsed=action.payload;
        },
        setCalRangeValue(state, action: PayloadAction<string>) {
            state.calRangeValue=action.payload;
        },
        setPageRangeUsed(state, action: PayloadAction<number>) {
            state.pageRangeUsed=action.payload;
        },
        setUiDimensions(state, action: PayloadAction<uiDimensionsType>){
            state.uiDimensions=action.payload;
        },
        setUrlFacetConstraints(state, action: PayloadAction<Array<string>>){
            state.urlFacetsConstraints=action.payload;
        },
        setUrlConstraintsUsed(state, action: PayloadAction<boolean>){
            state.urlConstraintsUsed=action.payload;
        }
    }
});



//
// get the mission colormap
//
export const getMissionColorMap = (): AppThunk => async dispatch => {
    
    dispatch(setAllStates({loading:true, ok:false, error: ""}));

    //const query = "q=*:*&facet.pivot=mission_ss,colorhexcodemis&facet=true&rows=0&indent=true";
    const query = buildColorQueryString();

    logger.debug("getMissionColorMap encoded query:" + query);
    logger.debug("getMissionColorMap decoded query:" + urldecode(query));

    fetch(CONSTANTS.ENDPOINT.REST_API + CONSTANTS.REST_GET_MISSIONS_COLORMAP + "?" + query)
        .then(response => {
            //logger.debug("response 0: " + Response);
            if(response.ok){
                logger.debug("getMissionColorMap response 0: ok");
                return response.json();
            }else{
                logger.debug("getMissionColorMap response 0: not ok");
                throw new Error('Network response was not ok:' + response.status);
            }
        })
        .then(data => {
            if(CONSTANTS.BACKEND_REPLY_IS_ERROR in data){
                const toastProperties: toastListProp = createToastProperties(toastType.error, CONSTANTS.REST_GET_MISSIONS_COLORMAP_ALIAS, data[CONSTANTS.BACKEND_REPLY_IS_ERROR]);
                dispatch(addToQueue(toastProperties));
            }else{
                logger.debug("## getMissionColorMap data.payload: " + JSON.stringify(data));
                dispatch(setMissionColorMap(data['colorMap']));
            }
            dispatch(setAllStates({loading:false, ok:true, error: ""}));
        })
        .catch((error) => {
            logger.error("## getMissionColorMap: error="+error);
            dispatch(setAllStates({loading:false, ok:false, error: error.message}));
            const toastProperties: toastListProp = createToastProperties(toastType.error, CONSTANTS.REST_GET_MISSIONS_COLORMAP_ALIAS, error.message);
            dispatch(addToQueue(toastProperties));
        })
};


//
// get the document type colormap, used by the gradient component
//
export const getGradientColorMap = (): AppThunk => async dispatch => {
    
    dispatch(setAllStates({loading:true, ok:false, error: ""}));

    const query = "q=*:*&facet.pivot=mission_ss,shorttitle,colorhexcode&facet=true&rows=0";

    logger.debug("getGradientColorMap encoded query:" + query);
    logger.debug("getGradientColorMap decoded query:" + urldecode(query));

    fetch(CONSTANTS.ENDPOINT.REST_API + CONSTANTS.REST_GET_GRADIENT_COLORMAP + "?" + query)
        .then(response => {
            //logger.debug("response 0: " + Response);
            if(response.ok){
                logger.debug("getGradientColorMap response 0: ok");
                return response.json();
            }else{
                logger.debug("getGradientColorMap response 0: not ok");
                throw new Error('Network response was not ok:' + response.status);
            }
        })
        .then(data => {
            if ('ERROR' in data){
                logger.error("getGradientColorMap error: " + data['ERROR']);
                const toastProperties: toastListProp = createToastProperties(toastType.error, CONSTANTS.REST_GET_GRADIENT_COLORMAP_ALIAS, JSON.stringify(data['ERROR']));
                dispatch(addToQueue(toastProperties));
                dispatch(setGradientValues({}));
            }else{
                logger.debug("## getGradientColorMap data.payload: " + JSON.stringify(data));
                dispatch(setGradientColorMap(data['colorMap']));
            }
                dispatch(setAllStates({loading:false, ok:true, error: ""}));
        })
        .catch((error) => {
            logger.error("## getGradientColorMap: error="+error);
            dispatch(setAllStates({loading:false, ok:false, error: error.message}));
            const toastProperties: toastListProp = createToastProperties(toastType.error, CONSTANTS.REST_GET_GRADIENT_COLORMAP_ALIAS, error.message);
            dispatch(addToQueue(toastProperties));
        })
};


//
// get the gradient values
//
export const getGradientMap = (textSearch: any, facetselected: any, operator: string): AppThunk => async dispatch => {
    
    dispatch(setAllStates({loading:true, ok:false, error: ""}));

    logger.info("getGradientMap facetselected:" + JSON.stringify(facetselected));
    var query = ''; 

    if(textSearch !== ""){
        query += "q=" + textSearch;
    }

    if(facetselected.length > 0){
        query += '&selectedFacets=';
        for(var val of facetselected){
            query += val.split('@')[1] + ':' + urlencode(val.split('@')[0]) + ';';
            //query += val.split('@')[1] + ':' + val.split('@')[0]; # KEEP to test error handling
        }
    }

    //query += '&op=' + CONSTANTS.OPERATOR_TO_USE;
    query += '&op=' + operator.toUpperCase();


    logger.info("getGradientMap encoded query:" + query);
    logger.info("getGradientMap decoded query:" + urldecode(query));

    fetch(CONSTANTS.ENDPOINT.REST_API + CONSTANTS.REST_GET_GRADIENT_MAP + "?" + query.trim())
        .then(response => {
            if(response.ok){
                logger.info("getGradientMap response 0: ok");
                return response.json();
            }else{
                logger.info("getGradientMap response 0: not ok");
                throw new Error('Network response was not ok:' + response.status);
            }
        })
        .then(data => {
            if ('ERROR' in data){
                logger.error("## getGradientMap error: " + data['ERROR']);
                const toastProperties: toastListProp = createToastProperties(toastType.error, CONSTANTS.REST_GET_GRADIENT_MAP_ALIAS+" error", JSON.stringify(data['ERROR']));
                dispatch(addToQueue(toastProperties));
                dispatch(setGradientValues({}));
            }else{
                logger.debug("## getGradientMap data.payload: " + JSON.stringify(data));
                dispatch(setGradientValues(data['gradientValues']));
            }
            dispatch(setAllStates({loading:false, ok:true, error: ""}));
        })
        .catch((error) => {
            logger.error("## getGradientMap: error="+error);
            dispatch(setAllStates({loading:false, ok:false, error: error.message}));
            const toastProperties: toastListProp = createToastProperties(toastType.error, CONSTANTS.REST_GET_GRADIENT_MAP_ALIAS+" error", error.message);
            dispatch(addToQueue(toastProperties));
        })
};

//
//get heatmap
//
export const getHeatMap = (start: any, end: any, facetselected: any, textSearch: string, operator: string): AppThunk => async dispatch => {

    dispatch(setAllStates({loading:true, ok:false, error: ""}));

    logger.info("getHeatMap facetselected:" + JSON.stringify(facetselected));
    var query = ''; 
    query = 'start=' + start + '&end=' + end;
    if(facetselected.length > 0){
        query += '&facets='
        
        for(var i=0; i<facetselected.length; i++){
            if(i === 0)
                query += facetselected[i].split('@')[1] + ':' + urlencode(facetselected[i].split('@')[0]);
            else
                query += ',' + facetselected[i].split('@')[1] + ':' + urlencode(facetselected[i].split('@')[0]);
            //query += val.split('@')[1] + ':' + val.split('@')[0]; # KEEP to test error handling
        }
    }
    if(textSearch !== ""){
        query += '&text=' + textSearch;
    }
    //query += '&op=' + CONSTANTS.OPERATOR_TO_USE;
    query += '&op=' + operator.toUpperCase();

    logger.info("getHeatMap encoded query:" + query);
    logger.info("getHeatMap decoded query:" + urldecode(query));

    fetch(CONSTANTS.ENDPOINT.REST_API + CONSTANTS.REST_GET_HEATMAP + "?" + query.trim())
        .then(response => {
            if(response.ok){
                logger.info("getHeatMap response 0: ok");
                return response.json();
            }else{
                logger.info("getHeatMap response 0: not ok");
                throw new Error('Network response was not ok:' + response.status);
            }
        })
        .then(data => {
            if ('ERROR' in data){
                logger.error("## getHeatMap error: " + data['ERROR']);
                const toastProperties: toastListProp = createToastProperties(toastType.error, CONSTANTS.REST_GET_HEATMAP_ALIAS+" error", JSON.stringify(data['ERROR']));
                dispatch(addToQueue(toastProperties));
                dispatch(setHeatMap({}));
            }else{
                logger.debug("## getHeatMap data.payload: " + JSON.stringify(data));
                dispatch(setHeatMap(data));
            }
            dispatch(setAllStates({loading:false, ok:true, error: ""}));
        })
        .catch((error) => {
            logger.error("## getHeatMap: error="+error);
            dispatch(setAllStates({loading:false, ok:false, error: error.message}));
            const toastProperties: toastListProp = createToastProperties(toastType.error, CONSTANTS.REST_GET_GRADIENT_MAP_ALIAS+" error", error.message);
            dispatch(addToQueue(toastProperties));
        })
};

export const {setTotal, setType, setCursors, setSortField, setSortOrder, setCalRangeUsed, setCalRangeValue, setMobile, setShowDebugInfo, setMissionColorMap, setGradientColorMap, setGradientValues, setHeatMap, setPageRangeUsed, setUiDimensions, setUrlFacetConstraints, setUrlConstraintsUsed} = viewSlice.actions;

export default viewSlice.reducer
