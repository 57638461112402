import { combineReducers } from '@reduxjs/toolkit'

// the loading indicator
import Loading_Reducer from "./Loading_Slice";
// the search
import Search_Reducer from "./Search_Slice";
// the criterias
import Facets_Reducer from "./Facets_Slice";
// the query
import Query_Reducer from "./Query_Slice";
// the summary
import Summary_Reducer from "./Summary_Slice";
// the View
import View_Reducer from "./View_Slice";
// the toats
import Toasts_Reducer from "./Toasts_Slice";
// the download
import Download_Reducer from "./Download_Slice";

const rootReducer = combineReducers({
  Loading: Loading_Reducer,
  Search: Search_Reducer,
  Facets: Facets_Reducer,
  Query: Query_Reducer,
  Summary: Summary_Reducer,
  View: View_Reducer,
  Toasts: Toasts_Reducer,
  Download: Download_Reducer,
});

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
