import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "./store";
//import axios, { AxiosError } from "axios";
import { setAllStates } from "./Loading_Slice";
import { buildQueryString } from 'utils/solr';
import CONSTANTS from "../constants";
import logger from "../logger"
//import { getSummaryShort } from 'services/Summary_Slice';
import { toastListProp, toastType, createToastProperties } from '../components/toast/Toast';
import { addToQueue} from "../services/Toasts_Slice";
//import { ConsoleTransportOptions } from "winston/lib/winston/transports";
//
const urldecode = require('urldecode');


// facetMap: map with one facet type 'genre_s' with  names: {"facetdMap":{"genre_s":["cryosat-2","TERRASARX"]}}
// facetSelected: array of facetValue@facetName
// currentFacetMap: as per facetMap, but for new facet UI
//
export type FacetsState = {
    facetMap: {[name: string]: Array<string>},
    facetSelected: Array<string>,
    currentFacetMap: {[name: string]: Array<string>},
}

//
export type FacetsMap = {[name: string]: Array<string>};

// dictionnary of facets
export type FacetsMapPayload = {
    facetMap: {[name: string]: Array<string>},
}

// array of selected facet
export type FacetsSelected =  Array<string>;


// ad done facet
export type OneFacetsSelectedPayload = string


//
let initialState: FacetsState = {
    facetMap: {
        // loading: [CONSTANTS.STATE_NOT_SET]
    },
    facetSelected: [],
    currentFacetMap: {
        // loading: [CONSTANTS.STATE_NOT_SET]
    }
};


const facetSlice = createSlice({
    name: 'Facets',
    initialState: initialState,
    reducers: {
        setFacetsMap(state, action: PayloadAction<FacetsMapPayload>) {
            state.facetMap = action.payload.facetMap;
        },
        setCurrentFacetsMap(state, action: PayloadAction<FacetsMapPayload>) {
            state.currentFacetMap = action.payload.facetMap;
        },
        setFacetSelected(state, action: PayloadAction<FacetsSelected>) {
            state.facetSelected=action.payload;
        },
        addOneFacetSelected(state, action: PayloadAction<OneFacetsSelectedPayload>) {
            if (state.facetSelected.includes(action.payload)){
                logger.debug("addOneFacetSelected: already in facetSelected:"+action.payload);
            }else{
                state.facetSelected.push(action.payload);
            }
        },
        removeOneFacetSelected(state, action: PayloadAction<OneFacetsSelectedPayload>) {
            logger.debug("removeOneFacetSelected: '"+action.payload+"'");
            if (state.facetSelected.includes(action.payload)){
                logger.debug("removeOneFacetSelected: new state"+ JSON.stringify(state.facetSelected.filter(item => item !== action.payload)));
                state.facetSelected = state.facetSelected.filter(item => item !== action.payload);
            }else{
                logger.debug("removeOneFacetSelected: not present in facetSelected:"+action.payload);
            }
        },
        removeGroupFacetSelected(state, action: PayloadAction<string>) {
            logger.debug("removeGroupFacetSelected: group name="+action.payload);
            var newArray: string[] = [];
            for( var val in state.facetSelected){
                if(state.facetSelected[val].split("@")[1]!==action.payload){
                    newArray.push(state.facetSelected[val]);
                }
            }
            logger.debug("removeGroupFacetSelected: newArray="+newArray);
            state.facetSelected=newArray;
        }
    }
});


//
// get all the available facet names + values. i.e. when no criteria used
// 
//
export const getAvailableFacets = (facetConstraints: Array<string>): AppThunk => async dispatch => {
    console.log("@@@ getAvailableFacets: facetConstraints=" + JSON.stringify(facetConstraints));

    dispatch(setAllStates({loading:true, ok:true, error: ""}));

    fetch(CONSTANTS.ENDPOINT.REST_API + CONSTANTS.REST_GET_FACETS)
        .then(response => {
            //logger.debug("response 0: " + Response);
            if(response.ok){
                logger.debug("getAvailableFacets response 0: ok");
                return response.json();
            }else{
                logger.debug("getAvailableFacets response 0: not ok");
                throw new Error('Network response was not ok:' + response.status);
            }
        })
        .then(data => {
            logger.info("## getAvailableFacets data.payload: " + JSON.stringify(data));

            if (CONSTANTS.BACKEND_REPLY_IS_ERROR in data){
                logger.error("##################### getAvailableFacets error:" + data[CONSTANTS.BACKEND_REPLY_IS_ERROR]);
                const toastProperties: toastListProp = createToastProperties(toastType.error, CONSTANTS.REST_GET_FACETS_ALIAS, data[CONSTANTS.BACKEND_REPLY_IS_ERROR]);
                dispatch(addToQueue(toastProperties));
            }else{
                dispatch(setFacetsMap(data));
                dispatch(setCurrentFacetsMap(data));
                //const facetSelected: Array<string> = [];
                //dispatch(getSummaryShort(data.facetMap, facetConstraints, searchContraints))
            }
            dispatch(setAllStates({loading:false, ok:true, error: ""}));
        })
        .catch((error) => {
            logger.error("## getAvailableFacets: error="+error);
            console.error(error.stack);
            dispatch(setAllStates({loading:false, ok:false, error: error.message}));
            const toastProperties: toastListProp = createToastProperties(toastType.error, CONSTANTS.REST_GET_FACETS_ALIAS, error.message);
            dispatch(addToQueue(toastProperties));
            dispatch(setAllStates({loading:true, ok:true, error: ""}));
        })
};


//
// get all the available facet names +  the values in scope
//
export const getCurrentFacets = (facetMap: FacetsMap, facetSelected: FacetsSelected, operator: string): AppThunk => async dispatch => {

    logger.debug("getCurrentFacets facetMap:" + JSON.stringify(facetMap));
    logger.debug("getCurrentFacets facetSelected:" + JSON.stringify(facetSelected));

    const query = buildQueryString(
        "*:*",
        "*",
        undefined,
        undefined,
        facetSelected,
        facetMap,
        operator
        );
    logger.info("getCurrentFacets encoded query:" + query);
    logger.info("getCurrentFacets decoded query:" + urldecode(query));

    fetch(CONSTANTS.ENDPOINT.REST_API + CONSTANTS.REST_GET_CURRENT_FACETS + "?" + query)
        .then(response => {
            if(response.ok){
                logger.debug("getCurrentFacets response 0: ok");
                return response.json();
            }else{
                logger.debug("getCurrentFacets response 0: not ok");
                throw new Error('Network response was not ok:' + response.status);
            }
        })
        .then(data => {
            logger.debug("## getCurrentFacets data.payload: " + JSON.stringify(data));
            dispatch(setCurrentFacetsMap(data));
            //dispatch(getSummaryShort(data.facetMap, facetSelected));
            //dispatch(setAllStates({loading:false, ok:true, error: ""}));
        })
        .catch((error) => {
            logger.debug("## getCurrentFacets: error="+error);
            console.error(error.stack);
            //console.dir(error);
            //dispatch(setAllStates({loading:false, ok:false, error: error.message}));
            const toastProperties: toastListProp = createToastProperties(toastType.error, CONSTANTS.REST_GET_CURRENT_FACETS_ALIAS, error.message);
            dispatch(addToQueue(toastProperties));
            //dispatch(setAllStates({loading:false, ok:false, error: error.message}));
        })
};


export const {setFacetsMap, setCurrentFacetsMap, addOneFacetSelected, removeOneFacetSelected, setFacetSelected, removeGroupFacetSelected} = facetSlice.actions;

export default facetSlice.reducer