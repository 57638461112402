import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../services/rootReducer";
// toast + redux slice
import Toast from './toast/Toast';
import { removeFromQueue } from "../services/Toasts_Slice";

const ToastsContainer = () => {

    const dispatch = useDispatch();
    const {toastQueue, position, autoDismiss, dismissTime} = useSelector((state: RootState) => state.Toasts);

    //
    // when toast deleted
    //
    const onDeleted = (id: number) =>{
        console.log("#### toast deleted:"+id);
        dispatch(removeFromQueue(id));
    };

    /*
    const doAddToast = (type: number, title: string, description: string) =>{
        const toastProperties: toastListProp = createToastProperties(type, title, description);
        console.log("#### will dispatch toast property:"+JSON.stringify(toastProperties));
        dispatch(addToQueue(toastProperties));
    }*/

    return(
        <div className="toastContainer">
            <Toast 
                toastList={toastQueue}
                position={position}
                autoDismiss={autoDismiss}
                /*autoDismiss={true}*/
                dismissTime={dismissTime}
                onDeleted={onDeleted}
                //onCreated={onCreated}
            />
        </div>
    )

}

export default ToastsContainer