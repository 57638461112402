const CONSTANTS: any  = {}

// version
CONSTANTS.VERSION = '  V 5.1  2022/07/06  Or/And for search component. '

// debug
CONSTANTS.DEBUG = 1

// download pdf timeout in ms
CONSTANTS.DOWNLOAD_TIMEOUT = 20000

// error messages
CONSTANTS.ERROR_MESSAGE = {}
CONSTANTS.ERROR_MESSAGE.REST_GET = 'REST GET Request failed: '
CONSTANTS.ERROR_MESSAGE.REST_POST = 'REST POST Request failed: '
CONSTANTS.ERROR_MESSAGE.GET = 'Get Request failed: '

// REST info
CONSTANTS.ENDPOINT = {}
CONSTANTS.ENDPOINT.REST_API = "/api"
// REST path
CONSTANTS.REST_GET_FACETS = "/real/facets"
CONSTANTS.REST_GET_FACETS_ALIAS = "Facets"
//
CONSTANTS.REST_GET_CURRENT_FACETS = "/real/currentFacets"
CONSTANTS.REST_GET_CURRENT_FACETS_ALIAS = "CurrentFacets"
//
CONSTANTS.REST_GET_MISSIONS_COLORMAP = "/real/missionsColor"
CONSTANTS.REST_GET_MISSIONS_COLORMAP_ALIAS = "MissionsColor"
//
CONSTANTS.REST_GET_GRADIENT_COLORMAP = "/real/gradientColor"
CONSTANTS.REST_GET_GRADIENT_COLORMAP_ALIAS = "GradientColor"
//
CONSTANTS.REST_GET_SUMMARY_SHORT = "/real/getSummaryShort"
CONSTANTS.REST_GET_SUMMARY_SHORT_ALIAS = "GetSummaryShort"
//
CONSTANTS.REST_GET_GRADIENT_MAP = "/real/getGradientMap"
CONSTANTS.REST_GET_GRADIENT_MAP_ALIAS = "GetGradientMap"
//
CONSTANTS.REST_DO_QUERY = "/real/query"
CONSTANTS.REST_DO_QUERY_ALIAS = "Query"
//
CONSTANTS.REST_DO_TERMS_QUERY = "/real/terms"
CONSTANTS.REST_DO_TERMS_QUERY_ALIAS = "QueryTerms"
//
CONSTANTS.REST_GET_HEATMAP = "/real/heatmap"
CONSTANTS.REST_GET_HEATMAP_ALIAS = "HeatMap"
//
CONSTANTS.REST_GET_QRARCHIVE = "/qrArchive?"
//
CONSTANTS.REST_GET_QRARCHIVE_POST = "/qrArchive-post"

//textSearch and summaryShort AND/OR (default is OR)
CONSTANTS.DEFAULT_OPERATOR_TO_USE = 'Or'
// CONSTANTS.OPERATOR_TO_USE = 'AND'

// document date field
CONSTANTS.CRITERIA = {}
//
CONSTANTS.CRITERIA.DATERANGE = 'file_modified_dt'
// document retrieved fields
CONSTANTS.CRITERIA.FIELDS_LIST= 'id,file_modified_dt,path_basename_s,title,shorttitle,mission_ss,instrument_ss,producttype_ss,level_ss,calendartype_ss,documenttype_ss'
// search on facet/field: default is 'text'
CONSTANTS.CRITERIA.FREESEARCH = '_text_'

// result elements
CONSTANTS.RESULT={}
CONSTANTS.RESULT.FIELD_URL = 'id' // FULL URL, used to retrieve the PDF
CONSTANTS.RESULT.FIELD_URL_LOCAL_SEPARATOR = '/documents' // what follow this is to be used as url by the proxy to retrieve doc by proxy
//CONSTANTS.RESULT.FIELD_URL_LOCAL_SEPARATOR = '/DataStore' // what follow this is to be used as url by the proxy to retrieve doc by proxy

// mobile: width limit for mobile UI
CONSTANTS.MOBILE_THREASHOLD = 768

CONSTANTS.UI = {}

// desired date format (listview and popup)
CONSTANTS.UI.DATE_FORMAT_DESIRED = 'dd-MM-yyyy'

// toltip color
CONSTANTS.UI.TOOLTIP_COLOR = '#525455';

// color fields
// top category: mission_ss
CONSTANTS.UI.TOP_CAT_COLOR_FIELD = 'colorhexcodemis'
// document categorisation: 
CONSTANTS.UI.REPORT_CAT_COLOR_FIELD = 'colorhexcode'

CONSTANTS.UI.TOP_CAT = 'mission_ss'
CONSTANTS.UI.TOP_CAT_LABEL = 'Mission'


// document item info zones on the list item
CONSTANTS.UI.DOC_ITEM_INFO_ZONE = {} //[doc.item: label]
CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE0 = ['title', '']
CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE1 = ['path_basename_s', '']
CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE2 = [CONSTANTS.UI.TOP_CAT, 'Mission: ']
CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE3 = ['instrument_ss', 'Instrument: ']
CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE4 = ['calendartype_ss', 'Calendar Type: ']
CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE5 = ['file_modified_dt', 'Date: ']
CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE6 = ['level_ss', 'Level: ']
CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE7 = ['producttype_ss', 'Product Type: ']
CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE8 = ['documenttype_ss','Document Type: ']
CONSTANTS.UI.DOC_ITEM_INFO_ZONE.ZONE9 = ['','']

//chart info
CONSTANTS.UI.CHART_DATA_0 = 'calendartype_ss';
CONSTANTS.UI.CHART_DATA_1 = CONSTANTS.UI.TOP_CAT
CONSTANTS.UI.CHART_DATA_2 = 'productType_ss';

CONSTANTS.UI.CHART_LABEL_0 = 'Report type :';
CONSTANTS.UI.CHART_LABEL_1 = CONSTANTS.UI.TOP_CAT_LABEL;
CONSTANTS.UI.CHART_LABEL_2 = 'ProductType :';


interface Dictionary<T> {
    [Key: string]: T;
}
CONSTANTS.UI.FACETS_ALIAS =  {
    'mission_ss': 'Mission',
    'instrument_ss': 'Instrument',
    'producttype_ss': 'Product Type',
    'level_ss': 'Level',
    'calendartype_ss': 'Calendar Type',
    'documenttype_ss': 'Document Type',
    } as Dictionary<string>;



// sorting
CONSTANTS.UI.SORTING_FIELDS = {'date': 'file_modified_dt', 'filename': 'path_basename_s'};
CONSTANTS.UI.SORTING_DEFAULT_FIELD_INDEX = 'date';

// the pagination on resultListView
//CONSTANTS.UI.ITEM_PER_PAGE = 10;
// CONSTANTS.UI.ITEM_PER_PAGE_CHOICES = [10, 25, 50, 100];
CONSTANTS.UI.ITEM_PER_PAGE_CHOICES = [10, 25, 50];

// items for week cal
CONSTANTS.UI.CALENDAR_ITEM_PER_WEEK = 500;

// document proxy URL, to avoid CORS problems. Is built in QRAST_server, see his setting constants.ts file
CONSTANTS.PROXY_DOCUMENTS_BASE_PATH = "/proxy"

// backend error 
CONSTANTS.BACKEND_REPLY_IS_ERROR='ERROR'

// SOLR errors
//CONSTANTS.SOLR_CONNECT_ERROR = "SOLR connect error !";

// state: initial state of loaded things (facets, etc..)
CONSTANTS.STATE_NOT_SET = "loading";

// document webserver auth
CONSTANTS.AUTH={}
CONSTANTS.AUTH.DOC_USER='eog'
CONSTANTS.AUTH.DOC_PASSWORD="p5*'hHmsr}9_\"zz4"

// months
CONSTANTS.MONTHS = ['NULL', "JAN", "FEB", "MAR","APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
CONSTANTS.MONTHS.FULL = ['NULL', "January", "February", "March","April", "May", "June", "July", "August", "September", "October", "November", "December"]


CONSTANTS.LEGEND_DATES = {'CryoSat':'(2010-ongoing)', 'Aeolus':'(2018-2023)', 'ALOS-1':'(2006-2011)',
                            'Envisat':'(2002-2012)', 'ERS-2':'(1995-2011)', 'GOCE':'(2009-2013)', 
                            'Landsat-8':'(2013-ongoing)', 'Proba-V':'(2013-ongoing)', 'SMOS':'(2009-ongoing)',
                            'Swarm':'(2013-ongoing)'}

export default CONSTANTS
