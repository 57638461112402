//
//
// display the main component.
// because we want 'normal' and 'mobile' layout, the main component will be a layout switcher
// that is we go a little bit to an adaptative layout (but also responsive)
//
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
//
import store from "./services/store";
//
import CONSTANTS from "./constants";
import { MOBILE_THREASHOLD } from './QrastLayout';
import QrastLayout from './QrastLayout';
//
import 'bootstrap/dist/css/bootstrap.min.css';
import 'index.css'

//process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

// need to know width to set layout init props
const width = window.innerWidth;

// router tests: get query params
function getParams(location:any): URLSearchParams {
  //const dispatch = useDispatch();
  const searchParams: URLSearchParams = new URLSearchParams(location.search);
  //console.log("@@@@@@ router tests; urlConstraints searchParams:" + searchParams);
  //console.log("@@@@@@ router tests; urlConstraints query=" + searchParams.get("query"));
  //return searchParams.get("query") || "";
  return searchParams;
}

// block all logging
if(CONSTANTS.DEBUG == 0){
  console.log = function() {};
  console.warn = function() {};
  console.error = function() {};
}

var urlConstraints: string = '';
console.log("  #### QRAS version: "+ CONSTANTS.VERSION+" ####");

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <Route
            path="/"
            render={({ location, history }) => {
              //urlConstraints = getParams(location);
              const searchParams: URLSearchParams = new URLSearchParams(location.search);
              //console.log("@@@@@@ router tests; urlConstraints useQuery query=" + query);
              //console.log("@@@@@@ router tests; urlConstraints searchParams:" + searchParams);
              return <QrastLayout searchParams={searchParams} willShowSidebar={width > CONSTANTS.MOBILE_THREASHOLD ? true : false} useMobileView={width > MOBILE_THREASHOLD ? false : true}/>
            }}
          />    
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
