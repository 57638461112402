

import React, { Component } from 'react';
import { Chart } from 'primereact/chart';

interface Dictionary<T> {
    [Key: string]: T;
}

type datasetsType = {
    data: Array<number>;
    backgroundColor: Array<string>;
    hoverBackgroundColor: Array<string>;
}

type chartDataType = {
        labels: Array<string>;
        datasets: Array<datasetsType>;
}

type propsType = {
    chartData: chartDataType;
    chartOptions: any;
}


export default class DoughnutChart extends Component<propsType> {

    /*constructor(props: propsType) {
        super(props);
        console.log(" #### DoughnutChart; props:"+JSON.stringify(props));
    }*/


    lightOptions: any = {
        legend: {
            display: false,
            labels: {
                fontColor: '#495057'
            }
        }
    };


    render() {
        //console.log(" #### DoughnutChart; lightOptions:"+JSON.stringify(this.lightOptions));
        return (
            <Chart type="doughnut" data={this.props.chartData} options={this.props.chartOptions} />
        )
    }
}

