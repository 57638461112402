import colormap from "colormap";
import logger from '../../logger';


interface Dictionary<T> {
    [Key: string]: T;
}

// doughnut
type chartEntryType = {
    angle: number,
    color: string,
}

// gradien entry
type gradientEntryType = {
    label: string,
    data: Array<number>,
    backgroundColor: string,
    hoverBackgroundColor: string,
}
// gradient type
type gradientType = {
    labels: Array<string>,
    datasets: Array<gradientEntryType>,
}

/*
* 

input is like:
{
	"CryoSat-2":
		{"SIRAL Daily OFL Ocean":1530,"SIRAL Daily FDM Ice":1120,"SIRAL Daily FDM":921,"SIRAL Daily IOP Ocean ":670,"SIRAL Daily GOP Ocean":625,"SIRAL Daily NOP Ocean":284},
	"SMOS":
		{"SMOS Weekly":268,"SMOS Monthly":35}
	...
}



result is like:
{
        labels: ["2014", "2013", "2012", "2011"],
        
        datasets: [{
            label: 'Dataset 1',
            data: [727, 589, 537, 543, 574],
            backgroundColor: "rgba(63,103,126,1)",
            hoverBackgroundColor: "rgba(50,90,100,1)"
        },{
            label: 'Dataset 2',
            data: [238, 553, 746, 884, 903],
            backgroundColor: "rgba(163,103,126,1)",
            hoverBackgroundColor: "rgba(140,85,100,1)"
        },{
            label: 'Dataset 3',
            data: [1238, 553, 746, 884, 903],
            backgroundColor: "rgba(63,203,226,1)",
            hoverBackgroundColor: "rgba(46,185,235,1)"
        }]
    };
*/
export function getGradientData(numFound: number, gradientData: any, gradientColorMap: any): Array<any>{
    logger.debug("@@@@@@@@@@@@@@ getGradientData params; gradientData: "+ JSON.stringify(gradientData));


    // to build result {}
    //var label = []; // mission
    var datasetMap: Dictionary<any>  = {}; // mission -> gradient (single line)

    // chart stuff
    var notEmpty=false;
    // to put total on item (legend?)
    //var itemTotal: Dictionary<number>={}


    // names ( of CHART_FIELD)
    //var names: Array<string> = [];
    // values
    //var values: Array<number> = [];
    // color used
    //var colorsUsed: Array<string> = [];

    var numMission=0;
    if(gradientData!==undefined){
        notEmpty=true;
        for (var mission in gradientData){
            logger.debug("@@@@@@@@@@@@@@ getGradientData params; gradientData[" + numMission + "]: " + mission);

            var datasetLines: Array<any> = [];
            for (var docType in gradientData[mission]){
                var gradientOneDataset: gradientEntryType = {
                    label: docType,
                    data: [gradientData[mission][docType]],
                    backgroundColor: gradientColorMap[mission] !== undefined ? gradientColorMap[mission][docType] : '#000000',
                    hoverBackgroundColor: gradientColorMap[mission] !== undefined ? gradientColorMap[mission][docType] : '#000000',
                };
                datasetLines.push(gradientOneDataset);
            }

            const aMissionGradient: gradientType = {
                labels: [mission],
                datasets: datasetLines
            }

            datasetMap[mission] = aMissionGradient;
            numMission+=1;
        }
    }


    return [notEmpty, datasetMap];
}


/*
* for the chart.js doughtnut
* minimum is: 
{
    labels: ['Toto: A', 'B', 'C'],
    datasets: [
        {
            data: [300, 50, 100],
            backgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56"
            ],
            hoverBackgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56"
            ]
        }]
    }
*
*/
export function getDoughnutData(numFound: number, facetFound: any, CHART_FIELD: string, missionColorMap: any): Array<any>{
    logger.debug("@@@@@@@@@@@@@@ getDoughnutData params; missionColorMap: "+ JSON.stringify(missionColorMap));

    // new for chart.js
    var result: Dictionary<any> = {};


    // names ( of CHART_FIELD)
    var names: Array<string> = [];
    // values
    var values: Array<number> = [];
    // color used
    var colorsUsed: Array<string> = [];

    //char data sall be like: const myData:any = [{angle: 1, label="aa", color="xx"}, {angle: 5, label="bb", color="xx"}, {angle: 2, label="cc", color="xx"}]
    //var myChartData: Array<chartEntryType> = [];

    // chart stuff
    var notEmpty=false;
    // to put total on item (legend?)
    var itemTotal: Dictionary<number>={}

    //var numMission;
    //var total=0;
    if(facetFound!==undefined && facetFound[CHART_FIELD]!==undefined){

        //numMission = facetFound[CHART_FIELD].length;
        for(var arInd in facetFound[CHART_FIELD]){
            const name: string = facetFound[CHART_FIELD][arInd].split('|')[0];
            names.push(name);
            const num: number = Number(facetFound[CHART_FIELD][arInd].split('|')[1]);
            values.push(num);
            //total+=num;
            logger.debug("@@@@@@@@@@@@@@ totalPerItem doing '"+arInd+"' name="+name+"; num="+num);
            itemTotal[name] = num;
        }

        //logger.info("@@@@@@@@@@@@@@ names=" + JSON.stringify(names));
        //logger.info("@@@@@@@@@@@@@@ values=" + JSON.stringify(values));
        //logger.info("@@@@@@@@@@@@@@ totalPerItem=" + JSON.stringify(totalPerItem));
        logger.debug("@@@@@@@@@@@@@@ itemTotal=" + JSON.stringify(itemTotal));


        var n=0;
        for(var arInd2 in names){
            if(values[n]>0){
                //myChartData.push({angle: values[n], color: missionColorMap[names[arInd2]]});
                colorsUsed.push(missionColorMap[names[arInd2]]);
                notEmpty=true;
                // add some info on label. Default shows: 'Name: total'
                //names[arInd]=names[arInd]+" Color: "+missionColorMap[names[arInd]];
                //names[arInd]=names[arInd]+" N: "+itemTotal[names[arInd]];
            }
            n+=1;
        }

    }else{
        logger.info("@@@@@@@@@@@@@@ no '"+ CHART_FIELD + "' field in summary facetFound map");
    }  

    result['labels'] = names;
    result['datasets'] = [{
        'data': values,
        'backgroundColor': colorsUsed,
        'hoverBackgroundColor': colorsUsed,
    }];
    logger.debug("@@@@@@@@@@@@@@ getDoughnutData return: "+ JSON.stringify(result));

    return [notEmpty, result, itemTotal];
    
}





/*
* for the rsuite chart
* minimum is: [{angle: 1, label="aa", color="xx"}, {angle: 5, label="bb", color="xx"} ...
*
*/
export function getChartData(facetFound: any, CHART_FIELD: string): Array<any>{

    // names ( of CHART_FIELD)
    var names: Array<string> = [];
    // values
    var values: Array<number> = [];

    //char data sall be like: const myData:any = [{angle: 1, label="aa", color="xx"}, {angle: 5, label="bb", color="xx"}, {angle: 2, label="cc", color="xx"}]
    //var myChartData: Array<chartEntryType> = [];

    // chart stuff
    var notEmpty=false;
    // colors used, to also put the CHART_FIELD color into the other control: CHART_FIELD list
    var missionColorMap: Dictionary<any> = {};
    // to put total on item (legend?)
    //var totalPerItem: Dictionary<Array<string>>={}
    var itemTotal: Dictionary<number>={}

    var numMission;
    //var total=0;
    if(facetFound!==undefined && facetFound[CHART_FIELD]!==undefined){

        numMission = facetFound[CHART_FIELD].length;
        for(var arInd in facetFound[CHART_FIELD]){
            const name: string = facetFound[CHART_FIELD][arInd].split('|')[0];
            names.push(name);
            const num: number = Number(facetFound[CHART_FIELD][arInd].split('|')[1]);
            values.push(num);
            //total+=num;

            logger.debug("@@@@@@@@@@@@@@ totalPerItem doing '"+arInd+"' name="+name+"; num="+num);

            /* put in TotalPerItem map. key is total.
            if (num in totalPerItem){
                totalPerItem[num].push(name);
            }else{
                var aList: Array<string> = [name];
                //aList.push(name);
                totalPerItem[num] = aList;
            }*/

            itemTotal[name] = num;
        }

        //logger.info("@@@@@@@@@@@@@@ names=" + JSON.stringify(names));
        //logger.info("@@@@@@@@@@@@@@ values=" + JSON.stringify(values));
        //logger.info("@@@@@@@@@@@@@@ totalPerItem=" + JSON.stringify(totalPerItem));
        //logger.debug("@@@@@@@@@@@@@@ itemTotal=" + JSON.stringify(itemTotal));

        // jet map requires nshades to be at least size 6
        // hsv map requires nshades to be at least size 6
        var numColor = 11;
        if (numMission>11){
            numColor = numMission;
        }
        const colors = colormap({
            colormap: 'hsv',
            nshades: numColor,
            format: 'hex',
            alpha: 1
        })

        var n=0;
        for(var arInd3 in names){
            if(values[n]>0){
                //myChartData.push({angle: values[n], color: colors[n]});
                missionColorMap[names[arInd3]] = colors[n];
                notEmpty=true;
            }
            n+=1;
        }


    }else{
        logger.info("@@@@@@@@@@@@@@ no '"+ CHART_FIELD + "' field in summary facetFound map");
    }  

    return [notEmpty, missionColorMap, itemTotal];
    
}