/*
 * utility methods for solr access
 *
 */
import { FacetsMap } from 'services/Facets_Slice';
import logger from '../logger';

import CONSTANTS from "../constants";

var urlencode = require('urlencode');
//var urldecode = require('urldecode');

interface Dictionary<T> {
    [Key: string]: T;
}




/*
* build the query string for mission color search
*
* shall be like: 
*   q=*%3A*&facet.pivot=mission_ss,colorhexcodemis&facet=true&rows=0 ??
*
*/
export function buildColorQueryString(): string{
    const res: string = "q=*:*&facet.pivot=" + CONSTANTS.UI.TOP_CAT +  "," + CONSTANTS.UI.TOP_CAT_COLOR_FIELD + "&facet=true&rows=0";
    logger.debug("## buildColorQueryString return: "+ res);
    return res;
}




/*
* build the query string for search
*
* Note: search and freeTextSearch are redondant... Maybe shall remove one of them
* - freeTextSearch if used overwrite search (default: '*:*') with: '_text_:criteria' (CONSTANTS.CRITERIA.FREESEARCH:criteria)
*
*/
export function buildQueryString(
     search: string,
     freeTextSearch: string,
     start: string | undefined,
     stop: string | undefined,
     facetSelected: string[],
     facetMap: FacetsMap,
     operator: string
     ): string {

    logger.info("## buildQueryString: search:"+search+"; freeTextSearch:"+freeTextSearch);
    logger.info("## buildQueryString: start:"+start+"; stop:"+stop);
    logger.info("## buildQueryString: facetSelected:"+ JSON.stringify(facetSelected));
    logger.info("## buildQueryString: FacetsMap:"+ JSON.stringify(facetMap));
    logger.info("## buildQueryString: CONSTANTS.CRITERIA.FIELDS_LIST:"+ CONSTANTS.CRITERIA.FIELDS_LIST);

    //query using filter on facets : ?fq=genre_s%3Arire&fq=gltype_s%3Acomic&q=*%3A*
    var queryPart = "";
    var facetPart = "";

    // default *:*
    var result = "q="+search+"&";

    // free text search if any
    if(freeTextSearch !== undefined && freeTextSearch.trim().length >1 ){ //
        var init = true;
        result = "q=(";
        for(var elem of freeTextSearch.split(" ")){
            if(init){
                result += urlencode(CONSTANTS.CRITERIA.FREESEARCH + ":" + elem) + "*";
                init = false;
            }else{
                //result += "%20" + CONSTANTS.OPERATOR_TO_USE + "%20" + urlencode(CONSTANTS.CRITERIA.FREESEARCH + ":" + elem) + "*";
                result += "%20" + operator.toUpperCase() + "%20" + urlencode(CONSTANTS.CRITERIA.FREESEARCH + ":" + elem) + "*";
            }
        }
        result += ")&";
        // result = "q=" + urlencode(CONSTANTS.CRITERIA.FREESEARCH + ":" + freeTextSearch) + "*&";
    }

    // facet list
    result += "facet=on&facet.query=*:*&fl=" + urlencode(CONSTANTS.CRITERIA.FIELDS_LIST) + "&";


    // 1) add all facets names as facet.fields criteria name, with criteria value *:*, to get the facets totals
    // like: {"Mission_ss":["Aeolus","Swarm","cryosat-2"],"Instrument_ss":["Aladin","instrument1","siral","siral-doris"],"ProductType_ss":["gob-b","gom","gop-c","iop","p_ss","p_ss1","p_ss4","p_ss5","p_ss6","p_ss7","p_ss8","p_ss9"]}
    /*Object.keys(facetMap).map(item => {
        logger.info("#### buildQueryString2: do facetMap item: " + item);
        facetPart += "facet.field=" + item + "&";
    })*/

    Object.keys(facetMap).forEach(item => {
        logger.debug("## buildQueryString: do facetMap item: " + item);
        facetPart += "facet.field=" + item + "&";
      });


    // 2) add selected facets values
    // facetSelected is a map like: ["comic@gltype_s"]
    if (facetSelected.length!==0){
        // need to add all xxx:value from value@xxx into several fp
        for (var val of facetSelected) {
            queryPart += "fq=" + val.split("@")[1] + ":" + "\"" + val.split("@")[0] + "\"" + "&";
        }

    }

    // add date range
    if(start !== undefined && stop !== undefined) {
        queryPart += "fq=" + urlencode(CONSTANTS.CRITERIA.DATERANGE + ':["' + start.replaceAll("/", "-") + 'T23:59:59Z" TO "' + stop.replaceAll("/", "-") + 'T00:00:00Z"]');
    }else{
        logger.debug("#### buildQueryString: start or stop undefined");
    }

    result += facetPart;
    result += queryPart;

    logger.debug("#### buildQueryString: facetPart: "+facetPart);
    logger.debug("#### buildQueryString: queryPart: "+queryPart);
    logger.debug("#### buildQueryString: result: "+result);

    const encoded = urlencode(result);
    logger.debug("#### buildQueryString: encoded: "+encoded);

    return result;

}