import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Container, Button, Dropdown } from 'react-bootstrap';
import ReactTooltip from "react-tooltip";
import { FlexboxGrid, List } from "rsuite";
import { RootState } from "../services/rootReducer";
import { doQuery } from "../services/Query_Slice";
import { cursorsType, setCursors, setPageRangeUsed } from 'services/View_Slice';
import MinusIcon from "@material-ui/icons/ArrowBackIos";
import PlusIcon from "@material-ui/icons/ArrowForwardIos";
import CONSTANTS from "../constants";
import styles from "./css/ResultsListView.module.css";
//import useWindowDimensions from "./utils/useWindowDimensions";
import ResultListItem  from "./ResultListItem";
import { buildQueryViewParams } from "./utils/queryHelper";
import logger from 'logger';
import DateNav from "components/DateNav";
import ViewSwitch from "components/ViewSwitch";
import SortControl from "components/SortControl";
import FlexboxGridItem from 'rsuite/lib/FlexboxGrid/FlexboxGridItem';
//import {Grid, Col} from 'rsuite';
import { format, parseISO } from 'date-fns';
// import { setLoading } from 'services/Loading_Slice';
import { clearUrls, addUrl } from 'services/Download_Slice';
import OverlayComp from './OverlayComp';

type screenValues = {
  mobile: boolean,
  width: number
}

function ResultsListView(props: React.PropsWithChildren<screenValues>) {

    //const { height, width } = useWindowDimensions();

    const dispatch = useDispatch();
    const {loading} = useSelector((state: RootState) => state.Loading)
    const {viewType, cursorStart, listSortedFeld, listSortedOrder, calRangeUsed, calRangeValue, pageRangeUsed, mobile} = useSelector((state: RootState) => state.View);
    const {result, request} = useSelector((state: RootState) => state.Query);
    const {urls} = useSelector((state: RootState) => state.Download);

    // used for pdf zip download
    const [downloading, setDownloading] = useState(false)
    // used for the select all button
    const [selected, setSelected] = useState(false)
    // used for select all button check state
    const [checked, setChecked] = useState(false)

    // if loading value in the store changes => then clear selected and urls (in store)
    useEffect(() => {
      setSelected(false);
      setChecked(false)
      dispatch(clearUrls());
    }, [loading, downloading])

    //
    //const usedPageRangeLabel = mobile ? styles.glPageRangeLabel_mobile : styles.glPageRangeLabel
    //const usedContainer = mobile ? styles.container : styles.container
    const usedLabelStyle = mobile ? styles.label_mobile: styles.label;
    const usedGlPageRangeLabel = mobile ? styles.glPageRangeLabel_mobile: styles.glPageRangeLabel;
    const usedGlIconStyle = mobile ? styles.glIcon_mobile: styles.glIcon;

    //const usedGlButton = mobile ? styles.glButton_mobile: styles.glButton;
    // for pagination
    const usedGlButton = mobile ? styles.glPageButton_mobile: styles.glPageButton; 
    const usedGlPageButton = mobile ? styles.glPageButton_mobile: styles.glPageButton;
    const usedGlPageLabelStyle = mobile ? styles.glPageLabel_mobile: styles.glPageLabel;
    //
    const usedPaginationChoiceStyle = mobile ? styles.paginationChoice_mobile: styles.paginationChoice;
    const usedGlDropdown = mobile ? styles.glDropdown_mobile: styles.glDropdown;
    const usedTitleLabel = mobile ? styles.title_mobile: styles.title;

    //const flexboxtype = mobile ? "center" : "end"

    var numFound: number = 0;
    var resStart: number = 0;
    var docs:any = []; 
    var numDocs = 0;
    if(result!==undefined && result["numFound"] !== undefined){
      numFound = result["numFound"];
      docs = result["docs"];
      numDocs = docs.length;
      resStart = result["start"]!==undefined ? result["start"] : -1;
    }

    // check nav button + pages
    const pageIndex: number = Math.floor(resStart/pageRangeUsed); // starts at 0
    const pageTop: number = Math.ceil(numFound/pageRangeUsed);
    const navMinus = resStart>0 ? true: false;
    const navPlus = (resStart + numDocs) <  numFound ? true: false;
    logger.debug("## resultListView2: (resStart + numDocs):" + (resStart + numDocs));
    logger.debug("## resultListView2: numDocs:" +numDocs+ "; numFound:" + numFound);
    logger.debug("## resultListView2: pageIndex:" +pageIndex+ "; pageTop:" + pageTop);


    // pagination button settings
    const maxButton = 13;
    var lessZone = false;
    var moreZone = false;
    var lessZoneIndex = 0;
    var lessZoneStart = 0;
    var lessZoneStop = 0;
    var moreZoneStart = 0;
    var moreZoneStop = 0;
    var moreZoneIndex = 0;
    if (pageTop>maxButton){
      // xZone 
      if(pageIndex >= maxButton/2-1){
        lessZone = true;
        lessZoneStart = 2;
        lessZoneStop = pageIndex - (maxButton/3)+3;
        lessZoneIndex = 2
      }
      if(pageIndex < (pageTop - maxButton/2)){
        moreZone = true;
        moreZoneStart = pageIndex + (maxButton/3)-3;
        moreZoneStop = pageTop - 3;
        moreZoneIndex = pageTop - 3;
      }
    }
    

    //var n=0;
    let range = Array.from(Array(pageTop).keys());
    logger.debug("## resultListView2: range:" + range);
    logger.debug("## resultListView2: lessZone:" + JSON.stringify(lessZone)+"; moreZone:" + JSON.stringify(moreZone));
    logger.debug("## resultListView2: lessZoneStart:" + lessZoneStart+"; lessZoneStop:" + lessZoneStop+"; lessZoneIndex:" + lessZoneIndex);
    logger.debug("## resultListView2: moreZoneStart:" + moreZoneStart+"; moreZoneStop:" + moreZoneStop+"; moreZoneIndex:" + moreZoneIndex);


    // the pagination buttons
    const PageButtonToRender = range.map(value => {

      

      var show: boolean = true;
      var zone: boolean = false;

      if(moreZone === true && (value>moreZoneStart && value<moreZoneStop)){
        show = false;
      }

      if(lessZone === true && (value>lessZoneStart && value<lessZoneStop)){
        show = false;
      }

      if((lessZone === true && value === lessZoneIndex) || (moreZone === true && value === moreZoneIndex)){
        zone = true;
      }


      if( zone === true ){
          return(
            <span key={'dot_' + value} className={usedLabelStyle}> . . . </span>
          )
      }else if(show === false){
        return(
          null
        )
      }else{

        const outLineType = value!==(pageIndex) ? "outline-light" : "light";
        const toolTipId = String(value+1);
        // start at index 0
        const newStart = pageRangeUsed * value;
        const newRows = newStart + pageRangeUsed - 1 < numFound ? pageRangeUsed : numFound - newStart + 1;

        return(
              <span key={value}>
                <Button variant={outLineType}
                    onClick = {(e) => {
                        //console.log("##toto: page button index" + value);
                        // set cursor. Add cursor info to query, run query
                        //console.log("##toto page button index '" + value +"': newStart="+newStart+"; newRows="+newRows);

                        // add date info
                        const cursorInfo: cursorsType = {cursorStart: newStart, cursorRows: newRows};
                        const viewparams = buildQueryViewParams(viewType, newStart, newRows, listSortedFeld, listSortedOrder, calRangeUsed, calRangeValue);
                        dispatch(setCursors(cursorInfo));

                        //dispatch(doQuery(request+"&start="+newStart+"&rows="+newRows+"&sortBy="+listSortedFeld+"&sortOrder="+listSortedOrder));
                        dispatch(doQuery(request + "&"+ viewparams))
                    }}
                    value={value}
                    className={usedGlButton}
                    data-for={toolTipId}
                    data-tip
                    >
                  <div className={usedGlPageLabelStyle}>{value+1}</div>

                </Button>
                <ReactTooltip className={styles.glToolTip} id={toolTipId} place="bottom" effect="solid" textColor='#000000'backgroundColor={CONSTANTS.UI.TOOLTIP_COLOR}>
                  Page {toolTipId} : from {newStart} to {newStart+newRows-1}
                </ReactTooltip>
              </span>

        );

      }

    });
    logger.debug("## resultListView2: PageButtonToRender length:" + PageButtonToRender.length);

    // the list items
    range = Array.from(Array(numDocs).keys());

    // the items per page dropdown
    const dropDownPaginationItemsToRender = Object.keys(CONSTANTS.UI.ITEM_PER_PAGE_CHOICES).map(ind => {
      return(
          <Dropdown.Item key={CONSTANTS.UI.ITEM_PER_PAGE_CHOICES[ind]} eventKey={CONSTANTS.UI.ITEM_PER_PAGE_CHOICES[ind]} className={usedPaginationChoiceStyle}>{CONSTANTS.UI.ITEM_PER_PAGE_CHOICES[ind]}</Dropdown.Item>
      )
    });

    // calculate end of the week
    const calcLastWeekDay = (value : number, month: string) =>{
      var date = parseISO(calRangeValue)
      var endDate = new Date(date.setDate(date.getDate() + 6))
      var anEndDateStr = format(endDate, "yyyy-MM-dd")
      return anEndDateStr;
    }

    // TODO
    // handle download request to backend and return .zip file with pdfs
    const handleDownload = async () => {
      // loading state activated
      setDownloading(true)
      
      console.log("HANDLE DOWNLOAD STARTED...");
      
      // create the body of the POST req
      var body = ''
      for(var elem of urls){
        body = body + 'uri=' + elem + '&';
      }
      console.log("STRINGS: ", body);
      // clear the store of urls
      dispatch(clearUrls()); 

      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'text/plain'
        },
        body: body
      };

      // execute POST req and read response
      try{
        var url_string: string = CONSTANTS.ENDPOINT.REST_API + CONSTANTS.REST_GET_QRARCHIVE_POST;

        setSelected(false);

        // create timeout and abort controller
        const timeout = CONSTANTS.DOWNLOAD_TIMEOUT;
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);

        // get the response and clear timeout
        const response = await fetch(url_string, {...requestOptions, signal: controller.signal});
        clearTimeout(id);

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'qr_zip_archive');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }catch(error){
        console.log('ERROR: [' + error + ']');
      }

      // con questo fai il refresh di tutta la pagina per risolvere i checked delle checkbox (ma non va bene)
      //window.location.reload();
  }

  const downloader = () => {
    handleDownload().then(() => setDownloading(false))
  }

  // select all checkboxes feature
  const selectAll = (event: { target: { checked: any; }; }) => {
    if(event.target.checked) {
      console.log("checked"); 
      setChecked(true)
      setSelected(true)
      // add all urls to the store
      for(var i in range){
        dispatch(addUrl(docs[i][CONSTANTS.RESULT.FIELD_URL]));
      }
    }else{
      console.log("not checked");
      setChecked(false)
      setSelected(false)
      // clear urls from the store
      dispatch(clearUrls());
    }
  };

    // end of the week
    var endDateStr = calcLastWeekDay(parseInt(calRangeValue.split("-")[2]), calRangeValue.split("-")[1])

    let elem;
    if(loading){
      elem = null;
    }
    else if(downloading){
      elem = <OverlayComp/>;
    }else{
      elem = (
        <Container className={styles.container}>

          <FlexboxGrid style={{paddingTop: '15px'}} justify="space-between">

            <FlexboxGridItem>
              <FlexboxGrid className={styles.paddingLeft} justify="space-around">

                <FlexboxGridItem>
                  { viewType===0 ?
                  <SortControl/>
                  : <span></span>
                  }
                </FlexboxGridItem>

                <FlexboxGridItem>
                  { numFound > 0 ?
                  <div className={styles.divPageRange}>
                  <div className={usedGlPageRangeLabel}>Show</div>
                  <Dropdown
                    className={usedGlDropdown}
                    onSelect={(e) => {
                        logger.info("@@@@@@@@@ dropdown paginationRange:" + e);
                        const newPageRangeUsed: number = Number(e);

                        if(newPageRangeUsed !== pageRangeUsed){

                          const newPage = Math.floor(cursorStart/newPageRangeUsed);
                          const newStart = newPage*newPageRangeUsed;
                          const newStop = newPage*(newPageRangeUsed+1) > numFound ? numFound : newPage*(newPageRangeUsed+1);
                          const newRows = newStart + newPageRangeUsed <numFound ? newPageRangeUsed : newPageRangeUsed - (numFound - (newStart + newPageRangeUsed));
                          logger.info("@@@@@@@@@ dropdown paginationRange: newPage=" + newPage + "; newStart=" + newStart + "; newStop=" + newStop + "; newRows=" + newRows);

                          // will re-query with new parameters
                          const cursorInfo: cursorsType = {cursorStart: newStart, cursorRows: newRows};
                          const viewparams = buildQueryViewParams(viewType, newStart, newRows, listSortedFeld, listSortedOrder, calRangeUsed, calRangeValue);
                          dispatch(setCursors(cursorInfo));

                          dispatch(doQuery(request + "&"+ viewparams))

                          dispatch(setPageRangeUsed(newPageRangeUsed));
                        }
                    }}
                  >
                  <Dropdown.Toggle variant="outline" id="dropdown-paginationRange"
                    size="sm"
                    data-for="tipPaginationRange"
                    data-tip>
                    <span className={usedLabelStyle}>{pageRangeUsed}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <span className={usedLabelStyle}>{dropDownPaginationItemsToRender}</span>
                  </Dropdown.Menu>
                  </Dropdown>
                  <ReactTooltip className={styles.glToolTip} id="tipPaginationRange" place="top" effect="solid" textColor='#000000'backgroundColor={CONSTANTS.UI.TOOLTIP_COLOR}>
                    Change pagination range
                  </ReactTooltip>
                    </div>
                    :<div></div>}
                </FlexboxGridItem>
              
              </FlexboxGrid>
            </FlexboxGridItem>

            <FlexboxGridItem>
              <FlexboxGrid className={styles.paddingRight} justify="space-around">
              
                <FlexboxGridItem>
                  <ViewSwitch/>
                </FlexboxGridItem>

                <FlexboxGridItem>
                  <DateNav mobile={props.mobile} width={props.width}/>
                </FlexboxGridItem>
              
              </FlexboxGrid>
            </FlexboxGridItem>

          </FlexboxGrid>


          <FlexboxGrid justify="center">
              {calRangeUsed === 0 ?
                <div className={usedTitleLabel}>{CONSTANTS.MONTHS.FULL[parseInt(calRangeValue.split("-")[1])]} {calRangeValue.split("-")[0]}</div> 
                : <span></span>
              }
              {calRangeUsed === 1 ?
                calRangeValue.split('-')[0] !== endDateStr.split('-')[0] ?
                <div className={usedTitleLabel}>
                  Week from {calRangeValue.split("-")[2]} {CONSTANTS.MONTHS[parseInt(calRangeValue.split("-")[1])]} 
                  &nbsp;to {endDateStr.split('-')[2]} {CONSTANTS.MONTHS[parseInt(endDateStr.split('-')[1])]}
                  &nbsp;- {calRangeValue.split("-")[0]}/{endDateStr.split('-')[0]}
                  </div> 
                  :
                  <div className={usedTitleLabel}>
                  Week from {calRangeValue.split("-")[2]} {CONSTANTS.MONTHS[parseInt(calRangeValue.split("-")[1])]} 
                  &nbsp;to {endDateStr.split('-')[2]} {CONSTANTS.MONTHS[parseInt(endDateStr.split('-')[1])]}
                  &nbsp;- {calRangeValue.split("-")[0]}
                  </div> 
                  : <span></span>
              }
              {calRangeUsed === 3 ?
                <div className={usedTitleLabel}>{calRangeValue}</div> 
                : <span></span>
              }
          </FlexboxGrid>

          {/* download button and features */}
          {/* <FlexboxGrid justify='start'>
            <FlexboxGridItem style={{flex: 1}}>
              <FlexboxGrid className={styles.download_container} justify='start' align='middle'>
                <FlexboxGridItem className={styles.paddingRight20}>
                  <>
                    <input type="checkbox" checked={checked} onChange={selectAll}></input>
                    <label className={styles.paddingLeft5}>Select All</label>
                  </>
                </FlexboxGridItem>
                <FlexboxGridItem className={styles.paddingRight20}>
                  <label> {urls.length} Selected </label>
                </FlexboxGridItem>
                <FlexboxGridItem className={styles.paddingRight20}>
                  {urls.length > 0 ? 
                      <>
                        <Button className={styles.download_button} onClick={downloader}>
                          <img className={styles.download_icon} src='./images/download.png' alt='download' />
                          Download
                        </Button>
                      </>
                      :
                      <Button className={styles.download_button} onClick={downloader} disabled={true}>
                        <img className={styles.download_icon} src='./images/download.png' alt='download' />
                        Download
                      </Button>  
                  }
                </FlexboxGridItem>
              </FlexboxGrid>
            </FlexboxGridItem>
            <FlexboxGridItem style={{flex: 1}}>
              <>
              </>
            </FlexboxGridItem>
          </FlexboxGrid> */}
        
          {numFound > 0 ?
          <div className={styles.listItems}>
            <List hover>
              {
                range.map(value => {
                  return(
                    <ResultListItem checked={selected} key={value} id={value} index={resStart+value} doc={docs[value]} width={props.width} mobile={props.mobile}/>
                  )
                })
              }
            </List>
          </div> :
          <div style={{textAlign: "center", fontStyle: 'italic'}}><br></br><h4>No reports available for the selected filters</h4></div>
          }

          <FlexboxGrid className={styles.paddingLeft} justify="space-between" align="middle">
            <FlexboxGridItem>
              {numFound > 0 ? 
              <div className={usedLabelStyle}>
                Showing {resStart} to {resStart + numDocs} of {numFound} files
              </div> : 
              <span></span>
              }
              {/* <div className={usedLabelStyle}>
              Total found:{numFound}
              </div>
              <div className={usedLabelStyle}>Show {numDocs} items starting at index:{resStart}</div>
              <div className={usedLabelStyle}> Range used: {pageRangeUsed}</div> */}
            </FlexboxGridItem>

            <FlexboxGridItem className={styles.paddingRight}>
              <div className={styles.divPagination}>
              {navMinus === true ? 
              <span>
              <Button variant="outline-secondary" className={usedGlPageButton}
                onClick={() => {
                    //logger.debug("## resultListView2: - selected:");
                    // set cursor. Add cursor info to query, run query
                    const newStart = resStart - pageRangeUsed >0 ? resStart - pageRangeUsed : 0;
                    const newRows = resStart - pageRangeUsed >0 ? pageRangeUsed : pageRangeUsed - (resStart - pageRangeUsed);
                    logger.debug("## resultListView2:  + selected: newStart="+newStart+"; newRows="+newRows);

                    // add date info
                    const cursorInfo: cursorsType = {cursorStart: newStart, cursorRows: newRows};
                    const viewparams = buildQueryViewParams(viewType, newStart, newRows, listSortedFeld, listSortedOrder, calRangeUsed, calRangeValue);
                    dispatch(setCursors(cursorInfo));

                    //dispatch(doQuery(request+"&start="+newStart+"&rows="+newRows+"&sortBy="+listSortedFeld+"&sortOrder="+listSortedOrder));
                    dispatch(doQuery(request + "&"+ viewparams))
                  }}
                  data-for="minus"
                  data-tip
              >
              <MinusIcon className={usedGlIconStyle}/>
              </Button>
              <ReactTooltip className={styles.glToolTip} id="minus" place="bottom" effect="solid" textColor='#000000'backgroundColor={CONSTANTS.UI.TOOLTIP_COLOR}>
              Previous
              </ReactTooltip>
              </span>
              :<span></span>}


              {PageButtonToRender}


              {navPlus === true ? 
              <div>
              <Button variant="outline-secondary" className={usedGlButton}
                  onClick={() => {
                      //console.log("##toto  + selected:");
                      // set next cursor start + rows
                      const newStart = resStart + pageRangeUsed <numFound ? resStart + pageRangeUsed : numFound;
                      const newRows = newStart + pageRangeUsed <numFound ? pageRangeUsed : pageRangeUsed - (numFound - (newStart + pageRangeUsed));
                      logger.debug("## resultListView2:  + selected: newStart="+newStart+"; newRows="+newRows);

                      // add date info
                      const cursorInfo: cursorsType = {cursorStart: newStart, cursorRows: newRows};
                      const viewparams = buildQueryViewParams(viewType, newStart, newRows, listSortedFeld, listSortedOrder, calRangeUsed, calRangeValue);
                      dispatch(setCursors(cursorInfo));

                      //dispatch(doQuery(request+"&start="+newStart+"&rows="+newRows+"&sortBy="+listSortedFeld+"&sortOrder="+listSortedOrder));
                      dispatch(doQuery(request + "&"+ viewparams))
                    }}
                    data-for="plus"
                    data-tip
                >
                <PlusIcon className={usedGlIconStyle}/>
              </Button>
              <ReactTooltip className={styles.glToolTip} id="plus" place="bottom" effect="solid" textColor='#000000'backgroundColor={CONSTANTS.UI.TOOLTIP_COLOR}>
              Next
              </ReactTooltip>
              </div>
              : <div></div>}
              </div>
            </FlexboxGridItem>
          </FlexboxGrid>
        </Container>
      )
    }
    
    return (
            <div>
            {elem}
            </div>
    );
    
}

export default ResultsListView;
