import React, { useState, useEffect}  from 'react';
import { format } from 'date-fns';
import checkIcon from './assets/check.svg';
import errorIcon from './assets/error.svg';
import infoIcon from './assets/info.svg';
import warningIcon from './assets/warning.svg';
import './Toast.css';
//import { ExceptionHandler } from 'winston';

const DEFAULT_DATE_FORMAT = "yyyy-MM-dd hh:mm:ss"

interface Dictionary<T> {
    [Key: string]: T;
}

// define some types
export type toastListProp = {
    id: number,
    title: string,
    description: string,
    backgroundColor: string,
    icon: any,
}

export enum toastType {
    success  = 0,
    info = 1,
    warning = 2,
    error = 3,
}

const backgroundColor: Array<string> = ['#48c7fd', '#58e28d', '#f0ad4e', '#d9534f']; // blue, green, orange, red
const toastTypeIconMap : Array<any> = [checkIcon, infoIcon, warningIcon, errorIcon];


export enum positionType {
    top_right = 0,
    bottom_right = 1,
    top_left = 2,
    bottom_left = 3,
    None = 4,
}

type propsTypes = {
    toastList: Array<toastListProp>,
    position: positionType,
    autoDismiss: boolean,
    dismissTime: number,
    onCreated?: (value: number) => void,
    onDeleted?: (value: number) => void,
}

var counter: number = 0;

// utility function: create toast properties
export const createToastProperties = (type: toastType, title: string, description: any) =>{
    const id = counter++;
    const iconType = toastTypeIconMap[type];
    const bgColor = backgroundColor[type];
    var descriptionOk = description

    if(typeof description !== 'string'){
        //console.log("!! toast description is not string but:" + typeof(description));
        try{
            descriptionOk = JSON.stringify(description);
        }catch(e){
            descriptionOk = "Error getting description: not string, not json...";
        }
        //throw new Error("@@@@#####@@@@@ toast description is not string but:" + typeof(description));
    }

    const toastProperties: toastListProp = {
        id,
        title: title,
        description: descriptionOk,
        backgroundColor: bgColor,
        icon: iconType
      }
    console.log("#### will dispatch toast property:"+JSON.stringify(toastProperties));
   return toastProperties;
};


//
const Toast = (props: propsTypes) => {
    //console.log("#### Toast:"+JSON.stringify(props));
    const { toastList, position, autoDismiss, dismissTime, onDeleted } = props;
    const [list, setList] = useState(toastList);


    // set props.toastList in state
    useEffect(() => {
        //setList([...toastList]);
        const listCp = [...toastList];
        setList(listCp);

        if(props.onCreated!==undefined){
            for (const item in listCp){
                console.log("#### Toast created:"+listCp[item].id);
                //props.onCreated(cp[item].id);
            }

        }

        // eslint-disable-next-line
    }, [toastList, props.onCreated]);

    // use effect to remove one toast every dismissTime delay
    useEffect(() => {
        const interval = setInterval(() => {
            if (autoDismiss && toastList.length && list.length) {
                deleteToast(toastList[0].id);
            }
        }, dismissTime);
        
        return () => {
            clearInterval(interval);
        }

        // eslint-disable-next-line
    }, [toastList, autoDismiss, dismissTime, list]);

    //
    // when toast X is clicked: remove the toast from list
    //
    const deleteToast = (id: number) => {
        //console.log("@@@@@@@@@@@@@ Toast: will remove id:"+id);
        const listItemIndex = list.findIndex(e => e.id === id);
        //console.log("@@@@@@@@@@@@@ Toast: listItemIndex:"+listItemIndex);
        //console.dir(list);
        //const toastListItem = toastList.findIndex(e => e.id === id);
        //console.log("@@@@@@@@@@@@@ Toast: toastListItem:"+toastListItem);
        list.splice(listItemIndex, 1);
        //toastList.splice(toastListItem, 1);
        setList([...list]);
        //
        if(onDeleted!==undefined){
            onDeleted(id);
        } 
    }

    const aDate = new Date();
    const aDateStr =  format(aDate, DEFAULT_DATE_FORMAT)

    return (
        <>
            <div className={`notification-container ${positionType[position]}`}>
                {
                    list.map((toast, i) =>     
                        <div 
                            key={i}
                            className={`notification glToast ${positionType[position]}`}
                            style={{ backgroundColor: toast.backgroundColor }}
                            >
                            <button onClick={() => deleteToast(toast.id)}>
                                X
                            </button>
                            <div className="notification-image">
                                <img src={toast.icon} alt="" />
                            </div>
                            <div>
                                <p className="notification-title">{toast.title}</p>
                                <p className="notification-message">
                                    {toast.description}
                                </p>
                                <p className="notification-date">At: {aDateStr}</p>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default Toast;
