import React, {Component} from 'react'
import {Collapse, Card} from 'react-bootstrap'
import { Button } from 'rsuite';
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import styles from "./css/CollapsableDiv.module.css";
import logger from '../logger';

type collapsableType = {
    open:boolean
}

type notAvailableType = {
    open:boolean
}

type myPropsType = {
    name: string,
    label: string,
    values?: Array<string>,
    open?: boolean,
    notAvailable: boolean,
    onFacetSelected?: (value: string) => void
}

class CollapsableDiv extends Component <myPropsType, collapsableType, notAvailableType>{

    constructor(props : myPropsType) {
        super(props);
        logger.info("CollapsableDiv comp; props:" + JSON.stringify(props));
        this.state = { 
            open : this.props.open !== undefined ? this.props.open : false
            }
    }



    render(){

        const isOpen = this.state.open;
        const isNotAvailable = this.props.notAvailable;
        const facetName = this.props.name;
        const facetLabel = this.props.label;
        const facetValues= this.props.values;
        logger.debug("@@@@ CollapsableDiv render facetName:" + facetName);
        logger.debug("@@@@ CollapsableDiv render facetValues:" + facetValues);

        const compToRenter = facetValues?.map(aValue => {
            logger.debug("@@@@@@@@ CollapsableDiv aValue:" + aValue);
            return(
                <div key={aValue}>
                    <Button appearance="link"
                    onClick={(e) => {
                        logger.debug("@@@@ CollapsableDiv onSelected:" + aValue + "@"+ facetName);
                        if(this.props.onFacetSelected !== undefined){
                            this.props.onFacetSelected(aValue + "@"+ facetName);
                        }
                    }}
                    value={aValue + "@"+ facetName}
                    className={isNotAvailable ? styles.glNotAvailable : styles.buttons}>{aValue}</Button>
                </div>
            )
        });

        return(
            <Card key="facetName">
                <Card.Header>
                    <div className={styles.glFlex}>
                        <span>{facetLabel}</span>
                        <span className={styles.glCardHeaderRight}>
                        <Button appearance="link"
                            onClick={(e) => {
                                this.setState((prevState: collapsableType) => ({open: !prevState.open}))
                            }}
                            className={styles.glSwitchButton}>
                        {isOpen ? <ExpandLess className={styles.glIcon}/> : <ExpandMore className={styles.glIcon}/>}
                        </Button>
                        </span>
                    </div>
                </Card.Header>

            <Collapse in={this.state.open}>
                <div>
                    {compToRenter}
                </div>
            </Collapse>
            </Card>
            );
        }
}

export default CollapsableDiv