import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Container, Content} from 'rsuite';
//
import Query from "./Query";
import ResultsListView from "./ResultsListView";
import CalendarView from "./CalendarView";
import Search from "./search/Search"
import { RootState } from "../services/rootReducer";
import { calRangeType, setShowDebugInfo } from "../services/View_Slice";
import { setFreeTextSearch } from "../services/Search_Slice";
import CONSTANTS from '../constants';
//
//import ErrorBoundary from "../AppErrorBoundary";
//
import logger from '../loggers/logger';
import { format, parseISO } from 'date-fns';
//
import styles from "./css/QrastMain.module.css";

interface Dictionary<T> {
    [Key: string]: T;
  }
  
type screenValues = {
  mobile: boolean,
  width: number
}

function QrastMain(props: React.PropsWithChildren<screenValues>) {

    const dispatch = useDispatch();

    const {viewType, calRangeUsed, calRangeValue} = useSelector((state: RootState) => state.View);
    const {urls} = useSelector((state: RootState) => state.Download);
    const {freeTextSearch} = useSelector((state: RootState) => state.Search);
    logger.debug("QrastMain comp; calRangeUsed:"+calRangeUsed+"; calRangeValue:"+calRangeValue+"; freeTextSearch:'"+freeTextSearch+"'");

    const usedContainer = props.mobile ? styles.container_mobile: styles.container

    //let eventGuid = 0
    let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today
    logger.info("QrastMain comp; calRangeValue="+ calRangeValue + "; todayStr="+todayStr);


    // date calculation
    var adate: Date | undefined = undefined;

    if(calRangeUsed !== undefined){
      switch(calRangeUsed){
          case calRangeType.month:
              adate = parseISO(String(calRangeValue+"-01"));
              logger.debug("QrastMain comp; anInitialDat parsed date:" + adate);
              break;
          case calRangeType.year:
              adate = parseISO(String(calRangeValue+"-01-01"));
              break;
          case calRangeType.week:
              adate = parseISO(String(calRangeValue));
              //aWeek = getISOWeek(adate);
              break;
          default:
              logger.error("QrastMain comp; anInitialDat onSelect: unhandled calRangeUsed:" + calRangeUsed);
      }
    }else{
        logger.error("QrastMain comp; anInitialDat NOT parsed because undefined");
    }
    logger.debug("#### QrastMain comp; (setCalRangeValue pb) adate:"+adate);

    // default is that dateRange is by month
    // NEW: with URL params, range can be any possible: year/month/week
    var anInitialDate: string = "";
    if(adate !== undefined){
      //anInitialDate = format(adate, 'yyyy-MM-dd');
      if(calRangeUsed===calRangeType.year){
        anInitialDate = format(adate, 'yyyy');
      }else if(calRangeUsed===calRangeType.month){
        anInitialDate = format(adate, 'yyyy-mm');
      }else if(calRangeUsed===calRangeType.week){
        anInitialDate = format(adate, 'yyyy-mm-dd');
      }else{
          logger.error("#### QrastMain comp; invalid case for calRangeUsed:" + calRangeUsed);
      }
    }else{
      //anInitialDate = format(new Date(), 'yyyy-MM-dd');
      if(calRangeUsed===calRangeType.year){
        anInitialDate = format(new Date(), 'yyyy');
      }else if(calRangeUsed===calRangeType.month){
        anInitialDate = format(new Date(), 'yyyy-mm');
      }else if(calRangeUsed===calRangeType.week){
        anInitialDate = format(new Date(), 'yyyy-mm-dd');
      }else{
          logger.error("#### QrastMain comp; invalid case for calRangeUsed:" + calRangeUsed);
      }
    }
    logger.debug("QrastMain comp; anInitialDat:"+anInitialDate);


    // when search free text field is triggered
    function doSearch(criteria: string) {
      console.log("#### doSearch; criteria=" + criteria);

      // hidden feature: shows debug info
      if (criteria==='hack: show debug info'){
        logger.info("#### doSearch; SHOW DEBUG INFO");
        dispatch(setShowDebugInfo(true));
      }else if(criteria==='hack: hide debug info'){
        logger.info("#### doSearch; hide DEBUG INFO");
        dispatch(setShowDebugInfo(false));
      }else if(criteria==='hack: help'){
        logger.info("#### doSearch; help");
        logger.info("#### doSearch; 'hack: show debug info': show debug info");
        logger.info("#### doSearch; 'hack: hide debug info':hide debug info");
      }else{ // normal search case:
        dispatch(setFreeTextSearch(criteria))
      }
    }

    return (
        <Container fluid="true" className={usedContainer}>
          {/**/}
          <Search value={freeTextSearch} callback={doSearch} popupColor={'#c2c2c2'} placeHolder={"Search for..."}/>
          <Query/>
          <Content className={styles.glContent}>
            { viewType===0 ?
              <ResultsListView width={props.width} mobile={props.mobile}/>
              : 
              <div className={styles.calendar}>
                <CalendarView width={props.width} mobile={props.mobile}/>
              </div>
            }
          </Content>
        </Container>
    )
}

export default QrastMain;
