import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import CONSTANTS from "../constants";

type searchState = {
    /*search: string;*/
    freeTextSearch: string;
    operator: string
}

let initialState: searchState = {
    /*search: "*:*",*/
    freeTextSearch: "",
    operator: CONSTANTS.DEFAULT_OPERATOR_TO_USE,
};


const searchSlice = createSlice({
    name: 'Search',
    initialState: initialState,
    reducers: {
        setFreeTextSearch(state, action: PayloadAction<string>) {
            state.freeTextSearch=action.payload
        },
        setOperator(state, action: PayloadAction<string>) {
            state.operator=action.payload
        },
    }
});



export const {setFreeTextSearch, setOperator} = searchSlice.actions;

export default searchSlice.reducer