import React, { useEffect, useState } from 'react';
import {RootState} from "../services/rootReducer";

import { useDispatch, useSelector } from 'react-redux'
import { getCurrentFacets, getAvailableFacets, addOneFacetSelected, removeOneFacetSelected, setCurrentFacetsMap, FacetsMap, FacetsMapPayload, setFacetsMap } from 'services/Facets_Slice';
import { cursorsType, setCursors, getMissionColorMap, getGradientColorMap, getGradientMap } from "../services/View_Slice"

import { Button } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { Container } from "react-bootstrap";
import HighlightOff from "@material-ui/icons/HighlightOff";
import styles from "./css/Facets.module.css";

import CollapsableDiv from "./CollapsableDiv";

import CONSTANTS from "../constants";
//
import logger from "../logger"


interface Dictionary<T> {
    [Key: string]: T;
}

const NO_FACET_VALUES_TO_DISPLAY = "Not Available";
const initialState: any = undefined;

//type propsType = {
//    constraint: Array<string>
//}

function Facets(){ //props: React.PropsWithChildren<propsType>) {

    //
    const [precedentFacetSelected, setPrecedentFacetSelected] = useState(initialState);

    //const facetConstraints = props.constraint;

    //var precedentFacetSelected: any = undefined;
    const {currentFacetMap, facetSelected, facetMap} = useSelector((state: RootState) => state.Facets);
    const {pageRangeUsed, mobile, urlFacetsConstraints} = useSelector((state: RootState) => state.View);
    const { operator } = useSelector((state: RootState) => state.Search);
    logger.info("@@@ Facets currentFacetMap:"+ JSON.stringify(currentFacetMap));
    logger.info("@@@ Facets facetSelected:"+ JSON.stringify(facetSelected));
    //logger.info("@@@ Facets facetConstraints:"+ JSON.stringify(facetConstraints));
    logger.info("@@@ Facets facetConstraints from store:urlConstraints:"+ JSON.stringify(urlFacetsConstraints));

    // use effect to load facets from server
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAvailableFacets(urlFacetsConstraints))
        logger.info("$$$$ getting available")
      }, [dispatch, urlFacetsConstraints]);

    useEffect(() => {
        dispatch(getMissionColorMap())
      }, [dispatch]);

    useEffect(() => {
        dispatch(getGradientColorMap())
      }, [dispatch]);

    /* 
    Added to trigger refresh of currentFacet on first initialization (date 29/04/2021)
      
    NOTE: There may be another call to getCurrentFacet.

    TODO avoid to have two calls to the same function (getCurrentFacet)
    */
    useEffect(() => {
        logger.info("$$$$ facetMap at facetMap changed: " + JSON.stringify(facetMap));
        logger.info("$$$$ facetSelected at facetMap changed: " + facetSelected);
        dispatch(getCurrentFacets(facetMap, facetSelected, operator));
    }, [facetMap]);

    /* when a facet is selected, this will cause a unnecessary call to /real/getGradientMap
       RISOLVED: use a local state with facetSelected, and requery getGradientMap only when there is change
    */
    useEffect(() => {
        logger.info(" ## Facets useEffect: precedentFacetSelected:" + JSON.stringify(precedentFacetSelected));
        logger.info(" ## Facets useEffect: facetSelected:" + JSON.stringify(facetSelected));
        if(precedentFacetSelected===facetSelected){
            logger.info(" ## Facets useEffect: == do nothing");
        }else{
            logger.info(" ## Facets useEffect: !== do query");
            setPrecedentFacetSelected(facetSelected);
            //qui c'era il getGradientMap
        }
      }, [dispatch, precedentFacetSelected, setPrecedentFacetSelected]);
    

    const compToRender = currentFacetMap!==undefined ? Object.keys(currentFacetMap).map(item => {

        // facets names
        var values:Array<string> = [];
        //var n=0;
        for (var val in currentFacetMap[item]){
            if(currentFacetMap[item][val] === ""){
                //console.log("currentFacetMap[item][val] === ''");
                continue;
            }
            logger.debug("## Facets; facet in facet '" +item + "': val["+val+"]:"+ currentFacetMap[item][val]);
            values.push(currentFacetMap[item][val]);
            //n+=1;
        }

        // facet values selected
        var disabled: Array<string> = [];
        if (facetSelected!==undefined){
            for(var val1 in facetSelected){
                logger.info("## Facets; test facetSelected in facet '" + item + "': val1="+ facetSelected[val1]);
                if(facetSelected[val1].split("@")[1] === item){
                    disabled.push(facetSelected[val1].split("@")[0]);
                    logger.info("## Facets; disabled in facet '" + item + "': " + facetSelected[val1].split("@")[0]);
                }else{
                    logger.info("## Facets; not disabled in facet '" + item + "': " + facetSelected[val1].split("@")[1] + "' VS '" + item + "'");
                }

            }
        }else{
            logger.info("## Facets; facetSelected==undefined");
        }
        logger.info("## Facets ##########; disabled:"+disabled);

        const disableToRender = disabled.map(value => {
            return(
                <div key="value">
                    <Button appearance="link"
                        onClick = {(e) => {
                            // remove facet from store
                            logger.info("facet shall remove selection: '" + value+"@"+item+"'");
                            dispatch(removeOneFacetSelected(value+"@"+item));

                            // reset view start + rows
                            const cursorInfo: cursorsType = {
                                cursorStart: 0,
                                cursorRows: pageRangeUsed,
                            }
                            dispatch(setCursors(cursorInfo));

                            // retrieve current facets
                            const updatedFacetSelected = facetSelected.filter(v => v!==value+"@"+item) 
                            logger.info("facet remove selection; updatedFacetSelected="+ JSON.stringify(updatedFacetSelected));
                            dispatch(getCurrentFacets(facetMap, updatedFacetSelected, operator));
                            //dispatch(getGradientMap(updatedFacetSelected));
                        }}
                        value={value}
                        className={styles.glButtonn}>
                        <HighlightOff className={styles.glIcon}/>
                    </Button>
                    {value}
                </div>
            )
        });


        // remove disabled from values if present
        // URL constraint: if any value is passed, disable the facet values in all cases
        var valuesBis = [...values].filter(item2 => ! disabled.includes(item2));
        logger.info("## Facets ########## facet name: " + item + " final0 values to render="+ valuesBis);
        if(disableToRender.length>0){
            valuesBis=[];
            logger.info("## Facets ########## facet name: " + item + " final1 values to render="+ valuesBis);
        }else{
            logger.info("## Facets ########## facet name: " + item + " final2 values to render="+ valuesBis);
        }

        // the facet label we want
        const itemLabel: string = item in CONSTANTS.UI.FACETS_ALIAS ? CONSTANTS.UI.FACETS_ALIAS[item] : item;
        logger.info("## Facets itemLabel: " + itemLabel);

        var valuesToShow:Array<string> = [];
        if(valuesBis.length === 0){
            valuesToShow.push(NO_FACET_VALUES_TO_DISPLAY);
        }
        valuesBis.sort();

        

        return (
            <div key={item}>
                <div className={styles.space}></div>
                {disableToRender.length > 0 ? <span className={styles.glActiveFilterLabel}>Active filter:</span> : <span></span> }
                {disableToRender}



                { valuesBis.length > 0 ?
                    <CollapsableDiv key={item} label={itemLabel} name={item} values={valuesBis} notAvailable={false} open={true} onFacetSelected={(value: string) =>{
                        // add facet in store
                        logger.debug("facet onFacetSelected; value (alias)="+ value);
                        // reverse to get the alias key (like mission_ss)
                        //const aKey = getKeyByValue(CONSTANTS.UI.FACETS_ALIAS, value.split('@')[1]);
                        //if(aKey!=undefined){
                        //    const valueOk = value.split('@')[0] + "@" + aKey;
                        //    logger.info("facet2 onFacetSelected; value (key)="+ valueOk);
                        //    dispatch(addOneFacetSelected(value));
                        //}
                        dispatch(addOneFacetSelected(value));

                        // reset view start + rows
                        const cursorInfo: cursorsType = {
                            cursorStart: 0,
                            cursorRows: pageRangeUsed,
                        }
                        dispatch(setCursors(cursorInfo));

                        // retrieve current facet
                        const updatedFacetSelected = facetSelected.concat(value) 
                        logger.info("$$$$ updated " + updatedFacetSelected)
                        logger.debug("facet onFacetSelected; updatedFacetSelected="+ JSON.stringify(updatedFacetSelected));
                        dispatch(getCurrentFacets(facetMap, updatedFacetSelected, operator))
                        //dispatch(getGradientMap(updatedFacetSelected));
                    }}>
                    {item}
                    </CollapsableDiv>:

                
                    <CollapsableDiv key={item} label={itemLabel} name={item} values={valuesToShow} notAvailable={true} open={true}>
                    </CollapsableDiv>}


            </div>
        )
    }) : <div>{CONSTANTS.STATE_NOT_SET}</div>;


    const usedStyle = mobile ? styles.container_mobile: styles.container;
    
    return (
        <Container className={usedStyle}>
            {compToRender}
        </Container>
    );
}

export default Facets;
