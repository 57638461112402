import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import YearViewComp from "./YearViewComp";
import styles from "./css/CalendarComp.module.css";
import { FlexboxGrid } from 'rsuite';
import FlexboxGridItem from 'rsuite/lib/FlexboxGrid/FlexboxGridItem';
import { RootState } from 'services/rootReducer';
import SortControl from "components/SortControl";
import DateNav from "components/DateNav";
import ViewSwitch from "components/ViewSwitch";
import { format, parseISO } from 'date-fns';
import MonthViewComp from './MonthViewComp';
import CONSTANTS from "../../constants"
import WeekViewComp from './WeekViewComp';
import { getHeatMap } from 'services/View_Slice';
//import { buildQueryViewParamsCal } from 'components/utils/queryHelper';
//import { doQuery } from 'services/Query_Slice';

import styled from "styled-components";

export type CalendarProps2 = {
  startDate: Date;
  endDate: Date;
};

type CalendarProps = {
    startDate: Date;
    endDate: Date;
    events: Array<any>;
    mobile: boolean,
    width: number
};

const StyledDiv = styled.div`
    background-color: ${props => props.color};
`;

const CalendarComp: React.FC<CalendarProps> = (props) => {

    const {viewType, calRangeUsed, calRangeValue, mobile} = useSelector((state: RootState) => state.View);
    const {facetSelected} = useSelector((state: RootState) => state.Facets)
    const {loading} = useSelector((state: RootState) => state.Loading)
    //const {request} = useSelector((state: RootState) => state.Query)
    const {freeTextSearch, operator} = useSelector((state: RootState) => state.Search)
    const usedTitleLabel = mobile? styles.title_mobile: styles.title;

    // calculate end of the week
    const calcLastWeekDay = (value : number, month: string) =>{
        var date = parseISO(calRangeValue)
        var endDate = new Date(date.setDate(date.getDate() + 6))
        var anEndDateStr = format(endDate, "yyyy-MM-dd")
        return anEndDateStr;
    }

    const endDateStr = calcLastWeekDay(parseInt(calRangeValue.split("-")[2]), calRangeValue.split("-")[1])

    const dispatch = useDispatch()
    var start: string = '';
    var end: string = '';
    var dateEnd;
    switch(calRangeUsed){
        case 0: 
        start = calRangeValue+'-01T00:00:00Z'; 
        dateEnd = new Date(parseInt(calRangeValue.split('-')[0]), parseInt(calRangeValue.split('-')[1]), 0);
        end = format(dateEnd,'yyyy-MM-dd') +'T23:59:59Z'
        break;
        case 1: 
        start = calRangeValue+'T00:00:00Z';
        dateEnd = parseISO(calRangeValue)
        dateEnd = dateEnd.setDate(dateEnd.getDate() + 7)
        end = format(dateEnd,'yyyy-MM-dd') +'T23:59:59Z'
        break;  
        case 3:
        start = calRangeValue+'-01-01T00:00:00Z'; 
        end = calRangeValue+'-12-31T23:59:59Z'
        break;    
    }

    useEffect(() => {
        dispatch(getHeatMap(start, end, facetSelected, freeTextSearch, operator))
    }, [start, end, facetSelected, freeTextSearch])

    //render calendarComp
    return (
        <div>
            {loading ? 
            null
            :
            <div className='calendar-container'>
                {!mobile ?
                <FlexboxGrid justify="end">
                    <FlexboxGridItem>
                        <FlexboxGrid justify="end">
                            <FlexboxGridItem>
                                <ViewSwitch/>
                            </FlexboxGridItem>
                            
                            <FlexboxGridItem>
                                <DateNav width={props.width} mobile={props.mobile}/>
                            </FlexboxGridItem>
                            
                            <FlexboxGridItem>
                                { viewType===0 ?
                                    <SortControl/>
                                    : <span></span>
                                }
                            </FlexboxGridItem>
                        </FlexboxGrid>
                    </FlexboxGridItem>

                    {/* <FlexboxGridItem>
                        {calRangeUsed == 3 ? 
                        <FlexboxGrid className={styles.yearLegend} justify="start" align="middle">
                            Legend: <StyledDiv className={styles.stDiv1} color={"#d4d4d4"}/> range[numfiles {'>'} 5]
                            <StyledDiv className={styles.stDiv2} color={"#e5e5e5"}/> range[3 {'<'} numfiles {'<'} 4]
                            <StyledDiv className={styles.stDiv3} color={"#f2f2f2"}/> range[0 {'<'} numfiles {'<'} 2]
                        </FlexboxGrid>
                        :
                        null
                        }
                    </FlexboxGridItem> */}
                </FlexboxGrid>
                :
                <FlexboxGrid justify="end">
                    
                    <FlexboxGridItem>
                        <FlexboxGrid justify="end">
                            <FlexboxGridItem>
                                <ViewSwitch/>
                            </FlexboxGridItem>
                            
                            <FlexboxGridItem>
                                <DateNav width={props.width} mobile={props.mobile}/>
                            </FlexboxGridItem>
                            
                            <FlexboxGridItem>
                                { viewType===0 ?
                                    <SortControl/>
                                    : <span></span>
                                }
                            </FlexboxGridItem>
                        </FlexboxGrid>
                    </FlexboxGridItem>
                </FlexboxGrid>
                }   

                {mobile ? 
                    <FlexboxGridItem className={styles.mobileLegend}>
                    {calRangeUsed == 3 ? 
                        <FlexboxGrid className={styles.yearLegend_mobile}>
                            <div style={{fontSize:"0.8em"}}>Number of reports:</div>
                            <div className={styles.inner_container}>
                                <StyledDiv className={styles.stDiv1} color={"#d4d4d4"}/> 
                                <div style={{marginLeft:"10px", fontSize:"0.8em"}}>more than 10</div>
                            </div>
                            <div className={styles.inner_container}>
                                <StyledDiv className={styles.stDiv2} color={"#e5e5e5"}/> 
                                <div style={{marginLeft:"10px", fontSize:"0.8em"}}>between 5 and 10</div>
                            </div>
                            <div className={styles.inner_container}>
                                <StyledDiv className={styles.stDiv3} color={"#f2f2f2"}/> 
                                <div style={{marginLeft:"10px", fontSize:"0.8em"}}>less than 5</div>
                            </div>
                            
                        </FlexboxGrid>
                        :
                        null
                    }
                    </FlexboxGridItem>:
                    <FlexboxGridItem>
                    {calRangeUsed == 3 ? 
                    <FlexboxGrid className={styles.yearLegend} justify="start" align="middle">
                        <div style={{fontSize:"0.8em"}}>Number of reports:</div><StyledDiv className={styles.stDiv1} color={"#d4d4d4"}/><div style={{fontSize:"0.8em"}}>more than 10</div>
                        <StyledDiv className={styles.stDiv2} color={"#e5e5e5"}/><div style={{fontSize:"0.8em"}}>between 5 and 10</div> 
                        <StyledDiv className={styles.stDiv3} color={"#f2f2f2"}/><div style={{fontSize:"0.8em"}}>less than 5</div>
                    </FlexboxGrid>
                    :
                    null
                    }
                </FlexboxGridItem>
                }

                {calRangeUsed === 3 ? 
                <div className={styles.container}>
                    <div className={styles.inner_container}>
                        <div className={usedTitleLabel}>{calRangeValue}</div> 
                    </div>
                    <div>
                        <YearViewComp width={props.width}/>
                    </div>
                    {/* <Calendar year={calRangeValue} displayHeader={false} ref={calendarYearRef} /> */}
                </div>
                :
                null
                }
                {calRangeUsed === 0 ? 
                <div className={styles.container}>
                    <div className={styles.inner_container}>
                        <div className={usedTitleLabel}>{CONSTANTS.MONTHS.FULL[parseInt(calRangeValue.split("-")[1])]} {calRangeValue.split("-")[0]}</div> 
                    </div>
                    <div>
                        <MonthViewComp width={props.width}/>
                    </div>
                </div>
                :
                null
                }
                {calRangeUsed === 1 ?
                    calRangeValue.split("-")[0] !== endDateStr.split("-")[0] ?
                    <div className={styles.container}>
                        <div className={styles.inner_container}>
                            <div className={usedTitleLabel}>
                                Week from {calRangeValue.split("-")[2]} {CONSTANTS.MONTHS[parseInt(calRangeValue.split("-")[1])]} 
                                &nbsp;to {endDateStr.split('-')[2]} {CONSTANTS.MONTHS[parseInt(endDateStr.split('-')[1])]}
                                &nbsp;- {calRangeValue.split("-")[0]}/{endDateStr.split("-")[0]}
                            </div>
                        </div>
                        <WeekViewComp width={props.width}/>
                    </div> 
                    :
                    <div className={styles.container}>
                        <div className={styles.inner_container}>
                            <div className={usedTitleLabel}>
                                Week from {calRangeValue.split("-")[2]} {CONSTANTS.MONTHS[parseInt(calRangeValue.split("-")[1])]} 
                                &nbsp;to {endDateStr.split('-')[2]} {CONSTANTS.MONTHS[parseInt(endDateStr.split('-')[1])]}
                                &nbsp;- {calRangeValue.split("-")[0]}
                            </div>
                        </div>
                        <WeekViewComp />
                    </div>
                : null
                }
            </div>
            }
        </div>
    );

}

export default CalendarComp;
