import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type LoadingState = {
    loading: boolean,
    ok: boolean,
    error: string
}

let initialState: LoadingState = {
    loading: false,
    ok: true,
    error: "",
};


const loadingSlice = createSlice({
    name: 'Loading',
    initialState: initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading=action.payload;
        },
        setOk(state, action: PayloadAction<boolean>) {
            state.ok=action.payload;
        },
        setError(state, action: PayloadAction<string>) {
            state.error=action.payload;
        },
        setAllStates(state, action: PayloadAction<LoadingState>) {
            state.loading=action.payload.loading;
            state.ok=action.payload.ok;
            state.error=action.payload.error;
        },
    }
});



export const {setLoading, setOk, setError, setAllStates} = loadingSlice.actions;

export default loadingSlice.reducer