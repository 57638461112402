import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import MinusIcon from "@material-ui/icons/ArrowBackIos";
import PlusIcon from "@material-ui/icons/ArrowForwardIos";
//import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import { RootState } from "../services/rootReducer";
import GradientBar from "./GradientBar"
import logger from 'logger';
import styles from "./css/Gradient.module.css";
//import { setAllStates } from 'services/Loading_Slice';
//import useResizeObserver from "./useResizeObserver";

//interface Dictionary<T> {
//    [Key: string]: T;
//}

interface GradientProps {
    missionInfo: Array<any>; // [notEmpty, myChartData, missionColorMap, itemTotal]
    doughnutData: Array<any>; // [notEmpty, result, itemTotal]
}

function Gradient(props: React.PropsWithChildren<GradientProps>) {

    const { missionInfo, doughnutData } = props;

    const [page, setPage] = useState(1); // from 1 to n pages

    logger.debug("@@@@@@@@@ GLTestBar missionInfo[2]==mission colormap: " + JSON.stringify(missionInfo[2]));

    const { gradientValues, gradientColorMap } = useSelector((state: RootState) => state.View);
    const numPages = missionInfo[2] !== undefined ?  Math.ceil(Object.entries(missionInfo[2]).length/5) : 0;

    var n = -1;
    const showPage = numPages>1 ? true : false;
    var pStart = (page-1)*5;
    var pStop = page*5;
    logger.debug("@@@@@@@@@ GLTestBar numPages=" + numPages +"; showPage="+showPage);


    const compToRender = missionInfo!==undefined && doughnutData[1]['labels'].length > 0 && gradientValues !== undefined && gradientColorMap !== undefined ? 
    Object.keys(gradientValues).map(mission => {
        if(mission in missionInfo[2]){
            n+=1;
            logger.info("**** gradient call gradientValues:" + JSON.stringify(gradientValues[mission]));
            if(n>=pStart && n<pStop){
                return(
                    <div key={mission} className={styles.gradientLine}>
                        <GradientBar mission={mission} barData={gradientValues[mission]} colorMap={gradientColorMap}/>
                    </div>
                )
            }else{
                return null;
            }
        }else{
            return null;
        }
    }):
    <span>Empty</span>
    



    return(
        <Container className={styles.container}>
            <div className={styles.divFlex}>
                        {page>1 ? <div>
                            <Button variant="outline" size="sm"
                                value="-"
                                className={styles.pageButton}
                                data-for="buttonMinus"
                                data-tip
                                onClick={(e) => {
                                    logger.debug("@@@@@@@@@ GLTestBar onClick -:" + e);
                                    setPage(page-1);
                                }}
                                >
                                <MinusIcon className={styles.glIcon}/>
                            </Button>
                            {/* <ReactTooltip id="buttonMinus" place="top" effect="solid" textColor='#000000'backgroundColor='#ffb388'>
                                Minus
                            </ReactTooltip> */}
                        </div> :
                        <span></span>

            }
            <div className={styles.labelRange}>&nbsp; Page {page} out of {numPages} &nbsp;</div>
            {page<numPages ? <div>
                            <Button variant="outline" size="sm"
                                value="-"
                                className={styles.pageButton}
                                data-for="buttonPlus"
                                data-tip
                                onClick={(e) => {
                                    logger.debug("@@@@@@@@@ GLTestBar onClick +:" + e);
                                    setPage(page+1);
                                }}
                                >
                                <PlusIcon className={styles.glIcon}/>
                            </Button>
                            {/* <ReactTooltip id="buttonPlus" place="top" effect="solid" textColor='#000000'backgroundColor='#ffb388'>
                                Plus
                            </ReactTooltip> */}
                        </div> :
                        <span></span>

            }
            </div>
            {compToRender}
        </Container>
    );

}

export default Gradient
