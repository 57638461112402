import React, {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../services/rootReducer";
import { Container, Dropdown, Button, ButtonToolbar, Popover, OverlayTrigger } from 'react-bootstrap';
import { format, startOfMonth, addMonths, subSeconds, subMonths, addYears, subYears, addWeeks, subWeeks, parseISO, getISOWeek } from 'date-fns';
import ReactTooltip from "react-tooltip";
import MinusIcon from "@material-ui/icons/ArrowBackIos";
import PlusIcon from "@material-ui/icons/ArrowForwardIos";
import { calRangeType, cursorsType, setCalRangeValue, setCalRangeUsed, setCursors } from "../services/View_Slice"
import { doQuery } from "../services/Query_Slice";
import { buildQueryViewParams } from "./utils/queryHelper";
import CONSTANTS from "../constants";
import styles from "./css/DateNav.module.css";
import logger from 'logger';
import { clearUrls } from 'services/Download_Slice';


//import {getMonday, getNextMonday} from  "../test/TestWeekMethod"
import {getMonday, getNextMonday,dateToWeekDate} from "./utils/dateHelper";

type screenValues = {
    mobile: boolean,
    width: number
}

function DateNav(props: React.PropsWithChildren<screenValues>) {

    const dispatch = useDispatch();
    const {request} = useSelector((state: RootState) => state.Query);
    const {mobile, viewType, listSortedFeld, listSortedOrder, calRangeUsed, calRangeValue, pageRangeUsed} = useSelector((state: RootState) => state.View);
    const rangeSelected = calRangeUsed !== undefined ? calRangeType[calRangeUsed] : calRangeType.month;

    logger.info("######## DateNav comp; (setCalRangeValue pb) calRangeValue:"+calRangeValue+"; rangeSelected:"+rangeSelected);

    var [currentDate, setCurrentDate] = useState(calRangeValue)


    var adate: Date | undefined = undefined;
    var aWeek: number | undefined = undefined;

    //
    const usedLabelStyle = props.mobile ? styles.label_mobile: styles.label;
    const usedGlIconStyle = props.mobile ? styles.glIcon_mobile: styles.glIcon;
    const usedGlButton = props.mobile ? styles.glButton_mobile: styles.glButton;
    const usedGlDropdown = props.mobile ? styles.glDropdown_mobile: styles.glDropdown;

    var tmp_date = parseISO(currentDate)
    var tmp_dateStr = format(tmp_date, CONSTANTS.UI.DATE_FORMAT_DESIRED)

    function renderYear(year: number, dateStr: string) {
        const first: number = Number(dateStr.split("-")[0]);
        const outline = first===(year+1) ? "light": "outline-secondary";
        const tipId = "tipYear"+year;

        return (
          <div className={styles.yearButton} key={year}>
            <Button variant={outline}
                onClick={(e) => {
                    const target = e.target as HTMLTextAreaElement;
                    console.log("## year selected:"+target.value);
                    const dateYear: string = target.value;

                    // will trigger a query:
                    // build view params: reset start at zero, rows at ITEM_PER_PAGE
                    // add to request
                    const viewparams = buildQueryViewParams(viewType, 0, pageRangeUsed, listSortedFeld, listSortedOrder, calRangeType.year, dateYear);
                    logger.debug("@@@@ viewparams popup year :" + viewparams);

                    // update store
                    const cursorInfo: cursorsType = {
                        cursorStart: 0,
                        cursorRows: pageRangeUsed,
                    }
                    dispatch(setCursors(cursorInfo));
                    dispatch(setCalRangeUsed(calRangeType.year));
                    dispatch(setCalRangeValue(dateYear));

                    // query
                    dispatch(doQuery(request + "&"+ viewparams))

                }}
                value={year+1}
                data-for={tipId}
                data-tip
                >
                {/* <ReactTooltip className={styles.glToolTip} id={tipId} place="right" effect="solid" textColor='#000000' backgroundColor={CONSTANTS.UI.TOOLTIP_COLOR}>
                    Year: {year}
                </ReactTooltip> */}
                {year+1}
            </Button>
          </div>
        );
      }

    const popoverYear = (aProps: any) =>{
        var aYearN: number = 1970;
        var dateStr_format = tmp_dateStr.split('-')[2];
        if(aProps.dateStr !== undefined){
            aYearN = Number(aProps.dateStr.split("-")[0]);
        }
        const years = [];
        for (var i = 18; i >-6; i--) {
            years.push(renderYear(aYearN-i, aProps.dateStr));
        }
        return(
            <Popover id="1" className={styles.op1}>
                <Popover.Title as="h4">Selected Year: {aProps.dateStr !== undefined ? dateStr_format : "no year set"}</Popover.Title>
                <Popover.Content>
                <div className={styles.yearContainer}>
                    {years}
                </div>
                </Popover.Content>
            </Popover>
        )
    };


    function renderMonth(month: number, dateStr: string) {
        const year: string = dateStr.split("-")[0];
        const last: number = Number(dateStr.split("-")[1]);
        const outline = last===(month+1) ? "light": "outline-secondary";

        var str = String(month+1);
        var pad = "00";
        var ans = (pad + str).slice(-pad.length);
        const tipId = "tipMonth"+ans;

        return (
          <div className={styles.monthButton} key={month}>
            <Button variant={outline}
                onClick={(e) => {
                    const target = e.target as HTMLTextAreaElement; // is 2 digit string
                    console.log("## month selected:"+target.value);
                    const dateMonth: string = year + "-" + target.value;

                    // will trigger a query:
                    // build view params: reset start at zero, rows at ITEM_PER_PAGE
                    // add to request
                    const viewparams = buildQueryViewParams(viewType, 0, pageRangeUsed, listSortedFeld, listSortedOrder, calRangeType.month, dateMonth);
                    logger.debug("@@@@ viewparams popup month :" + viewparams);

                    // update store
                    const cursorInfo: cursorsType = {
                        cursorStart: 0,
                        cursorRows: pageRangeUsed,
                    }
                    dispatch(setCursors(cursorInfo));
                    dispatch(setCalRangeUsed(calRangeType.month));
                    dispatch(setCalRangeValue(dateMonth));

                    // query
                    dispatch(doQuery(request + "&"+ viewparams))
                }}
                value={ans}
                data-for={tipId}
                data-tip
                >
                {/* <ReactTooltip className={styles.glToolTip} id={tipId} place="right" effect="solid" textColor='#000000' backgroundColor={CONSTANTS.UI.TOOLTIP_COLOR}>
                    Month: {ans}
                </ReactTooltip> */}
                {ans}
            </Button>
          </div>
        );
      }

    /*move to the previous/next year in the {month, week} pop-up
    @op:boolean -> false:decrease year, true:increase year
    @is_week:boolean -> false:month pop-up, true:week pop-up
    */
    function changeYearInPopUp(op:boolean, is_week:boolean){
        var year = currentDate.split('-')[0]
        var month = currentDate.split('-')[1]

        if(!op)
            year = (parseInt(year)-1).toString() + '-' + month
        else
            year = (parseInt(year)+1).toString() + '-' + month

        if(is_week){
            var week = currentDate.split('-')[2]
            year += '-' + week
        }
        //dispatch(setCalRangeValue(year))
        setCurrentDate(year)
    }

    /*move to the previous/next month in the {month, week} pop-up
    @op:boolean -> false:decrease month, true:increase month
    */
    function changeMonthInPopUp(op:boolean){
        var year = currentDate.split('-')[0]
        var month = currentDate.split('-')[1]
        var week = currentDate.split('-')[2]

        if(!op){
            month = (parseInt(month)-1).toString()
        }else{
            month = (parseInt(month)+1).toString()
        }
        if(parseInt(month)>0 && parseInt(month)<10)
            month = '0' + month
        if(parseInt(month)<1){
            year = (parseInt(year)-1).toString()
            month = '12'
        }else if(parseInt(month)>12){
            year = (parseInt(year)+1).toString()
            month = '01'
        }

        var date = new Date(parseInt(year), (parseInt(month)), 0);
        //handle month days to avoid exception
        var confront = parseInt(week)
        if(confront > date.getDate()) 
            week = date.getDate().toString();

        //dispatch(setCalRangeValue(year+'-'+month+'-'+week))
        setCurrentDate(year+'-'+month+'-'+week)
    }

    const popoverMonth = (aProps: any) => {
        const months = [];
        var dateStr_format = tmp_dateStr.split('-')[1] + '-' + tmp_dateStr.split('-')[2] 
        for (var i = 0; i < 12; i++) {
          months.push(renderMonth(i, aProps.dateStr));
        }
        return(
            <Popover id="1" className={styles.op1}>
                <div className={styles.popup_nav}>
                    <Button variant="link" className={styles.buttonYear} onClick={() => changeYearInPopUp(false, false)}>&#60;&#60;</Button>
                    <span style={{fontSize: "medium"}}>{currentDate.split('-')[0]}</span>
                    <Button variant="link" className={styles.buttonYear} onClick={() => changeYearInPopUp(true, false)}>&#62;&#62;</Button>
                </div>
                <Popover.Title as="h4">Selected Month: {aProps.dateStr !== undefined ? dateStr_format : "no month set"}</Popover.Title>
                <Popover.Content>
                <div className={styles.monthContainer}>
                    {months}
                </div>
                </Popover.Content>
            </Popover>
        )
    };

    function renderWeek(week: number, dateStr: string) {
        adate = parseISO(dateStr);
        aWeek = getISOWeek(adate);
        //
        var initYearDate = parseISO(currentDate.split("-")[0]);
          
        var weekLabel = (week+1).toString() //display label for week value
        if(getISOWeek(initYearDate) !== 1)
            initYearDate = getNextMonday(initYearDate)
        else
            initYearDate = getMonday(initYearDate)

        const aWeekDate = addWeeks(initYearDate, (week))
        const aWeekDateEnd = subSeconds(new Date(addWeeks(initYearDate, (week + 1))), 1)
        const aWeekDateStr = format(aWeekDate, CONSTANTS.UI.DATE_FORMAT_DESIRED)
        const aWeekDateStrEnd = format(aWeekDateEnd, CONSTANTS.UI.DATE_FORMAT_DESIRED)
        // console.log("aWeekDateStr:" + aWeekDateStr);
        // console.log("aWeekDateEnd:" + aWeekDateStrEnd)
        const outline = aWeek===(week+1) ? "light": "outline-secondary";
        const tipId = "tipWeek"+week;

        return (
          <div className={styles.weekButton} key={week}>
            <div></div>
            <Button variant={outline}
                onClick={(e) => {
                    const target = e.target as HTMLTextAreaElement;
                    console.log("## week selected:"+target.value);
                    var aWeek2: number = Number(target.value);
                    const aWeekDate2 = addWeeks(initYearDate, (aWeek2));
                    const aWeekDateStr2 = format(aWeekDate2, 'yyyy-MM-dd');

                    // will trigger a query:
                    // build view params: reset start at zero, rows at ITEM_PER_PAGE
                    // add to request
                    const viewparams = buildQueryViewParams(viewType, 0, pageRangeUsed, listSortedFeld, listSortedOrder, calRangeType.week, aWeekDateStr2);
                    logger.debug("@@@@ viewparams popup week :" + viewparams);

                    // update store
                    const cursorInfo: cursorsType = {
                        cursorStart: 0,
                        cursorRows: pageRangeUsed,
                    }
                    dispatch(setCursors(cursorInfo));
                    dispatch(setCalRangeUsed(calRangeType.week));
                    dispatch(setCalRangeValue(aWeekDateStr2));

                    // query
                    dispatch(doQuery(request + "&"+ viewparams))
                }}
                value={week}
                data-for={tipId}
                data-tip
            >
            <ReactTooltip className={styles.glToolTip} id={tipId} place="right" effect="solid" textColor='#000000' backgroundColor={CONSTANTS.UI.TOOLTIP_COLOR}>
                {aWeekDateStr} to {aWeekDateStrEnd}
            </ReactTooltip>
            Week: {weekLabel}
            </Button>
          </div>
        );
      }
    const popoverWeek = (aProps: any) => {
        const weeks = [];
        const popOverWideUsed = mobile? styles.popOverWideWeek_mobile : 
                            styles.popOverWideWeek;
        
        const containerUsed = mobile? styles.weekContainer_mobile:
                            styles.weekContainer;
        
        var numOfWeeks = getISOWeek(new Date(parseInt(currentDate.split('-')[0]), 12, 0));  //get number of weeks
        if(numOfWeeks === 1)
            numOfWeeks = 52
        
        for (var i = 0; i < numOfWeeks; i++) { 
            weeks.push(renderWeek(i, aProps.dateStr));
        }

        return(
            <Popover id="1" className={popOverWideUsed}>
                <div className={styles.popup_nav}>
                    <Button className={styles.buttonYear} onClick={() => changeYearInPopUp(false, true)}>&#60;&#60;</Button>
                    <Button className={styles.buttonMonth} onClick={() => changeMonthInPopUp(false)}>&#60;</Button>
                    <span style={{fontSize: "medium"}}>{tmp_dateStr.split('-')[1] + '-' + tmp_dateStr.split('-')[2]}</span>
                    <Button className={styles.buttonMonth} onClick={() => changeMonthInPopUp(true)}>&#62;</Button>
                    <Button className={styles.buttonYear} onClick={() => changeYearInPopUp(true, true)}>&#62;&#62;</Button>
                </div>
                <Popover.Title as="h4">Selected Week: {aWeek !== undefined ? aWeek : "no week set"}</Popover.Title>
                <Popover.Content>
                <div className={containerUsed}>
                    {weeks}
                </div>
                </Popover.Content>
            </Popover>
        )
    };



    var popoverToUse: JSX.Element | undefined = undefined;
    //var popoveryearToUse: JSX.Element | undefined = undefined;
    //var popoverToUse: JSX.Element | undefined = undefined;
    if(calRangeUsed !== undefined){
        switch(calRangeUsed){
            case calRangeType.month:
                adate = parseISO(currentDate);
                logger.debug("@@@@@@@@@ DateNav parsed date:" + adate);
                popoverToUse = popoverMonth({dateStr: currentDate});
                break;
            case calRangeType.year:
                adate = parseISO(currentDate);
                popoverToUse = popoverYear({dateStr: currentDate});
                break;
            case calRangeType.week:
                adate = parseISO(currentDate);
                aWeek = getISOWeek(adate);
                popoverToUse = popoverWeek({dateStr: currentDate});
                break;
            default:
                logger.error("@@@@@@@@@ DateNav onSelect: unhandled calRangeUsed:" + calRangeUsed);
        }
    }else{
        logger.error("@@@@@@@@@ DateNav NOT parsed because undefined");
    }
    logger.debug("@@@@@@@@@ DateNav parsed date:" + adate);
    logger.debug("@@@@@@@@@ DateNav calRangeUsed:" + calRangeUsed);

    return(
        <Container className={styles.container}>
            <div className={usedLabelStyle}>View by</div>

            {/* month/year/week dropdown */}
            <Dropdown
                className={usedGlDropdown}
                onSelect={(e) => {
                    logger.debug("@@@@@@@@@ DateNav onSelect:" + e);
                    const aNum: number = Number(e);

                        if(aNum!==calRangeUsed){
                            dispatch(setCalRangeUsed(aNum));

                            switch(aNum){
                                case calRangeType.month:
                                    const dateMonth = format(startOfMonth(new Date(calRangeValue)), 'yyyy-MM');

                                    // will trigger a query:
                                    // build view params: reset start at zero, rows at ITEM_PER_PAGE
                                    // add to request
                                    const viewparams = buildQueryViewParams(viewType, 0, pageRangeUsed, listSortedFeld, listSortedOrder, calRangeType.month, dateMonth);
                                    logger.debug("@@@@ viewparams dropdown month:" + viewparams);

                                    const cursorInfo: cursorsType = {
                                        cursorStart: 0,
                                        cursorRows: pageRangeUsed,
                                    }

                                    // update store
                                    dispatch(setCalRangeUsed(calRangeType.month));
                                    dispatch(setCalRangeValue(dateMonth));
                                    dispatch(setCursors(cursorInfo));

                                    // query
                                    dispatch(doQuery(request + "&"+ viewparams));

                                    break;
                                case calRangeType.year:
                                    const dateYear = format(new Date(calRangeValue), 'yyyy');

                                    // will trigger a query:
                                    // build view params: reset start at zero, rows at ITEM_PER_PAGE
                                    // add to request
                                    const viewparams1 = buildQueryViewParams(viewType, 0, pageRangeUsed, listSortedFeld, listSortedOrder, calRangeType.year, dateYear);
                                    logger.debug("@@@@ viewparams dropdown year:" + viewparams1);

                                    const cursorInfo1: cursorsType = {
                                        cursorStart: 0,
                                        cursorRows: pageRangeUsed,
                                    }

                                    // update store
                                    dispatch(setCalRangeUsed(calRangeType.year));
                                    dispatch(setCalRangeValue(dateYear));
                                    dispatch(setCursors(cursorInfo1));

                                    // query
                                    dispatch(doQuery(request + "&"+ viewparams1))

                                    break;
                                case calRangeType.week:
                                    /* moved into dateHelper
                                    var tmp = new Date(calRangeValue);
                                    if(getISOWeek(tmp) !== 1)        //if its different from the 1st week of the year get next monday
                                        tmp = getNextMonday(tmp);
                                    else{
                                        tmp = getMonday(tmp);
                                    }//else get first monday
                                    const dateWeek = format(tmp, 'yyyy-MM-dd');*/
                                    const dateWeek = dateToWeekDate(calRangeValue, 'yyyy-MM-dd');

                                    // will trigger a query:
                                    // build view params: reset start at zero, rows at ITEM_PER_PAGE
                                    // add to request
                                    const viewparams2 = buildQueryViewParams(viewType, 0, pageRangeUsed, listSortedFeld, listSortedOrder, calRangeType.week, dateWeek);
                                    logger.debug("@@@@ viewparams dropdown week:" + viewparams2);

                                    const cursorInfo2: cursorsType = {
                                        cursorStart: 0,
                                        cursorRows: pageRangeUsed,
                                    }

                                    // update store
                                    dispatch(setCalRangeUsed(calRangeType.week));
                                    dispatch(setCalRangeValue(dateWeek));
                                    dispatch(setCursors(cursorInfo2));

                                    // query
                                    dispatch(doQuery(request + "&"+ viewparams2));

                                    break;
                                default:
                                    logger.error("@@@@@@@@@ DateNav onSelect: unhandled range value:" + aNum);
                            }
                    }else{
                        logger.debug("@@@@@@@@@ DateNav onSelect: current value selected, do nothing");
                    }
                }}
                >

                <Dropdown.Toggle variant="outline" id="dropdown-basic"
                    size="sm"
                    data-for="tipSwitch"
                    data-tip>
                    {rangeSelected}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item eventKey={String(calRangeType.year)} className={usedLabelStyle}>year</Dropdown.Item>
                    <Dropdown.Item eventKey={String(calRangeType.month)} className={usedLabelStyle}>month</Dropdown.Item>
                    <Dropdown.Item eventKey={String(calRangeType.week)} className={usedLabelStyle}>week</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <ReactTooltip className={styles.glToolTip} id="tipSwitch" place="top" effect="solid" textColor='#000000'backgroundColor={CONSTANTS.UI.TOOLTIP_COLOR}>
                Switch year/month/week
            </ReactTooltip>


            <ButtonToolbar className={styles.glButtonToolbar}>

            {/* minus button */}
            <Button variant="outline" size="sm"
                        value="List"
                        className={usedGlButton}
                        onClick={(e) => {
                            logger.debug("@@@@@@@@@ DateNav onSelect -:" + e);

                            if (adate !== undefined){
                                //const ad: Date = adate;
                                switch(calRangeUsed){
                                    case calRangeType.month:
                                        const aDateMonth = subMonths(adate, 1);
                                        const aMonthStr = format(aDateMonth, "yyyy-MM");

                                        // will trigger a query:
                                        // build view params: reset start at zero, rows at ITEM_PER_PAGE
                                        // add to request: reset start at zero, rows at ITEM_PER_PAGE
                                        const viewparams = buildQueryViewParams(viewType, 0, pageRangeUsed, listSortedFeld, listSortedOrder, calRangeType.month, aMonthStr);
                                        logger.debug("@@@@ viewparams minus month:" + viewparams);

                                        // update store
                                        const cursorInfo3: cursorsType = {
                                            cursorStart: 0,
                                            cursorRows: pageRangeUsed,
                                        }
                                        dispatch(setCursors(cursorInfo3));
                                        dispatch(setCalRangeValue(format(aDateMonth, "yyyy-MM")));

                                        // query
                                        dispatch(doQuery(request + "&"+ viewparams));

                                        break;
                                    case calRangeType.year:
                                        const aDateYear = subYears(adate, 1);
                                        const aYearStr = format(aDateYear, "yyyy");

                                        // will trigger a query:
                                        // build view params: reset start at zero, rows at ITEM_PER_PAGE
                                        // add to request
                                        const viewparams1 = buildQueryViewParams(viewType, 0, pageRangeUsed, listSortedFeld, listSortedOrder, calRangeType.year, aYearStr);
                                        logger.debug("@@@@ viewparams minus year:" + viewparams1);

                                        // update store
                                        const cursorInfo4: cursorsType = {
                                            cursorStart: 0,
                                            cursorRows: pageRangeUsed,
                                        }
                                        dispatch(setCursors(cursorInfo4));
                                        dispatch(setCalRangeValue(aYearStr));

                                        // query
                                        dispatch(doQuery(request + "&"+ viewparams1));

                                        break;
                                    case calRangeType.week:
                                        const aDateweek = subWeeks(adate, 1);
                                        const aWeekStr = format(aDateweek, "yyyy-MM-dd");

                                        // will trigger a query:
                                        // build view params: reset start at zero, rows at ITEM_PER_PAGE
                                        // add to request
                                        const viewparams2 = buildQueryViewParams(viewType, 0, pageRangeUsed, listSortedFeld, listSortedOrder, calRangeType.week, aWeekStr);
                                        logger.debug("@@@@ viewparams minus week:" + viewparams2);

                                        // update store
                                        const cursorInfo5: cursorsType = {
                                            cursorStart: 0,
                                            cursorRows: pageRangeUsed,
                                        }
                                        dispatch(setCursors(cursorInfo5));
                                        dispatch(setCalRangeValue(format(aDateweek, "yyyy-MM-dd")));

                                        // query
                                        dispatch(doQuery(request + "&"+ viewparams2));

                                        break;
                                    default:
                                        logger.error("@@@@@@@@@ DateNav onSelect: unhandled calRangeUsed:" + calRangeUsed);
                                }

                            }

                            //dispatch(doQuery(request));
                        }}
                        data-for="tipLeft"
                        data-tip
                        >
                    <MinusIcon className={usedGlIconStyle}/>
                </Button>
                <ReactTooltip className={styles.glToolTip} id="tipLeft" place="left" effect="solid" textColor='#000000'backgroundColor={CONSTANTS.UI.TOOLTIP_COLOR}>
                            Previous
                </ReactTooltip>

                {/* overlay trigegr for year/mon/week */}
                <OverlayTrigger onEnter={
                    () => {
                        var str = parseISO(calRangeValue)
                        var endWeek = addWeeks(str, 1)
                        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>' + str+ ' ' + endWeek);
                        if(str.getFullYear() < endWeek.getFullYear()){
                            if(getISOWeek(str) === 1)
                                setCurrentDate(format(new Date(endWeek.getFullYear(), endWeek.getMonth(), endWeek.getDate() - 1), 'yyyy-MM-dd'));
                        } 
                        else
                            setCurrentDate(calRangeValue);
                    }
                } trigger="click" placement="bottom" overlay={popoverToUse!==undefined ? popoverToUse : popoverMonth({dateStr: adate})} rootClose={true}>
                    <Button variant="outline" size="sm"
                            value="List"
                            data-for="tipSelect"
                            data-tip
                            >
                            {calRangeUsed === calRangeType.week ?  " Week: " + aWeek  : <span></span>}
                            {calRangeUsed === calRangeType.month ? tmp_dateStr.split('-')[1] + '-' + tmp_dateStr.split('-')[2]: <span></span>}
                            {calRangeUsed === calRangeType.year ? tmp_dateStr.split('-')[2]: <span></span>}
                    </Button>
                </OverlayTrigger>
                <ReactTooltip className={styles.glToolTip} id="tipSelect" place="top" effect="solid" textColor='#000000'backgroundColor={CONSTANTS.UI.TOOLTIP_COLOR}>
                    Open {calRangeUsed !== undefined ? calRangeType[calRangeUsed] : "?"} selection
                </ReactTooltip>


                {/* plus button */}
                <Button variant="outline" size="sm"
                        value="List"
                        className={usedGlButton}
                        onClick={(e) => {
                            logger.debug("@@@@@@@@@ DateNav onSelect +:" + e);

                            if (adate !== undefined){
                                //const ad: Date = adate;
                                switch(calRangeUsed){
                                    case calRangeType.month:
                                        const aDateMonth = addMonths(adate, 1);
                                        const aMonthStr = format(aDateMonth, "yyyy-MM");

                                        // will trigger a query:
                                        // build view params: reset start at zero, rows at ITEM_PER_PAGE
                                        // add to request
                                        const viewparams = buildQueryViewParams(viewType, 0, pageRangeUsed, listSortedFeld, listSortedOrder, calRangeType.month, aMonthStr);
                                        logger.debug("@@@@ viewparams plus month:" + viewparams);

                                        // update store
                                        const cursorInfo6: cursorsType = {
                                            cursorStart: 0,
                                            cursorRows: pageRangeUsed,
                                        }
                                        dispatch(setCursors(cursorInfo6));
                                        dispatch(setCalRangeValue(format(aDateMonth, "yyyy-MM")));

                                        // query
                                        dispatch(doQuery(request + "&"+ viewparams));

                                        break;
                                    case calRangeType.year:
                                        const aDateYear = addYears(adate, 1);
                                        const aYearStr = format(aDateYear, "yyyy");

                                        // will trigger a query:
                                        // build view params: reset start at zero, rows at ITEM_PER_PAGE
                                        // add to request
                                        const viewparams1 = buildQueryViewParams(viewType, 0, pageRangeUsed, listSortedFeld, listSortedOrder, calRangeType.year, aYearStr);
                                        logger.debug("@@@@ viewparams plus year:" + viewparams1);

                                        // update store
                                        const cursorInfo7: cursorsType = {
                                            cursorStart: 0,
                                            cursorRows: pageRangeUsed,
                                        }
                                        dispatch(setCursors(cursorInfo7));
                                        dispatch(setCalRangeValue(format(aDateYear, "yyyy")));

                                        // query
                                        dispatch(doQuery(request + "&"+ viewparams1));

                                        break;
                                    case calRangeType.week:
                                        const aDateweek = addWeeks(adate, 1);
                                        const aWeekStr = format(aDateweek, "yyyy-MM-dd");

                                        // will trigger a query:
                                        // build view params: reset start at zero, rows at ITEM_PER_PAGE
                                        // add to request
                                        const viewparams2 = buildQueryViewParams(viewType, 0, pageRangeUsed, listSortedFeld, listSortedOrder, calRangeType.week, aWeekStr);
                                        logger.debug("@@@@ viewparams plus week:" + viewparams2);

                                        // update store
                                        const cursorInfo8: cursorsType = {
                                            cursorStart: 0,
                                            cursorRows: pageRangeUsed,
                                        }
                                        dispatch(setCursors(cursorInfo8));
                                        dispatch(setCalRangeValue(format(aDateweek, "yyyy-MM-dd")));

                                        // query
                                        dispatch(doQuery(request + "&"+ viewparams2));

                                        break;
                                    default:
                                        logger.error("@@@@@@@@@ DateNav onSelect: unhandled calRangeUsed:" + calRangeUsed);
                                }
                            }

                            //dispatch(doQuery(request));
                        }}
                        data-for="tipRight"
                        data-tip
                        >
                    <PlusIcon className={usedGlIconStyle}/>
                </Button>
                <ReactTooltip className={styles.glToolTip} id="tipRight" place="right" effect="solid" textColor='#000000'backgroundColor={CONSTANTS.UI.TOOLTIP_COLOR}>
                            Next
                </ReactTooltip>

            </ButtonToolbar>

        </Container>
    )

}

export default DateNav;
